import { Box, Typography } from "@mui/material";
import CommonSearchPage from "./CommonSearchPage";

interface Props {}

const GameListBonusBuy: React.FC<Props> = (props: Props) => {
  return (
    <Box>
      <Typography mb="30px" textAlign="center" variant="h2">
        Bonus Buy
      </Typography>
      <CommonSearchPage category="bonus-buy" />
    </Box>
  );
};

export default GameListBonusBuy;
