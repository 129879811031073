import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import MobileTitleHead from './MobileTitleHead';

interface Props {
  onBack?: () => void;
  title: string;
}

const CommonTitleHead: React.FC<Props> = (props: Props) => {
  const navigate = useNavigate();

  const handleBack = () => {
    if (props.onBack) {
      props.onBack();
    } else {
      // 默认回到首页
      navigate('/');
    }
  };

  return (
    <>
      <Box display={{ xs: 'block', md: 'none' }} mb={2}>
        <MobileTitleHead title={props.title} onBack={handleBack} />
      </Box>
      <Typography
        display={{ xs: 'none', md: 'block' }}
        mb="30px"
        textAlign="center"
        variant="h2"
      >
        {props.title}
      </Typography>
    </>
  );
};

export default CommonTitleHead;
