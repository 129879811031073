import { Theme } from "@mui/material/styles";

export default function Autocomplete(theme: Theme) {
  return {
    MuiAutocomplete: {
      clearIndicator: {
        color: "green", // 将清除图标颜色设置为红色
      },
      option: {
        "&:hover": {
          backgroundColor: theme.palette.info.main, // 设置悬停背景颜色
        },
      },
    },
  };
}
