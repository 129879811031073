import useLang from '@/hooks/useLang';
import useResponsive from '@/hooks/useResponsive';
import { isOpenVerifyAccountState } from '@/stores/auth';
import { Box, Dialog, Drawer } from '@mui/material';
import { useRecoilState } from 'recoil';
import DrawerHeder from '../Me/components/DrawerHeder';
import VerifyAccountPage from './VerifyAccount';

interface Props {}

const VerifyAccountDrawer = (props: Props) => {
  const { __ } = useLang();
  const [openVerifyAccount, setOpenVerifyAccount] = useRecoilState(
    isOpenVerifyAccountState,
  );

  const isMobile = useResponsive('down', 'md');

  const _onClose = () => {
    setOpenVerifyAccount('');
  };

  if (isMobile) {
    return (
      <Drawer
        anchor={isMobile ? 'bottom' : 'right'}
        open={openVerifyAccount?.length > 0}
        // onOpen={() => setOpenWithdraw(true)}
        onClose={_onClose}
        ModalProps={{
          container: document.getElementById('root') as HTMLElement,
        }}
        PaperProps={{
          sx: {
            borderRadius: '24px 24px 0 0',
          },
        }}
      >
        <Box
          bgcolor="transparent"
          position="relative"
          style={{
            width: isMobile ? '100vw' : 410,
            overflowY: 'hidden',
          }}
          sx={{ bgcolor: 'card.main' }}
        >
          <DrawerHeder
            title={
              openVerifyAccount === 'email'
                ? __('Email Verification')
                : __('Phone Verification')
            }
            onClose={_onClose}
          />

          <VerifyAccountPage type={openVerifyAccount} />
        </Box>
      </Drawer>
    );
  }
  return (
    <Dialog
      open={openVerifyAccount?.length > 0}
      PaperProps={{
        sx: {
          width: '400px',
          maxWidth: '400px',
          borderRadius: '20px 20px 0 0',
        },
      }}
      onClose={_onClose}
      aria-labelledby="sign-in"
    >
      <Box
        bgcolor="transparent"
        position="relative"
        style={{
          width: '400px',
          height: '80vh',
          overflowY: 'hidden',
        }}
        sx={{ bgcolor: 'card.main' }}
      >
        <DrawerHeder
          title={
            openVerifyAccount === 'email'
              ? __('Email Verification')
              : __('Phone Verification')
          }
          onClose={_onClose}
        />
        <VerifyAccountPage type={openVerifyAccount} />
      </Box>
    </Dialog>
  );
};

export default VerifyAccountDrawer;
