// import { Public_Sans, Barlow } from '@next/font/google';

export function remToPx(value: string) {
  return Math.round(parseFloat(value) * 16);
}

export function pxToRem(value: number) {
  return `${value / 16}rem`;
}

export function responsiveFontSizes({
  sm,
  md,
  lg,
}: {
  sm: number;
  md: number;
  lg: number;
}) {
  return {
    '@media (min-width:600px)': {
      fontSize: pxToRem(sm),
    },
    '@media (min-width:900px)': {
      fontSize: pxToRem(md),
    },
    '@media (min-width:1200px)': {
      fontSize: pxToRem(lg),
    },
  };
}

// export const primaryFont = Public_Sans({
//   weight: ['400', '500', '600', '700', '800'],
//   subsets: ['latin'],
//   display: 'swap',
//   fallback: ['Helvetica', 'Arial', 'sans-serif'],
// });
// font-family: PingFangSC-Regular, sans-serif;

// export const secondaryFont = Barlow({
//   weight: ['900'],
//   subsets: ['latin'],
//   display: 'swap',
//   fallback: ['Helvetica', 'Arial', 'sans-serif'],
// });

// LEARN MORE
// https://nextjs.org/docs/basic-features/font-optimization#google-fonts

const typography = {
  // fontFamily: '"Baloo Da 2 Variable", sans-serif',
  fontFamily: 'ubuntuFont, PingFang SC-Heavy, PingFang SC, sans-serif',
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  fontWeightBold: 700,
  h1: {
    fontWeight: 600,
    lineHeight: 45 / 32,
    fontSize: pxToRem(32),
    // ...responsiveFontSizes({ sm: 34, md: 41, lg: 48 }),
  },
  h2: {
    fontWeight: 600,
    // lineHeight: 64 / 48,
    fontSize: pxToRem(24),
    // ...responsiveFontSizes({ sm: 28, md: 31, lg: 34 }),
  },
  h3: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(22),
    // ...responsiveFontSizes({ sm: 22, md: 25, lg: 28 }),
  },
  h4: {
    fontWeight: 400,
    lineHeight: 1.5,
    fontSize: pxToRem(20),
    // ...responsiveFontSizes({ sm: 17, md: 20, lg: 22 }),
  },
  h5: {
    fontWeight: 700,
    lineHeight: 24 / 17,
    fontSize: pxToRem(17),
    // ...responsiveFontSizes({ sm: 17, md: 17, lg: 17 }),
  },
  h6: {
    fontWeight: 700,
    lineHeight: 18 / 15,
    fontSize: pxToRem(15),
    // ...responsiveFontSizes({ sm: 15, md: 15, lg: 15 }),
  },
  subtitle1: {
    fontWeight: 400,
    lineHeight: 1.5,
    fontSize: pxToRem(18),
  },
  subtitle2: {
    fontWeight: 400,
    // lineHeight: 1,
    fontSize: pxToRem(16),
  },
  body1: {
    lineHeight: 18 / 14,
    fontSize: pxToRem(14),
  },
  body2: {
    lineHeight: 17 / 12,
    fontSize: pxToRem(12),
  },
  caption: {
    lineHeight: 1.5,
    fontSize: pxToRem(12),
  },
  overline: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(12),
    textTransform: 'uppercase',
  },
  button: {
    fontWeight: 700,
    lineHeight: 24 / 15,
    fontSize: pxToRem(15),
    textTransform: 'capitalize',
  },
} as const;

export default typography;
