import IMAGE_SEARCH from '@/assets/icons/search.png';
import useLang from '@/hooks/useLang';
import { InputAdornment, OutlinedInput } from '@mui/material';

interface Props {
  keyword: string;
  onChange: (value: string) => void;
}

const KeywordInput: React.FC<Props> = (props: Props) => {
  const { __ } = useLang();
  return (
    <OutlinedInput
      size="small"
      value={props.keyword}
      onChange={(e) => props.onChange(e.target.value)}
      fullWidth
      placeholder={__('Search')}
      inputProps={{
        sx: {
          '&::placeholder': {
            color: '#999999',
          },
        },
      }}
      sx={{
        bgcolor: 'transparent',
        height: '40px',
        borderRadius: '8px',
        '& fieldset': {
          borderColor: '#323D4D',
          borderWidth: '1px !important',
        },
      }}
      startAdornment={
        <InputAdornment position="start">
          <img
            src={IMAGE_SEARCH}
            alt=""
            width={15}
            height={15}
            style={{
              width: 15,
              minWidth: 15,
              height: 15,
              marginRight: '-8px',
            }}
          />
        </InputAdornment>
      }
    ></OutlinedInput>
  );
};

export default KeywordInput;
