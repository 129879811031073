// material-ui
import Center from '@/components/center';
import { showBottomMenuState, showIOSDownLoadState } from '@/stores/auth';
import { supportListState } from '@/stores/config';
import { hoverMenuBg } from '@/styles';
import { Collapse, Stack } from '@mui/material';
import { useRecoilState, useSetRecoilState } from 'recoil';
import IOSDownLoadDrawer from './IOSDownLoadDrawer';

/**
 * 底部靠边菜单
 *
 * @param {{
 *   drawerOpen?: boolean;
 *   drawerToggle?: () => void;
 * }} {
 *   drawerOpen,
 *   drawerToggle,
 * }
 * @return {*}
 */
const HoverMenu = ({
  drawerOpen,
  drawerToggle,
}: {
  drawerOpen?: boolean;
  drawerToggle?: () => void;
}) => {
  const setShowIOSDownLoadState = useSetRecoilState(showIOSDownLoadState);
  const [showBottomMenu, setShowBottomMenu] =
    useRecoilState(showBottomMenuState);
  const [supportList, setSupportList] = useRecoilState(supportListState);
  const userAgent =
    typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;
  const isIOS = /iPhone|iPad|iPod/i.test(userAgent);
  const _onMenuPress = (item: any) => {
    if (item?.type == 6 && isIOS) {
      setShowIOSDownLoadState(true);
      return;
    }
    if (item?.url) {
      setShowBottomMenu(false);
      window.open(item?.url, '_blank');
    }
  };
  return (
    <Stack
      sx={{
        position: 'absolute',
        height: '100%',
        width: '100%',
        top: '0px',
        left: '0px',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        display: showBottomMenu ? 'flex' : 'none',
        zIndex: 1000,
      }}
      onClick={() => {
        setShowBottomMenu(false);
      }}
    >
      <Stack
        sx={{
          mb: '72px',
        }}
        flexDirection="row"
        justifyContent="space-between"
      >
        <Collapse in={showBottomMenu} orientation="horizontal">
          <Stack
            sx={{
              width: '100vw',
            }}
            flexDirection="row"
            justifyContent="center"
          >
            <Stack
              sx={{
                height: '64px',
                borderRadius: '16px',
                px: '24px',
                background: hoverMenuBg,
              }}
              flexDirection="row"
              justifyContent={'space-between'}
              alignItems="center"
            >
              {supportList.map((v, i) => {
                return (
                  <Center
                    key={i}
                    sx={{
                      width: '40px',
                      height: '40px',
                      bgcolor: 'common.white',
                      borderRadius: '20px',
                      ml: i == 0 ? 0 : '32px',
                    }}
                  >
                    <img
                      onClick={() => {
                        _onMenuPress(v);
                      }}
                      src={v.qr}
                      alt=""
                      style={{
                        width: '20px',
                        height: '20px',
                        objectFit: 'cover',
                      }}
                    />
                  </Center>
                );
              })}
            </Stack>
          </Stack>
        </Collapse>
      </Stack>

      <IOSDownLoadDrawer />
    </Stack>
  );
};

export default HoverMenu;
