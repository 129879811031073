import { Box, Button, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CommonTitleHead from '../../components/CommonTitleHead';
import TransationCard from '../../components/transations/TransationCard';
import useScrollToTop from '../../hooks/useScrollToTop';

interface Props {}

const GameHistory: React.FC<Props> = (props: Props) => {
  useScrollToTop();
  const navigate = useNavigate();
  return (
    <Box>
      <CommonTitleHead title="Game History" onBack={() => navigate(-1)} />
      <Grid container spacing="20px" columns={24}>
        <Grid item xs={24} md={8}>
          <TransationCard />
        </Grid>
        <Grid item xs={24} md={8}>
          <TransationCard />
        </Grid>
        <Grid item xs={24} md={8}>
          <TransationCard />
        </Grid>
        <Grid item xs={24} md={8}>
          <TransationCard />
        </Grid>
        <Grid item xs={24} md={8}>
          <TransationCard />
        </Grid>
        <Grid item xs={24} md={8}>
          <TransationCard />
        </Grid>
        <Grid item xs={24} md={8}>
          <TransationCard />
        </Grid>
        <Grid item xs={24} md={8}>
          <TransationCard />
        </Grid>
        <Grid item xs={24} md={8}>
          <TransationCard />
        </Grid>
      </Grid>

      <Box textAlign="center" mt="20px">
        <Button
          variant="contained"
          size="small"
          sx={{ borderRadius: '15px', bgcolor: '#221C3F' }}
        >
          More
        </Button>
      </Box>
    </Box>
  );
};

export default GameHistory;
