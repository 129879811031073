import { Request } from '../utils';
import { testHost } from './host';

const host = '';

export async function getInit(params: {
  media_id_list: string;
  news_id_list: string;
  parameter?: string;
}) {
  return Request.post(`${testHost}/api_load.html`, params);
}

// 获取充值券
export async function getRechargeCoupon() {
  try {
    const { data } = await Request.post(`${testHost}/api_pay_card.html`);
    return data.data;
  } catch (error) {
    return null;
  }
}

// 获取充值方式列表
export async function getDeopositMethods() {
  try {
    const { data } = await Request.post(`${testHost}/api_pay_load.html`);
    return data.data;
  } catch (error) {
    return null;
  }
}

// 获取充值方式列表
export async function getVipList() {
  try {
    const { data } = await Request.post(`${testHost}/api_vip_list.html`);
    return data.data;
  } catch (error) {
    return null;
  }
}

export async function heartbeat() {
  try {
    const { data } = await Request.post(`${testHost}/api_heartbeat.html`);
    return data;
  } catch (error) {
    return null;
  }
}

// 获取佣金信息
export async function getCommissionInfo() {
  return Request.get(`${host}/api/allAgentNew/GetCommissionInfo`);
}

export async function getBoxInfoById(id: number) {
  return Request.get(`${host}/api/reward/get_box_by_id?box_id=${id}`);
}

export async function getOwnBoxes() {
  return Request.get(`${host}/api/reward/get_own_boxes`);
}

// 获取活动列表
export async function getPromotionList() {
  try {
    const { data } = await Request.post(`${testHost}/api_activity_list.html`);
    return data.data;
  } catch (error) {
    return null;
  }
}

/**
 * 获取活动内容
 *
 * @export
 * @param {{
 *   activity_id: Number;活动id
 * }} params
 * @return {*}
 */
export async function getPromotionDetail(params: { activity_id: string }) {
  try {
    const { data } = await Request.post(
      `${testHost}/api_activity_info.html`,
      params,
    );
    return data.data;
  } catch (error) {
    return null;
  }
}

/**
 * 获取消息列表
 *
 * @export
 * @param {{
 *   page: Number;
 *   pagesize: Number;
 * }} params
 * @return {*}
 */
export async function getMessageAry(params: {
  page: number;
  pagesize: number;
}) {
  try {
    const { data } = await Request.post(
      `${testHost}/api_message_list.html`,
      params,
    );
    return data.data;

    // let data = {
    //   status: 1, //状态码
    //   msg: '', //文字提示信息
    //   total_msg:30,
    //   data: [
    //     {
    //       id: 1,
    //       title: 'Title 12311',
    //       add_time: 1699115639,
    //       is_look: 1,
    //     },
    //     {
    //       id: 2,
    //       title: 'Title 212',
    //       add_time: 1699115639,
    //       is_look: 0,
    //     },
    //     {
    //       id: 3,
    //       title: 'Title 333',
    //       add_time: 1699115639,
    //       is_look: 0,
    //     },
    //     {
    //       id: 4,
    //       title: 'Title 1',
    //       add_time: 1699115639,
    //       is_look: 1,
    //     },
    //     {
    //       id: 5,
    //       title: 'Title3 2',
    //       add_time: 1699115639,
    //       is_look: 0,
    //     },
    //     {
    //       id: 6,
    //       title: 'Title 33',
    //       add_time: 1699115639,
    //       is_look: 0,
    //     },
    //     {
    //       id: 7,
    //       title: 'Title 21',
    //       add_time: 1699115639,
    //       is_look: 1,
    //     },
    //     {
    //       id: 8,
    //       title: 'Title 22',
    //       add_time: 1699115639,
    //       is_look: 0,
    //     },
    //     {
    //       id: 9,
    //       title: 'Title 23',
    //       add_time: 1699115639,
    //       is_look: 0,
    //     },
    //     {
    //       id: 10,
    //       title: 'Title 11',
    //       add_time: 1699115639,
    //       is_look: 1,
    //     },
    //     {
    //       id: 11,
    //       title: 'Title 12',
    //       add_time: 1699115639,
    //       is_look: 0,
    //     },
    //     {
    //       id: 12,
    //       title: 'Title 13',
    //       add_time: 1699115639,
    //       is_look: 0,
    //     },
    //   ],
    // };
    // return data;
  } catch (error) {
    return null;
  }
}

/**
 * 消息内容
 *
 * @export
 * @param {{
 *   message_id: Number;
 * }} params
 * @return {*}
 */
export async function getMessageInfo(params: { message_id: number }) {
  try {
    const { data } = await Request.post(
      `${testHost}/api_message_info.html`,
      params,
    );
    return data.data;
  } catch (error) {
    return null;
  }
}

/**
 * 删除消息
 *
 * @export
 * @param {{
 *   message_id: Number;
 * }} params
 * @return {*}
 */
export async function delMessage(params: { message_id: number }) {
  try {
    const { data } = await Request.post(
      `${testHost}/api_message_delete.html`,
      params,
    );
    return data;
  } catch (error) {
    return null;
  }
}

/**
 * 代理等级列表接口
 *
 * @export
 * @param {{ sid: number }} params
 * @return {*}
 */
export async function getAgentLevelInfo(params: { sid: string }) {
  try {
    const { data } = await Request.post(
      `${testHost}/api_agent_level_list.html`,
      params,
    );
    return data;
  } catch (error) {
    return null;
  }
}

// 获取充值券
export async function getAgentSummary(start: number, end: number) {
  try {
    const { data } = await Request.post(`${testHost}/api_agent_summary.html`, {
      sSearchTime: start,
      eSearchTime: end,
    });
    return data;
  } catch (error) {
    return null;
  }
}

// 获取游戏历史
export async function getGameHistory() {
  return Request.get(`${testHost}/api_history_record.html`);
}