import useBoolean from '@/hooks/useBoolean';
import useLang from '@/hooks/useLang';
import services from '@/services';
import { isOpenBindCPFDrawState } from '@/stores/auth';
import { LoadingButton } from '@mui/lab';
import { Box, OutlinedInput, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { useRecoilState } from 'recoil';
import PhoneInput from '../Auth/login/PhoneInput';
import CommonDrawerV2 from '../common-drawer/CommonDrawerV2';
import { SnackbarUtils } from '../snackbar/SnackbarProvider';
import BirthdayInput from './BirthdayInput';

interface Props {}

const BindCPFDrawer = (props: Props) => {
  const [open, setOpen] = useRecoilState(isOpenBindCPFDrawState);
  const [loading, setLoading] = useBoolean();
  const { __ } = useLang();

  const [cardNo, setCardNo] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [birthday, setBirthDay] = useState<string>('');

  const handleSubmit = async () => {
    if (loading) {
      return;
    }

    setLoading.on();

    const params = {
      type_id: 1,
      card_no: cardNo,
      first_name: firstName,
      last_name: lastName,
      mobile: phone,
    };

    const isSuccess = await services.wallet.setCPF(params);
    setLoading.off();
    if (isSuccess) {
      SnackbarUtils.success(__('Successfully'));
      setOpen(false);
    }
  };

  return (
    <CommonDrawerV2
      title={__('Safety Verification')}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
    >
      <Box px={2.5} pt={2.5} pb={4}>
        <Stack spacing={2.5}>
          <Typography variant="subtitle2" fontWeight={700} textAlign="center">
            {__('请绑定CPF后再选择提现。')}
          </Typography>
          <PhoneInput
            phone={phone}
            onChangePhone={(v) => setPhone(v)}
            areaCode={55}
          />
          <OutlinedInput
            value={cardNo}
            onChange={(e) => setCardNo(e.target.value)}
            placeholder={__('Enter CPF')}
          ></OutlinedInput>
          <OutlinedInput
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            placeholder={__('Enter You First Name')}
          ></OutlinedInput>
          <OutlinedInput
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            placeholder={__('Enter You Last Name')}
          ></OutlinedInput>
          <BirthdayInput birthday={birthday} onChange={(v) => setBirthDay(v)} />

          <LoadingButton
            sx={{
              background: 'linear-gradient(90deg, #B304FE 0%, #0CC0F7 100%)',
              boxShadow: 'none !important',
              mt: '20px',
            }}
            fullWidth
            variant="contained"
            loading={loading}
            onClick={handleSubmit}
          >
            {__('Submit')}
          </LoadingButton>
        </Stack>
      </Box>
    </CommonDrawerV2>
  );
};

export default BindCPFDrawer;
