import { Box, Divider, Stack, Typography } from "@mui/material";

interface Props {}

const TransationCard: React.FC<Props> = (props: Props) => {
  return (
    <Box
      sx={{
        bgcolor: "#1D1839",
        borderRadius: "10px",
        px: 2,
        pb: "30px",
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ height: "54px" }}
      >
        <Typography variant="subtitle2">ID: 12321321321331231</Typography>
        <Typography variant="subtitle2">2023/04/06 10:09:23</Typography>
      </Stack>
      <Divider sx={{ borderColor: "#231E3A" }} />
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        my={2}
        spacing="36px"
      >
        <Typography
          color="#999999"
          variant="subtitle2"
          textAlign="right"
          flex={1}
        >
          Modelo
        </Typography>
        <Typography flex={1} variant="subtitle2" textAlign="left">
          Coupon
        </Typography>
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        my={2}
        spacing="36px"
      >
        <Typography
          color="#999999"
          variant="subtitle2"
          textAlign="right"
          flex={1}
        >
          Carteira
        </Typography>
        <Typography flex={1} variant="subtitle2" textAlign="left">
          BRL(real)
        </Typography>
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        my={2}
        spacing="36px"
      >
        <Typography
          color="#999999"
          variant="subtitle2"
          textAlign="right"
          flex={1}
        >
          Valor
        </Typography>
        <Typography flex={1} variant="subtitle2" textAlign="left">
          R$10.00
        </Typography>
      </Stack>
      <Stack
        direction="row"
        // alignItems="center"
        justifyContent="center"
        mt={2}
        spacing="36px"
      >
        <Typography
          color="#999999"
          variant="subtitle2"
          textAlign="right"
          flex={1}
        >
          Saldo da conta
        </Typography>
        <Stack flex={1} spacing={1.5}>
          <Typography variant="subtitle2" textAlign="left">
            R$10.00
          </Typography>
          <Typography variant="subtitle2" textAlign="left">
            R$10.00 Real
          </Typography>
          <Typography variant="subtitle2" textAlign="left">
            R$10.00 Bouns
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
};

export default TransationCard;
