import { Box, Grid, Stack, Switch, Typography } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CommonTitleHead from '../../components/CommonTitleHead';
import useScrollToTop from '../../hooks/useScrollToTop';

interface Props {}

const SettingItem = (props: { title: string }) => {
  const [checked, setChecked] = useState(true);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };
  return (
    <Box
      sx={{
        bgcolor: 'background.paper',
        px: 2,
        height: '76px',
        display: 'flex',
        alignItems: 'center',
        borderRadius: '10px',
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        <Typography>{props.title}</Typography>
        <Switch
          checked={checked}
          onChange={handleChange}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      </Stack>
    </Box>
  );
};

const PreferencesPage: React.FC<Props> = (props: Props) => {
  useScrollToTop();
  const navigate = useNavigate();
  return (
    <Box>
      <CommonTitleHead title="Setting" onBack={() => navigate(-1)} />

      <Grid container spacing="20px" columns={16}>
        <Grid item xs={16} md={8}>
          <SettingItem
            title="Receive Marketing Communications Receive email notifications about
              deposit and withdrawal updates"
          />
        </Grid>
        <Grid item xs={16} md={8}>
          <SettingItem title="Receive Messages From BrbrBet." />
        </Grid>
      </Grid>
    </Box>
  );
};

export default PreferencesPage;
