import { useRecoilState } from 'recoil';
import { en, pt } from '../locales';
import { langState, languageState } from '../stores/lang';
import { LanguageType } from '../types';

const useLang = () => {
  const [lang, setLang] = useRecoilState(langState);
  const [language, setLanguage] = useRecoilState(languageState);
  const __ = (key: string) => {
    return lang[key] || key;
  };

  const modifyLanguage = (val: LanguageType) => {
    setLang(val === LanguageType.EN ? en : pt);
    setLanguage(val);
    localStorage.setItem('__language__', val);
  };

  return {
    __,
    lang,
    language,
    modifyLanguage,
  };
};

export default useLang;
