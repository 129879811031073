import ICON_BR from '@/assets/icons/icon_br.png';
import ICON_DOWN from '@/assets/icons/icon_down.png';
import ICON_EN from '@/assets/icons/icon_english.png';
import useLang from '@/hooks/useLang';
import useResponsive from '@/hooks/useResponsive';
import { LanguageType } from '@/types';
import { Box, Button, Menu, MenuItem, Stack, Typography } from '@mui/material';
import Fade from '@mui/material/Fade';
import { useState } from 'react';

const LanguageRow = ({
  src,
  lang,
  show = false,
}: {
  src: string;
  lang: string;
  show?: boolean;
}) => {
  const isMobile = useResponsive('down', 'md');
  return (
    <Stack direction="row" spacing="3px" alignItems="center">
      <img
        style={{
          width: isMobile ? 16 : 22,
          height: isMobile ? 16 : 22,

          verticalAlign: 'middle',
        }}
        src={src}
        alt=""
      />
      <Typography variant="body1">{lang}</Typography>
      {show ? (
        <img src={ICON_DOWN} alt="" style={{ width: 8, height: 8 }} />
      ) : null}
    </Stack>
  );
};

const LocaleButton = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { language, modifyLanguage } = useLang();
  const isMobile = useResponsive('down', 'md');

  const handleClickEn = () => {
    setAnchorEl(null);
    modifyLanguage(LanguageType.EN);
  };

  const handleClickPt = () => {
    setAnchorEl(null);
    modifyLanguage(LanguageType.PT);
  };

  return (
    <Box pl={2}>
      <Button
        sx={{
          px: 2,
          py: 1.5,
          borderRadius: 999,
          borderColor: '#3C3952',
        }}
        color="info"
        variant="outlined"
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <LanguageRow
          src={language === LanguageType.EN ? ICON_EN : ICON_BR}
          lang={language === LanguageType.EN ? 'English' : 'Português'}
          show={true}
        />
      </Button>
      <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        TransitionComponent={Fade}
        PaperProps={{
          sx: {
            bgcolor: 'card.main',
            width: isMobile ? 124 : 248,
          },
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <MenuItem onClick={handleClickEn}>
          <LanguageRow src={ICON_EN} lang="English" />
        </MenuItem>
        <MenuItem onClick={handleClickPt}>
          <LanguageRow src={ICON_BR} lang="Português" />
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default LocaleButton;
