import Center from '@/components/center';
import { hideScrollbarX } from '@/utils/cssStyles';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Box, Dialog, IconButton, Typography } from '@mui/material';

const GiftItem = (props: { pic?: string; title?: string }) => {
  return (
    <Box
      sx={{
        p: 2,
        background: 'linear-gradient(180deg, #F17BFF 0%, #9C46EE 100%)',
        borderRadius: 1,
        width: 112,
        minWidth: 112,
        mx: 0.5,
      }}
    >
      <Center>
        <img
          src={props.pic}
          alt=""
          style={{
            width: 80,
            height: 80,
            borderRadius: 8,
            overflow: 'hidden',
          }}
        />
      </Center>
      <Typography mt={1} textAlign="center" variant="body1">
        {props.title}
      </Typography>
    </Box>
  );
};

interface Props {
  open: boolean;
  onClose: () => void;
  list: Array<{
    pic?: string;
    title?: string;
  }>;
}

const GiftDialog: React.FC<Props> = (props: Props) => {
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      PaperProps={{ sx: { bgcolor: 'transparent', boxShadow: 'none' } }}
    >
      <Box minWidth={300}>
        <Box
          bgcolor="white"
          py={4}
          px={1.5}
          borderRadius="16px"
          sx={{
            ...hideScrollbarX,
          }}
        >
          <Box
            sx={{
              display: 'flex',
            }}
          >
            {props.list.map((item, index) => (
              <GiftItem key={index} pic={item.pic} title={item.title} />
            ))}
          </Box>
        </Box>

        <Box sx={{ mt: 2, textAlign: 'center' }}>
          <IconButton
            sx={{ bgcolor: 'background.neutral' }}
            size="small"
            onClick={props.onClose}
          >
            <CloseRoundedIcon sx={{ color: 'white' }} />
          </IconButton>
        </Box>
      </Box>
    </Dialog>
  );
};

export default GiftDialog;
