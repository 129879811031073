import useBoolean from '@/hooks/useBoolean';
import { Box, Button, Popover, Typography } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useRef, useState } from 'react';

interface Props {
  date: string;
  onChange: (value: string) => void;
  maxDate?: any;
  minDate?: any;
  justifyContent?: 'start' | 'center' | 'end';
}

const DatePicker: React.FC<Props> = (props: Props) => {
  const pickerRef = useRef<HTMLButtonElement | null>(null);
  const [isOpen, setIsOpen] = useBoolean();
  const [value, setValue] = useState<Dayjs | null>(null);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (props.date) {
      setValue(dayjs(props.date));
    }
  }, [props.date]);

  const handleCloseDelay = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      setIsOpen.off();
    }, 100);
  };

  return (
    <>
      <Button
        ref={pickerRef}
        fullWidth
        variant="text"
        size="small"
        color="info"
        onClick={setIsOpen.on}
        sx={{
          justifyContent: props.justifyContent ?? 'center',
        }}
      >
        <Typography variant="body2">{props.date}</Typography>
      </Button>
      <Popover
        anchorEl={pickerRef.current}
        open={isOpen}
        onClose={setIsOpen.off}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: -10,
          horizontal: 'right',
        }}
      >
        <Box sx={{ width: '100%' }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateCalendar
              value={value}
              maxDate={props.maxDate}
              minDate={props.minDate}
              onChange={(newValue) => {
                setValue(newValue);
                props.onChange(newValue!.format('YYYY-MM-DD'));
                handleCloseDelay();
              }}
            />
          </LocalizationProvider>
        </Box>
      </Popover>
    </>
  );
};

export default DatePicker;
