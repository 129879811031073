import { PageHeader } from '@/components/BackHeader';
import Center from '@/components/center';
import CommonSkeleton from '@/components/CommonSkeleton';
import DepositAmounts, {
  ExtraMoney,
} from '@/components/deposit/DepositAmounts';
import DepositPaymentMethods from '@/components/deposit/DepositPaymentMethods';
import { SnackbarUtils } from '@/components/snackbar/SnackbarProvider';
import useAuth from '@/hooks/useAuth';
import useBoolean from '@/hooks/useBoolean';
import { useDepositCoupons } from '@/hooks/useDepositCoupons';
import useLang from '@/hooks/useLang';
import services from '@/services';
import { payChannelDataState } from '@/stores/config';
import { ColorfulBg } from '@/styles';
import { customColor } from '@/theme/customColor';
import { IDeposit, IDepositCoupon } from '@/types/config';
import { hideScrollbarX } from '@/utils/cssStyles';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Dialog,
  InputAdornment,
  OutlinedInput,
  Stack,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { Coupon } from './Coupon';

interface Props {
  type?: 'page' | 'dialog';
  height?: string;
}

const MAX_DEPOSIT_AMOUNT = 20000;

const DepositPage: React.FC<Props> = (props: Props) => {
  const isDialog = props.type === 'dialog';

  const navigate = useNavigate();
  const { __ } = useLang();
  const [loading, setLoading] = useState<boolean>(true);
  const [depositList, setDepositList] = useState<IDeposit[]>([]);
  const [selectedDeposit, setSelectedDeposit] = useState<IDeposit | null>(null);
  const [money, setMoney] = useState<number>(0);
  const [inputMoney, setInputMoney] = useState<string>('');
  const [isOpenDepositUrl, setIsOpenDepositUrl] = useBoolean();
  const [depositUrl, setDepositUrl] = useState<string>('');
  const [depositLoading, setDepositLoading] = useState<boolean>(false);
  const { coupons } = useDepositCoupons();
  const [selectedCoupon, setSelectedCoupon] = useState<IDepositCoupon>();
  const { getWallet, isLogin, openLogin } = useAuth();
  const [payChannelData, setPayChannelData] =
    useRecoilState(payChannelDataState);

  useEffect(() => {
    const fetchData = async () => {
      const data = payChannelData; // await services.config.getDeopositMethods();
      if (data?.onlinepay_list) {
        const list = Object.keys(data.onlinepay_list).map(
          (key) => data.onlinepay_list[key],
        );
        setDepositList(list);
        if (list[0]) {
          setSelectedDeposit(list[0]);
        }
      }
      setLoading(false);
    };

    fetchData();
  }, [payChannelData]);

  // 是否可以
  const isAvaliable = !!selectedDeposit && (money > 0 || +inputMoney > 0);

  const getDepositParams = (deposit: IDeposit, coupon?: IDepositCoupon) => {
    let params: any = {
      pay_id: deposit.id,
    };
    if (money === 0) {
      const { min_money, max_money } = deposit!;
      if (+inputMoney > max_money || +inputMoney < min_money) {
        SnackbarUtils.error(
          __('moneyLimit')
            .replace('{min}', min_money)
            .replace('{max}', max_money),
        );
        return null;
      }
      params['money'] = inputMoney;
    } else {
      params['money'] = `${money}`;
    }

    if (coupon) {
      params['card_id'] = coupon.id;
    }
    return params;
  };

  const handleDeposit = async () => {
    if (!isLogin) {
      openLogin();
      return;
    }
    if (depositLoading) {
      return;
    }
    if (!selectedDeposit) {
      return;
    }
    const params = getDepositParams(selectedDeposit, selectedCoupon);
    if (!params) {
      return;
    }

    setDepositLoading(true);
    try {
      const { data } = await services.wallet.deposit(params);
      const { type = '', info = '' } = data;
      if (type === 'url' && info) {
        setIsOpenDepositUrl.on();
        setDepositUrl(info);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setDepositLoading(false);
    }
  };

  const handleSelectDepositType = (item: IDeposit) => {
    setSelectedDeposit(item);
    setMoney(0);
    setInputMoney('');
  };

  const handleChangeInputMoney: React.ChangeEventHandler<HTMLInputElement> = (
    e,
  ) => {
    const value = e.target.value;
    const moneyT = value.replace(/[^\d]/g, '');
    setInputMoney(moneyT);
    setMoney(0);
  };

  // inputMoney 限制最多2位小数
  // 限制最大输入 20000
  const handleAmountBlur = () => {
    if (+inputMoney > MAX_DEPOSIT_AMOUNT) {
      setInputMoney(MAX_DEPOSIT_AMOUNT.toString());
      return;
    }
    const moneyT = inputMoney?.replace(/[^\d]/g, '');
    const value = +moneyT;
    if (value > 0) {
      setInputMoney(moneyT);
    }

    // if (inputMoney.includes('.') && inputMoney.split('.')[1]?.length > 2) {
    //   const value = +inputMoney;
    //   if (value > 0) {
    //     setInputMoney(value.toFixed(2));
    //   }
    // }
  };

  const handleChangeMoney = (v: number) => {
    setMoney(v);
    setInputMoney('');
  };

  const closeDepositDialog = () => {
    getWallet();
    setIsOpenDepositUrl.off();
  };

  if (loading) {
    return (
      <Box minHeight={isDialog ? props.height : '100vh'}>
        {isDialog ? null : (
          <Center height={60}>
            <PageHeader onBack={() => navigate('/')} title={__('Deposit')} />
          </Center>
        )}
        <CommonSkeleton />
      </Box>
    );
  }

  return (
    <Box
      minHeight={isDialog ? props.height : '100vh'}
      maxHeight={isDialog ? props.height : '100vh'}
      sx={{
        overflowY: 'auto',
      }}
      display="flex"
      flexDirection="column"
    >
      {isDialog ? null : (
        <Center height={60}>
          <PageHeader onBack={() => navigate('/')} title={__('Deposit')} />
        </Center>
      )}

      <Box px={2} flex="auto">
        <Stack spacing={1.5}>
          <Typography variant="subtitle2" fontWeight={700}>
            {__('Select A Channel')}
          </Typography>
          <DepositPaymentMethods
            depositList={depositList}
            selectedDeposit={selectedDeposit}
            onSelect={handleSelectDepositType}
          />
        </Stack>
        <DepositAmounts
          deposit={selectedDeposit}
          money={money}
          onChangeMoney={handleChangeMoney}
          selectedCoupon={selectedCoupon}
        />
        <Box pt={1.5}>
          <OutlinedInput
            value={inputMoney}
            startAdornment={
              <Typography variant="body1" sx={{ fontSize: 18 }}>
                R$
              </Typography>
            }
            onChange={handleChangeInputMoney}
            fullWidth
            type="number"
            placeholder={__('Enter your amount')}
            onBlur={handleAmountBlur}
            style={{ backgroundColor: '#232B36' }}
            inputProps={{
              sx: {
                fontSize: 24,
                '&::placeholder': {
                  fontSize: 18,
                },
              },
            }}
            endAdornment={
              <InputAdornment position="end">
                <ExtraMoney money={+inputMoney || 0} coupon={selectedCoupon} />
              </InputAdornment>
            }
          />
        </Box>
        {coupons.length > 0 ? (
          <>
            <Typography mb={1.5} mt={2.5} variant="subtitle2" fontWeight={700}>
              {__('Select Coupon (n)').replace('{n}', `${coupons.length}`)}
            </Typography>
            <Stack
              direction="row"
              spacing={1.5}
              sx={{
                ...hideScrollbarX,
              }}
            >
              {coupons.map((item, index) => {
                return (
                  <Coupon
                    key={index}
                    data={item}
                    isSelect={item.id === selectedCoupon?.id}
                    onSelect={() => {
                      if (selectedCoupon?.id === item.id) {
                        setSelectedCoupon(void 0);
                        return;
                      } else {
                        setSelectedCoupon(item);
                      }
                    }}
                  />
                );
              })}
            </Stack>
          </>
        ) : null}
      </Box>

      <Box pb={3} px={2} pt={2}>
        <LoadingButton
          fullWidth
          disabled={!isAvaliable}
          variant="contained"
          onClick={handleDeposit}
          loading={depositLoading}
          sx={{
            // background: isAvaliable ? ColorfulBg : '#f00',
            borderRadius: '8px',
          }}
          style={{
            backgroundColor: isAvaliable
              ? ColorfulBg()
              : customColor.disable_button_bg,
          }}
        >
          {__('Deposit')}
        </LoadingButton>
      </Box>

      <Dialog
        fullWidth
        maxWidth="md"
        open={isOpenDepositUrl}
        onClose={() => {
          closeDepositDialog();
        }}
      >
        <Box>
          <iframe
            title="deposit"
            src={depositUrl}
            frameBorder={0}
            id="gameIframe"
            style={{
              width: '100%',
              height: '600px',
              verticalAlign: 'middle',
            }}
          ></iframe>
        </Box>
      </Dialog>
    </Box>
  );
};

export default DepositPage;
