import CommonSkeleton from '@/components/CommonSkeleton';
import { SnackbarUtils } from '@/components/snackbar/SnackbarProvider';
import useAuth from '@/hooks/useAuth';
import useBoolean from '@/hooks/useBoolean';
import useLang from '@/hooks/useLang';
import AuthTabs from '@/pages/Auth/components/AuthTabs';
import services from '@/services';
import { isOpenLoginModalState } from '@/stores/auth';
import { SigninType } from '@/types';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Dialog, Divider, IconButton, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import LoginForm from '../login/LoginForm';

const SigninModal = () => {
  const [isOpen, setIsOpen] = useRecoilState(isOpenLoginModalState);

  const [isLoading, setIsLoading] = useBoolean(true);
  const search = new URLSearchParams(window.location.search);
  const guid = search.get('guid');
  const id = search.get('id');
  const navigate = useNavigate();
  const { login } = useAuth();
  const { __ } = useLang();

  const handleClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen && guid && id) {
      fetchOauthGuid({ guid, id });
    } else {
      setIsLoading.off();
    }
  }, [guid, id, isOpen]);

  const fetchOauthGuid = async (params: { guid: string; id: string }) => {
    const res = await services.auth.getOauthGuid({
      ...params,
      type: 1,
    });
    console.log(res);
    if (res?.data?.token) {
      // TODO 登录
      login(res?.data);
    } else if (res?.data?.is_bind === 2 || res?.data?.is_binding === 2) {
      setIsLoading.off();
      SnackbarUtils.info(__('您还未绑定过账号，请先注册并自动绑定'));
      navigate('/signup?guid=' + guid + '&id=' + id);
    } else {
      // 其他错误
      setIsLoading.off();
    }
  };

  const HeaderView = (
    <Box
      position="relative"
      height={56}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Typography variant="h3" textAlign="center">
        {__('登录')}
      </Typography>
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          width: 56,
          height: 56,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <IconButton color="info" onClick={handleClose}>
          <CloseIcon sx={{ fontSize: 24, color: '#8B899C' }} />
        </IconButton>
      </Box>
    </Box>
  );

  return (
    <Dialog
      open={isOpen}
      PaperProps={{
        sx: {
          width: '400px',
          maxWidth: '400px',
        },
      }}
      onClose={() => setIsOpen(false)}
      aria-labelledby="sign-in"
    >
      <Box sx={{ flex: 1 }} bgcolor="background.neutral">
        {isLoading ? (
          <CommonSkeleton />
        ) : (
          <>
            {HeaderView}
            <Divider sx={{ mb: 1 }} />
            <Box px={2} pb={0.5}>
              <AuthTabs
                emailTab={
                  <LoginForm
                    id={id || ''}
                    guid={guid || ''}
                    type={SigninType.Email}
                    displayType="dialog"
                  />
                }
                phoneTab={
                  <LoginForm
                    id={id || ''}
                    guid={guid || ''}
                    type={SigninType.Phone}
                    displayType="dialog"
                  />
                }
              />
            </Box>
          </>
        )}
      </Box>
    </Dialog>
  );
};

export default SigninModal;
