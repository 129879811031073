import { Box, Button, Stack, Typography } from '@mui/material';

import useLang from '@/hooks/useLang';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
interface Props {
  title: string;
  desc?: string;
  icon?: string;
  children: React.ReactNode;
  onClickMore?: () => void;
  onPrev?: () => void;
  onNext?: () => void;
  hasNext?: boolean;
  hasPrev?: boolean;
}

const HomeSectionContainer: React.FC<Props> = (props: Props) => {
  const { __ } = useLang();
  return (
    <Box>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        py={1.5}
      >
        <Stack direction="row" alignItems="center">
          {props?.icon ? (
            <img
              src={props.icon}
              alt=""
              style={{
                width: '16px',
                height: '16px',
                marginRight: '4px',
              }}
            />
          ) : null}

          <Typography
            variant="subtitle2"
            sx={{ textTransform: 'capitalize', fontWeight: 700 }}
          >
            {props.title}
          </Typography>
        </Stack>

        <Stack direction="row" alignItems="center" spacing={1}>
          <Button
            sx={{
              height: 25,
              color: '#8B899C',
              borderColor: '#323D4D !important',
              borderRadius: '4px',
              fontSize: 12,
              fontWeight: 400,
              minWidth: 0,
              px: 1,
            }}
            variant="outlined"
            size="small"
            onClick={props.onClickMore}
          >
            {__('View All')}
          </Button>
          {!!props.onPrev && (
            <Button
              size="small"
              variant="outlined"
              aria-label="prev"
              onClick={props.onPrev}
              sx={{
                width: 25,
                height: 25,
                minWidth: 25,
                borderRadius: '50%',
                borderColor: '#35314B !important',
                color: props.hasPrev ? '#fff' : '#8B899C',
              }}
            >
              <ChevronRightRoundedIcon
                fontSize="small"
                sx={{ transform: 'rotate(180deg)' }}
              />
            </Button>
          )}
          {!!props.onNext && (
            <Button
              size="small"
              variant="outlined"
              aria-label="prev"
              onClick={props.onNext}
              sx={{
                width: 25,
                height: 25,
                minWidth: 25,
                borderRadius: '50%',
                borderColor: '#35314B !important',
                color: props.hasNext ? '#fff' : '#8B899C',
              }}
            >
              <ChevronRightRoundedIcon fontSize="small" />
            </Button>
          )}
        </Stack>
      </Stack>
      {props.desc ? (
        <Typography color="text.secondary" variant="body2" fontWeight={500}>
          {props.desc}
        </Typography>
      ) : null}
      {props.children}
    </Box>
  );
};

export default HomeSectionContainer;
