// import HEADICON from '@/assets/icons/home_t_d.gif';
import { showIOSDownLoadState } from '@/stores/auth';
import { supportListState } from '@/stores/config';
import { useEffect } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';

import HEADICON from '@/assets/icons/icon_download.svg';
import Center from '@/components/center';
import useLang from '@/hooks/useLang';
import { Stack } from '@mui/material';
import { useState } from 'react';

interface Props {}

const HeaderDownLoad: React.FC<Props> = (props: Props) => {
  const { __ } = useLang();
  const [supportList, setSupportList] = useRecoilState(supportListState);
  const setShowIOSDownLoadState = useSetRecoilState(showIOSDownLoadState);
  const [standAlone, setStandAlone] = useState<boolean>(false);

  const userAgent =
    typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;
  const isIOS = /iPhone|iPad|iPod/i.test(userAgent);
  let liveSupport = supportList.find((su) => su.type == 6);
  const handleDl = async () => {
    if (liveSupport?.type == 6 && isIOS) {
      setShowIOSDownLoadState(true);
      return;
    }
    if (liveSupport?.url) {
      window.open(liveSupport.url, '_blank');
    }
  };

  function isStandalone() {
    //alert("ios:"+navigator.standalone+";  Android:"+(window.matchMedia('(display-mode: standalone)').matches)+";  Android:"+(window.matchMedia('(display-mode: fullscreen)').matches))
    return (
      window.matchMedia('(display-mode: fullscreen)').matches ||
      window.matchMedia('(display-mode: standalone)').matches
    );
  }

  useEffect(() => {
    if (isStandalone()) {
      setStandAlone(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (standAlone) {
    return null;
  }

  return (
    <>
      <Center onClick={handleDl}>
        <Stack direction="row" alignItems="center">
          <img
            src={HEADICON}
            alt=""
            style={{ width: '24px', height: '24px', minWidth: '24px' }}
          />
        </Stack>
      </Center>
    </>
  );
};

export default HeaderDownLoad;
