import useOffSetTop from '@/hooks/useOffSetTop';
import HomeHeaderGuide from '@/layouts/Header/HomeHeaderGuide';
import {
  isLoginState,
  isOpenDepositState,
  isOpenForgetPwModalState,
  isOpenLRModalState,
  showBottomMenuState,
} from '@/stores/auth';
import { homeTab, isShowHomeGuide, supportListState } from '@/stores/config';
import { HomeTab } from '@/types';
import {
  AppBar,
  Box,
  CssBaseline,
  Stack,
  Toolbar,
  useMediaQuery,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  drawerWidth,
  footerbarHeight,
  mobileHeaderbarHeight,
  webHeaderbarHeight,
} from '../theme';
import MoblieAppbar from './Footerbar';
import Header from './Header';
import HoverMenu from './HoverMenu';
import Sidebar from './Siderbar';

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme }) => ({
    // backgroundColor: theme.background,
    width: '100%',
    minHeight: 'calc(100vh - 72px)',
    flexGrow: 1,
    padding: '0 40px',
    marginTop: '72px',
    position: 'relative',
    // borderRadius: `${theme?.customization?.borderRadius}px`,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.up('md')]: {
      marginLeft: 0,
      width: `calc(100% - ${drawerWidth}px)`,
      // width: '100%',
    },
    [theme.breakpoints.down('md')]: {
      margin: '0px',
      width: '100%',
      minHeight: '100vh',
      maxWidth: '100vw',
      // width: `calc(100% - ${drawerWidth}px)`,
      overflowX: 'hidden',
      padding: `${mobileHeaderbarHeight}px 16px ${footerbarHeight}px`,
    },
    [theme.breakpoints.down('sm')]: {
      margin: '0px',
      minHeight: '100vh',
      // minHeight: `calc(100vh + ${footerHeight + footerbarHeight}px)`,
      maxWidth: '100vw',
      width: '100%',
      overflowX: 'hidden',
      padding: `${mobileHeaderbarHeight}px 16px ${0}px`,
    },
    paddingBottom: 0,
  }),
);

const NO_FOOTER_PATHS = [
  '/account',
  '/wallet',
  '/deposit',
  '/vip',
  '/commissions',
  '/records',
  '/setting',
];

const NO_HEADER_PATHS: string | string[] = [];

const APP_BAR_PATHS = ['/', '/commissions', '/vip', '/lucky', '/me'];
const DOWNLOAD_GUIDE_PATHS = ['/'];
const LUCKY_PATHS = ['/lucky'];

const Layouts = (props: { children?: React.ReactNode }) => {
  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
  const isOffset = useOffSetTop(5);
  // const isOffsetHeader = useOffSetTop((window.innerWidth * 24) / 100);
  const [leftDrawerOpened, setLeftDrawerOpened] = useState<boolean>(true);
  const isLogin = useRecoilValue(isLoginState);
  const [isShowDLGuide, setIsShowDLGuide] = useRecoilState(isShowHomeGuide);
  const [isOpenLR, setIsOpenLR] = useRecoilState(isOpenLRModalState);
  const [openDeposit, setOpenDeposit] = useRecoilState(isOpenDepositState);
  const [forgotPwd, setForgotPwd] = useRecoilState(isOpenForgetPwModalState);
  const [supportList, setSupportList] = useRecoilState(supportListState);
  const [showBottomMenu, setShowBottomMenu] =
    useRecoilState(showBottomMenuState);
  const location = useLocation();
  const homeTabValue = useRecoilValue(homeTab);

  const isMobile = !matchUpMd;
  const isHideMobleFooter =
    isMobile && NO_FOOTER_PATHS.includes(location.pathname);
  const isHideMobileHeader =
    isMobile && NO_HEADER_PATHS.includes(location.pathname);
  const isHideAppFooterBar = !APP_BAR_PATHS.includes(location.pathname);
  // 首页显示下载引导
  const isShowDLGuideT =
    isLogin &&
    isMobile &&
    homeTabValue === HomeTab.Home &&
    DOWNLOAD_GUIDE_PATHS.includes(location.pathname) &&
    !isShowDLGuide;

  // 首页显示底部按钮
  const isShowBottomBtn =
    isMobile &&
    homeTabValue === HomeTab.Home &&
    !isOpenLR &&
    !openDeposit &&
    !forgotPwd &&
    DOWNLOAD_GUIDE_PATHS.includes(location.pathname);
  let TGSupport = supportList.find((su) => su.type == 2);
  let liveSupport = supportList.find((su) => su.type == 5);
  const heanderHeight = isMobile ? mobileHeaderbarHeight : webHeaderbarHeight;
  // const footerRealHeight = isMobile ? h5FooterHeight : footerHeight;
  return (
    <Box
      sx={{
        display: 'flex',
        bgcolor: 'background.default',
        // background: {
        //   xs: `url(${BG})`,
        //   md: '#08010F',
        // },
        // backgroundSize: '100% auto',
        // backgroundRepeat: 'no-repeat',
        // backgroundPosition: 'center top',
      }}
    >
      {isMobile ? <HoverMenu /> : null}
      {/* {isShowBottomBtn ? <HomeFooterDownLoad /> : null} */}
      {isShowBottomBtn ? (
        <Stack
          sx={{
            position: 'absolute',
            right: '12px',
            bottom: '72px',
            zIndex: 2000,
            display: 'flex',
            flexDirection: 'row',
            // width: 'calc(100vw - 24px)',
            alignItems: 'flex-end',
            justifyContent: 'space-between',
          }}
          // style={{ pointerEvents: 'none' }}
        >
          {/* <Box
            style={{
              width: 40,
              height: 40,
            }}
          ></Box>
          <HomeFooterDownLoad /> */}
          <Stack>
            {TGSupport ? (
              <img
                onClick={(event) => {
                  event.stopPropagation();
                  window.open(TGSupport.url, '_blank');
                }}
                src={TGSupport.qr}
                alt=""
                style={{
                  width: 44,
                  height: 44,
                  objectFit: 'cover',
                }}
              />
            ) : null}
            {liveSupport ? (
              <img
                onClick={(event) => {
                  event.stopPropagation();
                  // window.location.href = liveSupport.url;
                  window.open(liveSupport.url, '_blank');
                }}
                src={liveSupport.qr}
                alt=""
                style={{
                  width: 44,
                  height: 44,
                  objectFit: 'cover',
                  marginTop: '16px',
                }}
              />
            ) : null}
          </Stack>
        </Stack>
      ) : null}

      <CssBaseline />
      <AppBar
        enableColorOnDark
        position="fixed"
        color="inherit"
        elevation={0}
        sx={{
          display: isHideMobileHeader ? 'none' : 'flex',
          bgcolor: {
            xs: isOffset ? 'background.default' : 'transparent',
            md: 'card.main',
          },
          // bgcolor: bgcolorChangeOffset
          //   ? 'background.default'
          //   : 'rgba(8, 1, 15, 0.1)',
          pl: {
            xs: 0,
            md: leftDrawerOpened ? `${drawerWidth}px` : 0,
          },
          height: matchUpMd ? '72px' : '56px',
          justifyContent: 'center',
          transition: theme.transitions.create('width'),
        }}
      >
        <Box sx={{ px: { xs: 2, md: 4 } }}>
          <Box
            sx={{
              maxWidth: '1260px',
              width: '100%',
              margin: matchUpMd ? '0 auto' : '0 auto',
            }}
          >
            <Toolbar sx={{ px: { xs: 0, md: 0 }, zIndex: 200 }}>
              <Header />
            </Toolbar>
            {isShowDLGuideT ? <HomeHeaderGuide /> : null}
          </Box>
        </Box>
      </AppBar>

      <Sidebar
        drawerOpen={leftDrawerOpened}
        drawerToggle={() => setLeftDrawerOpened((prev) => !prev)}
      />

      {matchUpMd || isHideAppFooterBar ? null : <MoblieAppbar />}

      <Main
        sx={{
          px: {
            xs:
              (homeTabValue === HomeTab.Spinner &&
                ['/'].includes(location.pathname)) ||
              homeTabValue === HomeTab.Vip ||
              LUCKY_PATHS.includes(location.pathname)
                ? 0
                : 2, //修订抽奖也没拉伸
            md: 4,
          },
          pt: {
            xs:
              isHideMobileHeader ||
              (homeTabValue === HomeTab.Spinner &&
                ['/'].includes(location.pathname)) ||
              LUCKY_PATHS.includes(location.pathname)
                ? 0
                : `${heanderHeight}px`,
            md: 0,
          },
          pb: {
            xs: isHideMobleFooter
              ? 4
              : isHideAppFooterBar
              ? `${0}px`
              : `${footerbarHeight}px`,
            md: `${0}px`,
          },
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          sx={{
            maxWidth: '1260px',
            margin: matchUpMd ? '0 auto 40px' : '0 auto 20px',
          }}
        >
          <Box flex="1 0 auto">
            <Outlet />
          </Box>
        </Box>
        {/* <Box
          sx={{
            position: 'absolute',
            // bottom: matchUpMd ? 0 : footerbarHeight,
            bottom: matchUpMd || isHideAppFooterBar ? 0 : footerbarHeight,
            right: 0,
            width: '100%',
            height: { xs: `${h5FooterHeight}px`, md: `${footerHeight}px` },
            display: isHideMobleFooter ? 'none' : 'block',
          }}
        >
          <Footer />
        </Box> */}
      </Main>
    </Box>
  );
};

export default Layouts;
