import { Box } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import useResponsive from '@/hooks/useResponsive';
import { bannerList } from '@/stores/config';
import { IBannerItem } from '@/types/config';
import { useRecoilValue } from 'recoil';
import { Autoplay, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';

import HomeBanner from './Banner';

interface Props {}

const HomeBannerV2: React.FC<Props> = (props: Props) => {
  const isMobile = useResponsive('down', 'md');
  const bannerListValue = useRecoilValue(bannerList);

  const handleClick = (data: IBannerItem) => {
    if (!data.url) {
      return;
    }

    if (+data.target === 1) {
      window.open(data.url, '_blank');
    } else {
      window.location.href = data.url;
    }
  };

  if (isMobile) {
    return <HomeBanner />;
  }

  return (
    <Box my={2}>
      <Swiper
        centeredSlides={true}
        slidesPerView={3}
        autoplay={{
          delay: 2000,
          reverseDirection: false,
        }}
        spaceBetween={16}
        loop
        pagination={true}
        modules={[Pagination, Autoplay]}
        className="mySwiper"
      >
        {bannerListValue.map((banner, index) => {
          return (
            <SwiperSlide key={`${banner.title}-${index}`}>
              <Box
                sx={{
                  borderRadius: '10px',
                  width: '100%',
                  overflow: 'hidden',
                  aspectRatio: '2/1',
                }}
                onClick={() => handleClick(banner)}
              >
                <img
                  style={{
                    width: '100%',
                    height: '100%',
                    borderRadius: isMobile ? '10px' : '0',
                    objectFit: 'fill',
                  }}
                  src={banner.pic}
                  // src={index % 2 === 1 ? bannerImage : bannerImage1}
                  alt={banner.title}
                />
              </Box>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </Box>
  );
};

export default HomeBannerV2;
