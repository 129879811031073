export const SPINNER_PRIZES = [
  {
    unranked: [
      { reward: "10-2", image: "icon_rewards_10-2.png" },
      { reward: "4", image: "" },
      { reward: "4", image: "" },
      { reward: "10-1", image: "icon_rewards_10-1.png" },
      { reward: "4", image: "" },
      { reward: "4", image: "" },
      { reward: "5", image: "icon_rewards_5.png" },
      { reward: "4", image: "" },
      { reward: "4", image: "" },
      { reward: "10-2", image: "icon_rewards_10-2.png" },
      { reward: "4", image: "" },
      { reward: "4", image: "" },
      { reward: "7", image: "icon_rewards_7.png" },
      { reward: "4", image: "" },
      { reward: "4", image: "" },
      { reward: "10-2", image: "icon_rewards_10-2.png" },
      { reward: "4", image: "" },
      { reward: "4", image: "" },
      { reward: "8", image: "icon_rewards_8.png" },
      { reward: "4", image: "" },
      { reward: "4", image: "" },
      { reward: "10-1", image: "icon_rewards_10-1.png" },
      { reward: "4", image: "" },
      { reward: "4", image: "" },
    ],
    bronze: [
      { reward: "10-2", image: "icon_rewards_10-2.png" },
      { reward: "4", image: "" },
      { reward: "4", image: "" },
      { reward: "10-1", image: "icon_rewards_10-1.png" },
      { reward: "4", image: "" },
      { reward: "4", image: "" },
      { reward: "5", image: "icon_rewards_5.png" },
      { reward: "4", image: "" },
      { reward: "4", image: "" },
      { reward: "10-2", image: "icon_rewards_10-2.png" },
      { reward: "4", image: "" },
      { reward: "4", image: "" },
      { reward: "7", image: "icon_rewards_7.png" },
      { reward: "4", image: "" },
      { reward: "4", image: "" },
      { reward: "10-2", image: "icon_rewards_10-2.png" },
      { reward: "4", image: "" },
      { reward: "4", image: "" },
      { reward: "8", image: "icon_rewards_8.png" },
      { reward: "4", image: "" },
      { reward: "4", image: "" },
      { reward: "10-1", image: "icon_rewards_10-1.png" },
      { reward: "4", image: "" },
      { reward: "4", image: "" },
    ],
    silver: [
      { reward: "1-1", image: "icon_rewards_1-1.png" },
      { reward: "4", image: "" },
      { reward: "4", image: "" },
      { reward: "10-1", image: "icon_rewards_10-1.png" },
      { reward: "4", image: "" },
      { reward: "4", image: "" },
      { reward: "5", image: "icon_rewards_5.png" },
      { reward: "4", image: "" },
      { reward: "4", image: "" },
      { reward: "10-2", image: "icon_rewards_10-2.png" },
      { reward: "4", image: "" },
      { reward: "4", image: "" },
      { reward: "7", image: "icon_rewards_7.png" },
      { reward: "4", image: "" },
      { reward: "4", image: "" },
      { reward: "11", image: "icon_rewards_11.png" },
      { reward: "4", image: "" },
      { reward: "4", image: "" },
      { reward: "8", image: "icon_rewards_8.png" },
      { reward: "4", image: "" },
      { reward: "4", image: "" },
      { reward: "10-1", image: "icon_rewards_10-1.png" },
      { reward: "4", image: "" },
      { reward: "4", image: "" },
    ],
    gold: [
      { reward: "1-2", image: "icon_rewards_1-2.png" },
      { reward: "4", image: "" },
      { reward: "4", image: "" },
      { reward: "10-1", image: "icon_rewards_10-1.png" },
      { reward: "4", image: "" },
      { reward: "4", image: "" },
      { reward: "5", image: "icon_rewards_5.png" },
      { reward: "4", image: "" },
      { reward: "4", image: "" },
      { reward: "10-2", image: "icon_rewards_10-2.png" },
      { reward: "4", image: "" },
      { reward: "4", image: "" },
      { reward: "7", image: "icon_rewards_7.png" },
      { reward: "4", image: "" },
      { reward: "4", image: "" },
      { reward: "11", image: "icon_rewards_11.png" },
      { reward: "4", image: "" },
      { reward: "4", image: "" },
      { reward: "8", image: "icon_rewards_8.png" },
      { reward: "4", image: "" },
      { reward: "4", image: "" },
      { reward: "10-1", image: "icon_rewards_10-1.png" },
      { reward: "4", image: "" },
      { reward: "4", image: "" },
    ],
    platinum: [
      { reward: "1-2", image: "icon_rewards_1-2.png" },
      { reward: "4", image: "" },
      { reward: "4", image: "" },
      { reward: "10-1", image: "icon_rewards_10-1.png" },
      { reward: "4", image: "" },
      { reward: "4", image: "" },
      { reward: "5", image: "icon_rewards_5.png" },
      { reward: "4", image: "" },
      { reward: "4", image: "" },
      { reward: "10-2", image: "icon_rewards_10-2.png" },
      { reward: "4", image: "" },
      { reward: "4", image: "" },
      { reward: "7", image: "icon_rewards_7.png" },
      { reward: "4", image: "" },
      { reward: "4", image: "" },
      { reward: "11", image: "icon_rewards_11.png" },
      { reward: "4", image: "" },
      { reward: "4", image: "" },
      { reward: "8", image: "icon_rewards_8.png" },
      { reward: "4", image: "" },
      { reward: "4", image: "" },
      { reward: "10-1", image: "icon_rewards_10-1.png" },
      { reward: "4", image: "" },
      { reward: "4", image: "" },
    ],
    diamond: [
      { reward: "1-3", image: "icon_rewards_1-3.png" },
      { reward: "4", image: "" },
      { reward: "4", image: "" },
      { reward: "10-1", image: "icon_rewards_10-1.png" },
      { reward: "4", image: "" },
      { reward: "4", image: "" },
      { reward: "5", image: "icon_rewards_5.png" },
      { reward: "4", image: "" },
      { reward: "4", image: "" },
      { reward: "10-2", image: "icon_rewards_10-2.png" },
      { reward: "4", image: "" },
      { reward: "4", image: "" },
      { reward: "7", image: "icon_rewards_7.png" },
      { reward: "4", image: "" },
      { reward: "4", image: "" },
      { reward: "11", image: "icon_rewards_11.png" },
      { reward: "4", image: "" },
      { reward: "4", image: "" },
      { reward: "8", image: "icon_rewards_8.png" },
      { reward: "4", image: "" },
      { reward: "4", image: "" },
      { reward: "10-1", image: "icon_rewards_10-1.png" },
      { reward: "4", image: "" },
      { reward: "4", image: "" },
    ],
    crown: [
      { reward: "1-3", image: "icon_rewards_1-3.png" },
      { reward: "4", image: "" },
      { reward: "4", image: "" },
      { reward: "10-1", image: "icon_rewards_10-1.png" },
      { reward: "4", image: "" },
      { reward: "4", image: "" },
      { reward: "5", image: "icon_rewards_5.png" },
      { reward: "4", image: "" },
      { reward: "4", image: "" },
      { reward: "10-2", image: "icon_rewards_10-2.png" },
      { reward: "4", image: "" },
      { reward: "4", image: "" },
      { reward: "7", image: "icon_rewards_7.png" },
      { reward: "4", image: "" },
      { reward: "4", image: "" },
      { reward: "11", image: "icon_rewards_11.png" },
      { reward: "4", image: "" },
      { reward: "4", image: "" },
      { reward: "8", image: "icon_rewards_8.png" },
      { reward: "4", image: "" },
      { reward: "4", image: "" },
      { reward: "10-1", image: "icon_rewards_10-1.png" },
      { reward: "4", image: "" },
      { reward: "4", image: "" },
    ],
  },
  {
    unranked: [
      { reward: "1-1", image: "icon_rewards_1-1.png" },
      { reward: "3", image: "" },
      { reward: "3", image: "" },
      { reward: "10-2", image: "icon_rewards_10-2.png" },
      { reward: "3", image: "" },
      { reward: "3", image: "" },
      { reward: "1-1", image: "icon_rewards_1-1.png" },
      { reward: "3", image: "" },
      { reward: "3", image: "" },
      { reward: "10-3", image: "icon_rewards_10-3.png" },
      { reward: "3", image: "" },
      { reward: "3", image: "" },
      { reward: "1-1", image: "icon_rewards_1-1.png" },
      { reward: "3", image: "" },
      { reward: "3", image: "" },
      { reward: "10-3", image: "icon_rewards_10-3.png" },
      { reward: "3", image: "" },
      { reward: "3", image: "" },
      { reward: "1-1", image: "icon_rewards_1-1.png" },
      { reward: "3", image: "" },
      { reward: "3", image: "" },
      { reward: "10-2", image: "icon_rewards_10-2.png" },
      { reward: "3", image: "" },
      { reward: "3", image: "" },
    ],
    bronze: [
      { reward: "1-1", image: "icon_rewards_1-1.png" },
      { reward: "3", image: "" },
      { reward: "3", image: "" },
      { reward: "10-2", image: "icon_rewards_10-2.png" },
      { reward: "3", image: "" },
      { reward: "3", image: "" },
      { reward: "1-1", image: "icon_rewards_1-1.png" },
      { reward: "3", image: "" },
      { reward: "3", image: "" },
      { reward: "10-3", image: "icon_rewards_10-3.png" },
      { reward: "3", image: "" },
      { reward: "3", image: "" },
      { reward: "1-1", image: "icon_rewards_1-1.png" },
      { reward: "3", image: "" },
      { reward: "3", image: "" },
      { reward: "10-3", image: "icon_rewards_10-3.png" },
      { reward: "3", image: "" },
      { reward: "3", image: "" },
      { reward: "1-1", image: "icon_rewards_1-1.png" },
      { reward: "3", image: "" },
      { reward: "3", image: "" },
      { reward: "10-2", image: "icon_rewards_10-2.png" },
      { reward: "3", image: "" },
      { reward: "3", image: "" },
    ],
    silver: [
      { reward: "1-1", image: "icon_rewards_1-1.png" },
      { reward: "3", image: "" },
      { reward: "3", image: "" },
      { reward: "10-2", image: "icon_rewards_10-2.png" },
      { reward: "3", image: "" },
      { reward: "3", image: "" },
      { reward: "1-1", image: "icon_rewards_1-1.png" },
      { reward: "3", image: "" },
      { reward: "3", image: "" },
      { reward: "10-3", image: "icon_rewards_10-3.png" },
      { reward: "3", image: "" },
      { reward: "3", image: "" },
      { reward: "1-1", image: "icon_rewards_1-1.png" },
      { reward: "3", image: "" },
      { reward: "3", image: "" },
      { reward: "10-3", image: "icon_rewards_10-3.png" },
      { reward: "3", image: "" },
      { reward: "3", image: "" },
      { reward: "1-1", image: "icon_rewards_1-1.png" },
      { reward: "3", image: "" },
      { reward: "3", image: "" },
      { reward: "10-2", image: "icon_rewards_10-2.png" },
      { reward: "3", image: "" },
      { reward: "3", image: "" },
    ],
    gold: [
      { reward: "1-2", image: "icon_rewards_1-2.png" },
      { reward: "3", image: "" },
      { reward: "3", image: "" },
      { reward: "10-2", image: "icon_rewards_10-2.png" },
      { reward: "3", image: "" },
      { reward: "3", image: "" },
      { reward: "1-1", image: "icon_rewards_1-1.png" },
      { reward: "3", image: "" },
      { reward: "3", image: "" },
      { reward: "10-3", image: "icon_rewards_10-3.png" },
      { reward: "3", image: "" },
      { reward: "3", image: "" },
      { reward: "1-2", image: "icon_rewards_1-2.png" },
      { reward: "3", image: "" },
      { reward: "3", image: "" },
      { reward: "10-3", image: "icon_rewards_10-3.png" },
      { reward: "3", image: "" },
      { reward: "3", image: "" },
      { reward: "1-1", image: "icon_rewards_1-1.png" },
      { reward: "3", image: "" },
      { reward: "3", image: "" },
      { reward: "10-2", image: "icon_rewards_10-2.png" },
      { reward: "3", image: "" },
      { reward: "3", image: "" },
    ],
    platinum: [
      { reward: "1-2", image: "icon_rewards_1-2.png" },
      { reward: "3", image: "" },
      { reward: "3", image: "" },
      { reward: "10-2", image: "icon_rewards_10-2.png" },
      { reward: "3", image: "" },
      { reward: "3", image: "" },
      { reward: "1-1", image: "icon_rewards_1-1.png" },
      { reward: "3", image: "" },
      { reward: "3", image: "" },
      { reward: "10-3", image: "icon_rewards_10-3.png" },
      { reward: "3", image: "" },
      { reward: "3", image: "" },
      { reward: "1-2", image: "icon_rewards_1-2.png" },
      { reward: "3", image: "" },
      { reward: "3", image: "" },
      { reward: "10-3", image: "icon_rewards_10-3.png" },
      { reward: "3", image: "" },
      { reward: "3", image: "" },
      { reward: "1-1", image: "icon_rewards_1-1.png" },
      { reward: "3", image: "" },
      { reward: "3", image: "" },
      { reward: "10-2", image: "icon_rewards_10-2.png" },
      { reward: "3", image: "" },
      { reward: "3", image: "" },
    ],
    diamond: [
      { reward: "1-3", image: "icon_rewards_1-3.png" },
      { reward: "3", image: "" },
      { reward: "3", image: "" },
      { reward: "10-2", image: "icon_rewards_10-2.png" },
      { reward: "3", image: "" },
      { reward: "3", image: "" },
      { reward: "1-2", image: "icon_rewards_1-2.png" },
      { reward: "3", image: "" },
      { reward: "3", image: "" },
      { reward: "10-3", image: "icon_rewards_10-3.png" },
      { reward: "3", image: "" },
      { reward: "3", image: "" },
      { reward: "1-2", image: "icon_rewards_1-2.png" },
      { reward: "3", image: "" },
      { reward: "3", image: "" },
      { reward: "10-3", image: "icon_rewards_10-3.png" },
      { reward: "3", image: "" },
      { reward: "3", image: "" },
      { reward: "1-1", image: "icon_rewards_1-1.png" },
      { reward: "3", image: "" },
      { reward: "3", image: "" },
      { reward: "10-2", image: "icon_rewards_10-2.png" },
      { reward: "3", image: "" },
      { reward: "3", image: "" },
    ],
    crown: [
      { reward: "1-3", image: "icon_rewards_1-3.png" },
      { reward: "3", image: "" },
      { reward: "3", image: "" },
      { reward: "10-2", image: "icon_rewards_10-2.png" },
      { reward: "3", image: "" },
      { reward: "3", image: "" },
      { reward: "1-2", image: "icon_rewards_1-2.png" },
      { reward: "3", image: "" },
      { reward: "3", image: "" },
      { reward: "10-3", image: "icon_rewards_10-3.png" },
      { reward: "3", image: "" },
      { reward: "3", image: "" },
      { reward: "1-2", image: "icon_rewards_1-2.png" },
      { reward: "3", image: "" },
      { reward: "3", image: "" },
      { reward: "10-3", image: "icon_rewards_10-3.png" },
      { reward: "3", image: "" },
      { reward: "3", image: "" },
      { reward: "1-2", image: "icon_rewards_1-2.png" },
      { reward: "3", image: "" },
      { reward: "3", image: "" },
      { reward: "10-2", image: "icon_rewards_10-2.png" },
      { reward: "3", image: "" },
      { reward: "3", image: "" },
    ],
  },
];


export const INVITE_CODE_KEY = "pgame_invite_code";