import IMAGE_VIP from "../../assets/icons/icon_VIP0@2x.png";

interface Props {
  width?: number | string;
  height?: number | string;
}

const ImageIconVip: React.FC<Props> = (props: Props) => {
  return (
    <img
      style={{
        width: props.width,
        height: props.height,
        verticalAlign: "middle",
      }}
      src={IMAGE_VIP}
      alt="vip"
    />
  );
};

export default ImageIconVip;
