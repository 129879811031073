import { IGameItem } from '@/types/config';

export { default as Request } from './request';

export const caclItemWidth = (clientWidth: number) => {
  let gapCount = 5;
  let itemsPerPage = 6;
  const windowWidth = window.document.body.clientWidth;
  if (windowWidth <= 640) {
    gapCount = 2;
    itemsPerPage = 3;
  } else if (windowWidth <= 900) {
    gapCount = 3;
    itemsPerPage = 4;
  }
  // setWidth((+ref.current.clientWidth - gapCount * 10) / itemsPerPage);
  return (clientWidth - gapCount * 10) / itemsPerPage;
};

export const caclItemWidth2 = (clientWidth: number) => {
  let gapCount = 5;
  let itemsPerPage = 6;
  const windowWidth = window.document.body.clientWidth;
  if (windowWidth <= 640) {
    gapCount = 2;
    itemsPerPage = 3;
  } else if (windowWidth <= 900) {
    gapCount = 3;
    itemsPerPage = 4;
  }
  // setWidth((+ref.current.clientWidth - gapCount * 10) / itemsPerPage);
  return (clientWidth - gapCount * 20) / itemsPerPage;
};

export const caclSearchItemWidth = (clientWidth: number) => {
  let gapCount = 5;
  let itemsPerPage = 6;
  const windowWidth = window.document.body.clientWidth;
  if (windowWidth <= 900) {
    gapCount = 2;
    itemsPerPage = 3;
  } else if (windowWidth <= 1200) {
    gapCount = 3;
    itemsPerPage = 4;
  }
  // setWidth((+ref.current.clientWidth - gapCount * 10) / itemsPerPage);
  return (clientWidth - gapCount * 10) / itemsPerPage;
};

export function compareAZ(a: IGameItem, b: IGameItem): number {
  return a.game_name.localeCompare(b.game_name);
}

export function compareZA(a: IGameItem, b: IGameItem): number {
  return b.game_name.localeCompare(a.game_name);
}

export function differenceInSeconds(start: Date, end: Date): number {
  // console.log(start.getTime(), end.getTime());
  const durationInMillis = end.getTime() - start.getTime();
  return Math.floor(durationInMillis / 1000);
}

export function calculateTime(n: number): {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
} {
  const secondsPerMinute = 60;
  const secondsPerHour = 60 * secondsPerMinute;
  const secondsPerDay = 24 * secondsPerHour;

  const days = Math.floor(n / secondsPerDay);
  n %= secondsPerDay;

  const hours = Math.floor(n / secondsPerHour);
  n %= secondsPerHour;

  const minutes = Math.floor(n / secondsPerMinute);
  n %= secondsPerMinute;

  const seconds = n;

  return { days, hours, minutes, seconds };
}

export function fixZore(n: number): string {
  if (n < 10) {
    return `0${n}`;
  } else {
    return `${n}`;
  }
}
