import IC_QUESTION from '@/assets/icons/icon_question.png';
import { IAgentSummary } from '@/hooks/useAgentSummary';
import useLang from '@/hooks/useLang';
import { isOpenMemberTipState } from '@/stores/auth';
import { Box, Divider, Stack, Typography } from '@mui/material';
import { useRecoilState } from 'recoil';
import MemberTipDrawer from './MemberTipDrawer';

interface AgentSummaryProps {
  data?: IAgentSummary;
  type: 1 | 2;
}

export const AgentSummary = (props: AgentSummaryProps) => {
  const { __ } = useLang();
  const [openBoxTip, setOpenBoxTip] = useRecoilState(isOpenMemberTipState);
  if (props.type === 2) {
    return (
      <Box
        sx={{
          bgcolor: '#212933',
          borderRadius: 1,
          p: 2,
          mb: 2,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ flex: 1, minWidth: 0 }}>
            <Typography variant="body1" color="text.secondary">
              {`${__('Números de novos membros:')} `}
              <span style={{ color: 'white' }}>
                {props.data?.registerCount ?? 0}
              </span>
            </Typography>
          </Box>
          <Divider sx={{ mx: 2 }} orientation="vertical" flexItem />
          <Box sx={{ flex: 1, minWidth: 0 }}>
            <Typography variant="body1" color="text.secondary">
              {`${__('Convites diretos válidos:')} `}
              <span style={{ color: 'white' }}>
                {props.data?.activateCount ?? 0}
              </span>
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  }
  return (
    <Box
      sx={{
        bgcolor: '#212933',
        borderRadius: 1,
        p: 2,
        mb: 2,
      }}
    >
      <Box
        sx={{
          bgcolor: '#212933',
          borderRadius: 1,

          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Stack
          sx={{ flex: 1, minWidth: 0 }}
          direction="row"
          alignItems="center"
        >
          {/* <img width={16} height={16} src={IconInvite} alt="" /> */}
          <Typography ml={0.5} variant="body1" color="text.secondary">
            {`${__('Histórico de convites :')} `}
            <span style={{ color: 'white' }}>
              {props.data?.totalCount ?? 0}
            </span>
          </Typography>
        </Stack>
        <Divider sx={{ mx: 2 }} orientation="vertical" flexItem />
        <Stack
          sx={{ flex: 1, minWidth: 0 }}
          direction="row"
          alignItems="center"
        >
          {/* <img width={16} height={16} src={IconDeposit} alt="" /> */}
          <img
            src={IC_QUESTION}
            alt=""
            width={14}
            height={14}
            onClick={() => {
              setOpenBoxTip(true);
            }}
          />
          <Typography ml={0.5} variant="body1" color="text.secondary">
            {`${__('Histórico de convites válidos:')} `}
            <span style={{ color: 'white' }}>
              {props.data?.totalActivateCount ?? 0}
            </span>
          </Typography>
        </Stack>
      </Box>

        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                mt: 2,
            }}
        >
            <Box sx={{ flex: 1, minWidth: 0 }}>
                <Typography variant="body1" color="text.secondary">
                    {`${__('Histórico Efetivo da Primeira Carga')} `}
                    <span style={{ color: 'white' }}>
              {props.data?.totalRechargedCount ?? 0}
            </span>
                </Typography>
            </Box>
            <Divider sx={{ mx: 2 }} orientation="vertical" flexItem />
            <Box sx={{ flex: 1, minWidth: 0 }}>
                <Typography variant="body1" color="text.secondary">
                    {/*{`${__('Apostas da equipe:')} `}*/}
                    {/*<span style={{ color: 'white' }}>{props.data?.orderAmt ?? 0}</span>*/}
                </Typography>
            </Box>
        </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          mt: 2,
        }}
      >
        <Box sx={{ flex: 1, minWidth: 0 }}>
          <Typography variant="body1" color="text.secondary">
            {`${__('Valor de recarga da equipe:')} `}
            <span style={{ color: 'white' }}>
              {props.data?.rechargeAmt ?? 0}
            </span>
          </Typography>
        </Box>
        <Divider sx={{ mx: 2 }} orientation="vertical" flexItem />
        <Box sx={{ flex: 1, minWidth: 0 }}>
          <Typography variant="body1" color="text.secondary">
            {`${__('Apostas da equipe:')} `}
            <span style={{ color: 'white' }}>{props.data?.orderAmt ?? 0}</span>
          </Typography>
        </Box>
      </Box>
      <MemberTipDrawer />
    </Box>
  );
};
