import { Request } from '@/utils';
import { testHost } from './host';

export async function deposit(params: { money: string; pay_id: string }) {
  return Request.post(`${testHost}/api_pay.html`, params);
}

// http://test.brabab.com/api_cpf_list.html

export async function getCPFList(params: { page: number; pagesize: number }) {
  try {
    const { data } = await Request.post(
      `${testHost}/api_cpf_list.html`,
      params,
    );
    return data?.data?.list || [];
  } catch (error) {
    return [];
  }
}

// 绑定cpf
export async function setCPF(params: any) {
  try {
    await Request.post(`${testHost}/api_bind_cpf.html`, params);
    return true;
  } catch (error) {
    return false;
  }
}

export async function withdraw(params: {
  requestType: number, 
  type: any, 
  phone: string, 
  code: string, 
  cpfCode:string,
  cpfId?: string, 
  walletType: number, 
  amt: number 
}) {
  try {
    await Request.post(`${testHost}/api_out_money.html`, params);
    return true;
  } catch (error) {
    return false;
  }
}



export async function getMoneyLog(params: {
  page: number;
  pagesize: number;
  totypeid?: number;
}) {
  try {
    const { data } = await Request.post(
      `${testHost}/api_money_log.html`,
      params,
    );
    return data;
  } catch (error) {
    return null;
  }
}

export async function getBetGameList(params: {
  page: number;
  pagesize: number;
  type_id?: number;
  platform_id?: number;
}) {
  try {
    const { data } = await Request.post(
      `${testHost}/api_bet_game_list.html`,
      params,
    );
    return data;
  } catch (error) {
    return null;
  }
}

export async function getBetGameTotalList() {
  try {
    const { data } = await Request.post(
      `${testHost}/api_bet_game_total_list.html`,
    );
    return data;
  } catch (error) {
    return null;
  }
}


/**
 * 代理钱包转账
 *
 * @export
 * @param {{
 *   amt: number;
 *
 * }} params
 * @return {*} 
 */
export async function transferAgent(params: {
  amt: number;
}) {
  try {
    const { data } = await Request.post(
      `${testHost}/api_transfer_from_agent_money.html`,
      params,
    );
    return data;
  } catch (error) {
    return null;
  }
}
