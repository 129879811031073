import { INVITE_CODE_KEY } from "@/constants";
import { useEffect } from "react";

export const useHomeInvite = () => {
  const search = new URLSearchParams(window.location.search);
  const code = search.get('code');
  useEffect(() => {
    if (code) {
      sessionStorage.setItem(INVITE_CODE_KEY, code);
    }
  }, [code]);
}