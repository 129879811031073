import BG from '@/assets/images/gift-bg.png';
import Center from '@/components/center';
import useAuth from '@/hooks/useAuth';
import useBoolean from '@/hooks/useBoolean';
import services from '@/services';
import { IGiftData } from '@/types/config';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useState } from 'react';
import GiftDialog from './GiftDialog';

export const GiftPage = () => {
  const [giftData, setGiftData] = useState<IGiftData[]>([]);
  const [open, setOpen] = useBoolean();
  const [isLoading, setIsLoading] = useBoolean();
  const { isLogin, openLogin, getWallet } = useAuth();
  const search = new URLSearchParams(window.location.search);
  const code = search.get('code');

  const handleReceive = async () => {
    if (!code) return;

    if (!isLogin) {
      openLogin();
      return;
    }

    if (isLoading) return;

    setIsLoading.on();
    const data = await services.lucky.receiveGift(code);
    setIsLoading.off();

    setGiftData(data?.data ?? []);
    if (data?.data?.length) {
      setOpen.on();
    }

    // 刷新钱包
    if (data?.handle === 'refresh_money') {
      getWallet();
    }
  };

  return (
    <Box
      sx={{
        maxWidth: '414px',
        mx: 'auto',
        height: '100vh',
        backgroundImage: `url(${BG})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '80%',
          width: '100%',
          left: 0,
          right: 0,
          px: 4,
        }}
      >
        <Box
          sx={{
            background:
              'linear-gradient(90deg, #FFC46C 0%, #EF7640 6.94%, #F58446 10.28%, #FFDBA6 50.36%, #FFD4A0 50.37%, #F68C4C 91.11%, #F1864A 94.22%, #FFC46C 100%)',
            boxShadow: '0px 4px 8px 0px #B73DBC',
            height: '60px',
            borderRadius: 999,
            textAlign: 'center',
            maxWidth: '350px',
            mx: 'auto',
            cursor: 'pointer',
          }}
        >
          <Box
            sx={{
              background: 'linear-gradient(90deg, #FF4641 0%, #D500DF 100%)',
              boxShadow: '2px 2px 4px 0px rgba(255, 255, 255, 0.80) inset',
              height: '56px',
              pt: 1.5,
              borderRadius: 999,
              textAlign: 'center',
              cursor: 'pointer',
            }}
            onClick={handleReceive}
          >
            {isLoading ? (
              <Center height={36}>
                <CircularProgress size={28} color="info" />
              </Center>
            ) : (
              <Typography variant="h4">Receive gifts</Typography>
            )}
          </Box>
        </Box>
      </Box>

      {/* 中奖弹窗 */}
      <GiftDialog
        open={open}
        onClose={setOpen.off}
        list={giftData}
      ></GiftDialog>
    </Box>
  );
};
