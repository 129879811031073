import ICON_GAMES from '@/assets/icons/icon_games.png';
import BG from '@/assets/images/common-game-bg.png';
import useBoolean from '@/hooks/useBoolean';
import useLang from '@/hooks/useLang';
import useResponsive from '@/hooks/useResponsive';
import { IGameCategoryImg, IGameItem } from '@/types/config';

import { hideScrollbarY } from '@/utils/cssStyles';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/pagination';
import { IGame } from '../../types';
import Center from '../center';
import EmptyData from '../EmptyData';
import GameFont from '../GameFont';
import ImageWithLoading from '../image';
import HomeSectionContainer from './SectionContainer';

interface Props {
  title: string;
  desc?: string;
  icon?: IGameCategoryImg;
  bgImg?: IGameCategoryImg;
  list: IGameItem[];
  onClickMore?: () => void;
}
export enum GameListType {
  Popular = 'Popular',
  New = 'New',
  LiveCasino = 'Live Casino',
  BonusBuy = 'Bonus Buy',
  Recommend = 'Recommend Game',
}

export const HomeGameListBreakPoints = {
  100: {
    slidesPerView: 3.5,
    spaceBetween: 10,
  },
  600: {
    slidesPerView: 4,
    spaceBetween: 10,
  },
  900: {
    slidesPerView: 6,
    spaceBetween: 10,
  },
};

export const HomeGameCard = (props: {
  data: IGame;
  height?: number;
  width?: number;
  onClick?: () => void;
}) => {
  // const navigate = useNavigate();
  return (
    <Box>
      <Box
        onClick={props.onClick}
        sx={{
          mt: '12px',
          transition: 'all 0.3s ease',
          cursor: 'pointer',
          position: 'relative',
          overflow: 'hidden',
          borderRadius: '8px',
          aspectRatio: '53/70',
          // '&:hover': {
          //   mt: {
          //     xs: '12px',
          //     sm: '0px',
          //   },
          // },
        }}
      >
        <ImageWithLoading
          // width={props.width || 188}
          // height={props.height || 248}
          aspectRatio="53/70"
          src={props.data.image_url}
          alt={props.data.name}
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            width: props.width || 188,
            height: props.height || 248,
            position: 'absolute',
            background: 'linear-gradient(360deg, #B304FE 0%, #0CC0F7 100%)',
            opacity: 0,
            '&:hover': {
              opacity: 1,
            },
          }}
        >
          <Stack
            spacing={props.height ? `${(props.height * 12) / 100}px` : 4}
            height="100%"
            alignItems="center"
            justifyContent="center"
          >
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: 600,
                px: 2,
                width: props.width || 188,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                textAlign: 'center',
                whiteSpace: 'nowrap',
              }}
            >
              {props.data.name}
            </Typography>
            <Stack
              alignItems="center"
              spacing={props.height ? `${(props.height * 6) / 100}px` : 2}
            >
              <PlayArrowRoundedIcon sx={{ width: '38%', height: '38%' }} />
              <Typography
                variant="body1"
                sx={{
                  px: 2,
                  width: props.width || 188,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  textAlign: 'center',
                  whiteSpace: 'nowrap',
                }}
              >
                {props.data.provider?.name}
              </Typography>
            </Stack>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

export const HomeGameCard2 = (props: {
  data: IGameItem;
  // height?: number;
  // width?: number;
  onClick?: () => void;
}) => {
  const navigate = useNavigate();
  // const { getPlatformTitle } = usePlatform();
  const handleClick = () => {
    props.onClick?.();
    window.scrollTo({
      top: 0,
      left: 0,
    });
    navigate('/game/info?id=' + props.data.id);
  };

  return (
    <Box>
      <Box
        onClick={handleClick}
        sx={{
          mt: '12px',
          transition: 'all 0.3s ease',
          cursor: 'pointer',
          position: 'relative',
          overflow: 'hidden',
          borderRadius: '8px',
          '&:hover': {
            mt: {
              xs: '12px',
              sm: '0px',
            },
          },
        }}
      >
        <ImageWithLoading
          // width={props.width || 188}
          // height={props.height || 248}
          aspectRatio="53/70"
          // src={props.data.pic}
          src={BG}
          alt={props.data.game_name}
        />
        <Box
          sx={{
            top: '56%',
            width: '100%',
            overflow: 'hidden',
            left: 0,
            right: 0,
            aspectRatio: '53/70',
            position: 'absolute',
          }}
        >
          <GameFont name={props.data.game_name}></GameFont>
        </Box>
        {/* <Box
          sx={{
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            aspectRatio: '53/70',
            position: 'absolute',
            background: 'linear-gradient(360deg, #B304FE 0%, #0CC0F7 100%)',
            opacity: 0,
            '&:hover': {
              opacity: 1,
            },
          }}
        >
          <Stack height="100%" alignItems="center" justifyContent="center">
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: 600,
                px: 2,
                width: '100%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                textAlign: 'center',
                whiteSpace: 'nowrap',
              }}
            >
              {props.data.game_name}
            </Typography>
            <PlayArrowRoundedIcon sx={{ width: '38%', height: '38%' }} />
            <Typography
              variant="body1"
              sx={{
                px: 2,
                width: '100%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                textAlign: 'center',
                whiteSpace: 'nowrap',
              }}
            >
              {getPlatformTitle(props.data.platform_id)}
            </Typography>
          </Stack>
        </Box> */}
      </Box>
    </Box>
  );
};

export const HomeGameList2 = (props: Props) => {
  const isMobile = useResponsive('down', 'md');
  if (isMobile) {
    return <HomeGameList2Mobile {...props} />;
  }
  return <HomeGameList2Web {...props} />;
};

export const HomeGameList2Web = (props: Props) => {
  const [isLoadMore, setIsLoadMore] = useBoolean();
  const { __ } = useLang();

  const showList = useMemo(() => {
    if (isLoadMore) {
      return props.list.slice(0, 12);
    } else {
      return props.list.slice(0, 6);
    }
  }, [props.list, isLoadMore]);

  return (
    <HomeSectionContainer
      title={props.title}
      desc={props.desc}
      icon={props?.icon?.square}
      onClickMore={props.onClickMore}
    >
      <Box sx={{ mb: '16px' }}>
        <Grid container gridRow={1} columns={24} spacing={{ xs: 1.5, sm: 2 }}>
          {showList.map((item, index) => {
            return (
              <Grid item key={item.id} md={4}>
                <GameCard item={item} />
              </Grid>
            );
          })}
        </Grid>

        {props.list.length > 8 && !isLoadMore && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              my: 2,
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={setIsLoadMore.on}
              size="small"
              sx={{
                px: 1.5,
                py: 1,
                fontSize: 14,
                height: 32,
                borderRadius: 999,
                bgcolor: 'card.main',
                fontWeight: 500,
              }}
            >
              {__('Load More')}
            </Button>
          </Box>
        )}

        {props.list.length === 0 && (
          <Center py={2}>
            <EmptyData />
          </Center>
        )}
      </Box>
    </HomeSectionContainer>
  );
};

export const HomeGameList2Mobile = (props: Props) => {
  const [isLoadMore, setIsLoadMore] = useBoolean();
  const { __ } = useLang();
  const navigate = useNavigate();

  const showList = useMemo(() => {
    if (isLoadMore) {
      return props.list.slice(0, 12);
    } else {
      return props.list.slice(0, 6);
    }
  }, [props.list, isLoadMore]);

  return (
    <Box>
      <HomeSectionContainer
        title={props.title}
        desc={props.desc}
        icon={props?.icon?.square}
        onClickMore={props.onClickMore}
      >
        <Box>
          <Grid container columns={24} spacing={{ xs: 1.5, sm: 2 }}>
            {showList.map((item, index) => {
              return (
                <Grid item key={item.id} xs={8}>
                  <GameCard item={item} />
                </Grid>
              );
            })}
          </Grid>

          {props.list.length > 6 && !isLoadMore && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                my: 2,
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={setIsLoadMore.on}
                size="small"
                sx={{
                  px: 1.5,
                  py: 1,
                  fontSize: 14,
                  height: 32,
                  borderRadius: '8px',
                  bgcolor: 'card.main',
                  fontWeight: 500,
                  color: 'text.secondary',
                }}
              >
                {__('Load More')}
              </Button>
            </Box>
          )}

          {props.list.length === 0 && (
            <Center py={2}>
              <EmptyData />
            </Center>
          )}
        </Box>
      </HomeSectionContainer>
    </Box>
  );
};

// 游戏详情页，推荐游戏列表
export const GameInfoGameListMobile = (props: {
  title: string;
  desc?: string;
  list: IGameItem[];
  onClickMore?: () => void;
}) => {
  const [isLoadMore, setIsLoadMore] = useBoolean();
  const { __ } = useLang();
  const navigate = useNavigate();

  const showList = useMemo(() => {
    if (isLoadMore) {
      return props.list.slice(0, 12);
    } else {
      return props.list.slice(0, 6);
    }
  }, [props.list, isLoadMore]);

  return (
    <Box mb={2}>
      <Stack
        mb={1}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack flexDirection={'row'} alignItems="center">
          <img
            style={{ width: '16px', height: '16px', marginRight: '4px' }}
            src={ICON_GAMES}
            alt=""
          />
          <Typography variant="subtitle2" fontWeight={700}>
            {__('Game Recommendations')}
          </Typography>
        </Stack>

        <Button
          sx={{
            height: 25,
            color: '#8B899C',
            borderColor: '#35314B !important',
            borderRadius: '12px',
            fontSize: 12,
            display: 'none',
          }}
          variant="outlined"
          size="small"
          onClick={props.onClickMore}
        >
          {__('View All')}
        </Button>
      </Stack>
      <Box
        sx={{
          overflowX: 'scroll',
          width: '100%',
          maxWidth: '100%',
          ...hideScrollbarY,
        }}
      >
        <Stack direction="row" spacing="10px" sx={{ overflowX: 'scroll' }}>
          {showList.map((item, index) => {
            return (
              <Box
                key={index}
                sx={{ cursor: 'pointer', position: 'relative' }}
                onClick={() => {
                  navigate('/game/info?id=' + item.id);
                }}
              >
                <ImageWithLoading
                  width={106}
                  height={140}
                  aspectRatio={'2/1'}
                  alt={item.game_name}
                  src={item.pic}
                />
                <Center
                  sx={{
                    bottom: '3px',
                    width: '100%',
                    overflow: 'hidden',
                    minHeight: '25%',
                    left: 0,
                    right: 0,
                    position: 'absolute',
                  }}
                >
                  <GameFont name={item.game_name}></GameFont>
                </Center>
              </Box>

              // <GameCard item={item} />
            );
          })}
        </Stack>
        {props.list.length === 0 && (
          <Center py={2}>
            <EmptyData />
          </Center>
        )}
      </Box>
    </Box>
  );
};

// 游戏卡片
export const GameCard = ({
  item,
  width,
  height,
}: {
  item: IGameItem;
  width?: any;
  height?: any;
}) => {
  const navigate = useNavigate();
  return (
    <Box
      borderRadius={1}
      overflow="hidden"
      onClick={() => {
        navigate('/game/info?id=' + item.id);
      }}
      sx={[
        {
          // mt: '8px',
          transition: 'all 0.3s ease',
          cursor: 'pointer',
          position: 'relative',
          overflow: 'hidden',
          borderRadius: '8px',
          '&:hover': {
            mt: {
              xs: '0px',
              sm: '-8px',
            },
          },
        },
        width && { width },
        height && { height },
      ]}
    >
      <ImageWithLoading
        width={width}
        height={height}
        aspectRatio={'53/70'}
        alt={item.game_name}
        src={item.pic}
        style={{ width: '100%', height: 'auto' }}
      />
      <Center
        sx={{
          bottom: '3px',
          width: '100%',
          overflow: 'hidden',
          minHeight: '25%',
          left: 0,
          right: 0,
          position: 'absolute',
        }}
      >
        <GameFont name={item.game_name}></GameFont>
      </Center>
    </Box>
  );
};
