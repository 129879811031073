import { Box, Stack, Typography } from '@mui/material';

interface Props {
  percent: number;
  label?: string;
  size?: 'small' | 'medium' | 'large';
  hideText?: boolean;
}

const ProcessBar: React.FC<Props> = (props: Props) => {
  const height =
    props.size === 'small' ? '6px' : props.size === 'medium' ? '8px' : '14px';

  const radius =
    props.size === 'small' ? '3px' : props.size === 'medium' ? '4px' : '7px';
  return (
    <Stack direction="row" alignItems="center" spacing="20px">
      <Box
        sx={{
          width: '100%',
          height: height,
          background: '#2F294C',
          borderRadius: radius,
        }}
      >
        <Box
          sx={{
            width: `${props.percent}%`,
            background: 'linear-gradient(90deg, #B304FE 0%, #0CC0F7 100%)',
            height: height,
            borderRadius: radius,
          }}
        ></Box>
      </Box>
      {props.hideText ? null : (
        <Typography variant="subtitle1">
          {props.label ? props.label : `${props.percent}%`}
        </Typography>
      )}
    </Stack>
  );
};

export default ProcessBar;
