export enum LanguageType {
  EN = 'en',
  PT = 'pt',
}

export enum HomeTab {
  Spinner = 'Spinner',
  Invite = 'Invite',
  Home = 'Home',
  Vip = 'Vip',
  Me = 'Me',
}

export enum AccountTabType {
  General = 'General',
  Security = 'Security',
  Address = 'Address',
  Suspension = 'Suspension',
}

export enum TransationsTabType {
  Transactions = 'Transactions',
  Deposists = 'Deposists',
  Withdraws = 'Withdraws',
}

export enum SpinnerTabType {
  Daily = 'VIP Daily Spin',
  Progress = ' Vip Progress Spin',
}

export enum SigninType {
  Phone = 'Phone',
  Email = 'Email',
}

/**
 * 用户信息
 */
export interface IUserInfo {
  name:string;
  vipState: number;
  agent: number;
  agent_url: string;
  agent_money: number;
  avatar: string;
  birthday_day: string;
  email_bind: number;
  email: string;
  exp: number;
  level: number;
  level_end_date: number;
  level_name: string;
  level_pic: string;
  level_progress: number;
  login_ip: string;
  login_time: number;
  lottery_num: number;
  max_exp: number;
  money: number;
  msg: number;
  nick_name: string;
  no_dama_money: number;
  pay_password: number;
  phone: string;
  phone_bind: number;
  qiandao: number;
  qiandao_num: number;
  sex: number;
  token: string;
  uid: number;
  username: string;
  rentou_money: number;
  total_agent_money: number;
  total_rentou_money: number;
  protect_level_dama: number;
  protect_level_money: number;
  protect_level_money_num: number;
  oauth1_banding_status: number;
  oauth2_banding_status: number;
  cpfCode: string;
  cpfId: string;
  cpfState: number;
  vipExpireTime: number;
  agentExpireTime: number;
  cpfType:number;
}

export interface IBanner {
  title: string;
  description: string;
  image_url: string;
  href: string;
  action: string;
  action_text: string;
  mobile_image_url: string;
  os_type: number;
  in_dialog: number;
}

export interface IGameSearchItem {
  demo_disabled: boolean;
  game_provider_id: number;
  id: number;
  image_url: string;
  name: string;
  slug: string;
}

export interface IGame {
  id: number;
  name: string;
  image_url: string;
  slug: string;
  game_provider_id: number;
  demo_disabled: boolean;
  provider: {
    slug: string;
    name: string;
  };
}

export interface IGameProvider {
  game_count: number;
  id: number;
  image_url: string;
  name: string;
  slug: string;
}

export const GAME_PROVIDERS = [
  {
    game_count: 0,
    id: 4,
    image_url: 'https://brbrbet.com/images/game-providers/amatic.png',
    name: 'Pragmatic Play',
    slug: 'pragmatic-play',
  },
  {
    game_count: 0,
    id: 5,
    image_url: 'https://brbrbet.com/images/game-providers/belatra.png',
    name: 'Pocket Games Soft',
    slug: 'pocket-games-soft',
  },
];

export const MOCK_BANNERS = {
  banners: [
    {
      title: '充值赠送2',
      description: '每天5次充值大于等于100随机赠送2%~500%',
      image_url:
        'https://cdn.brcrown.com/pragmatic-play/c1e46a8d1da94a714084deec787dced3.jpg',
      href: '/modal?type=payment',
      action: '',
      action_text: '',
      mobile_image_url:
        'https://cdn.brcrown.com/pragmatic-play/54b397d2470e884a921c11af98b16f3d.jpg',
      os_type: 0,
      in_dialog: 2,
    },
    {
      title: '代理等级',
      description: '代理等级',
      image_url:
        'https://cdn.brcrown.com/pragmatic-play/8468c32d7c6e4ec424fc9945f03e003a.jpg',
      href: '/referred/code',
      action: '',
      action_text: '',
      mobile_image_url:
        'https://cdn.brcrown.com/pragmatic-play/f9e71992958d240be8fb93484b41e26c.jpg',
      os_type: 0,
      in_dialog: 2,
    },
    {
      title: '抽奖',
      description: '抽奖',
      image_url:
        'https://cdn.brcrown.com/pragmatic-play/f1d2441b66677d0a324a6f99432446b5.jpg',
      href: '/spinners',
      action: '',
      action_text: '',
      mobile_image_url:
        'https://cdn.brcrown.com/pragmatic-play/b4ebf761c88318c4c1604de69f54a9e9.jpg',
      os_type: 0,
      in_dialog: 2,
    },
    {
      title: 'VIP',
      description: 'VIP',
      image_url:
        'https://cdn.brcrown.com/pragmatic-play/e836e3e8ce81eea79de5765b45a3c8e3.jpg',
      href: '/vip',
      action: '',
      action_text: '',
      mobile_image_url:
        'https://cdn.brcrown.com/pragmatic-play/530038d1b471cca243364d7bc7919ff9.jpg',
      os_type: 0,
      in_dialog: 2,
    },
  ],
};
