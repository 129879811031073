import { Box, Stack, Typography } from "@mui/material";
import IMAGE_COUPON from "../../assets/images/coupon.png";
import { IBoxItem } from "../../types/box";

interface Props {
  data: IBoxItem;
}

const CouponCard: React.FC<Props> = (props: Props) => {
  return (
    <Box
      sx={{
        overflow: "hidden",
        bgcolor: "#1D1839",
        borderRadius: "10px",
        p: 2,
      }}
    >
      <Stack direction="row" spacing="20px" alignItems="center">
        <Box
          sx={{
            bgcolor: "#322A59",
            width: "82px",
            height: "82px",
            minWidth: "82px",
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={props.data.image_url || IMAGE_COUPON}
            alt="coupon"
            style={{ width: "50px" }}
          />
        </Box>
        <Box overflow="hidden">
          <Typography
            variant="h4"
            sx={{
              fontWeight: 600,
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              width: "100%",
            }}
          >
            {props.data.name}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: "#999999",
              whiteSpace: "pre-wrap",
              display: "-webkit-box",
              boxOrient: "vertical",
              lineClamp: 2,
              overflow: "hidden",
            }}
          >
            {props.data.description}
          </Typography>
        </Box>
      </Stack>
    </Box>
  );
};

export default CouponCard;
