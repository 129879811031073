import useBoolean from '@/hooks/useBoolean';
import useLang from '@/hooks/useLang';
import { LoadingButton } from '@mui/lab';
import { Box, InputAdornment, OutlinedInput, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { useEffect } from 'react';
import { useCountdown } from 'usehooks-ts';
interface Props {
  code: string;
  placeholder?: string;
  sx?: SxProps<Theme>;
  disabled?: boolean;
  onChange: (value: string) => void;
  onSendSMS: () => Promise<boolean>;
}

const CodeInput: React.FC<Props> = (props: Props) => {
  const { __ } = useLang();
  const [loading, setLoading] = useBoolean();
  const [showCountdown, setShowCountdown] = useBoolean();
  const [count, { startCountdown, resetCountdown }] = useCountdown({
    countStart: 60,
    intervalMs: 1000,
  });
  useEffect(() => {
    if (count === 0) {
      setShowCountdown.off();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count]);

  const handleClick = async () => {
    if (loading) {
      return;
    }
    setLoading.on();

    const isSuccess = await props.onSendSMS();
    console.log(isSuccess);
    if (isSuccess) {
      start();
    }
    setLoading.off();
  };

  const start = () => {
    if (count <= 0) {
      resetCountdown();
    }

    startCountdown();
    setShowCountdown.on();
  };

  return (
    <OutlinedInput
      value={props.code}
      sx={{ ...props.sx }}
      onChange={(e) => props.onChange(e.target.value)}
      endAdornment={
        <InputAdornment position="end">
          {showCountdown ? (
            <Box sx={{ px: 1, textAlign: 'center' }}>
              <Typography variant="body2" color="primary.main">
                {count} s
              </Typography>
            </Box>
          ) : (
            <LoadingButton
              disabled={props?.disabled === true ? true : false}
              onClick={handleClick}
              variant="text"
              color="primary"
              loading={loading}
              sx={{ width: '90px', height: '36px' }}
            >
              {__('Send Code')}
            </LoadingButton>
          )}
        </InputAdornment>
      }
      type="number"
      placeholder={props?.placeholder || __('Please enter verify code')}
    />
  );
};

export default CodeInput;
