import icon_notice from '@/assets/icons/icon_notice.svg';
import tg_blue from '@/assets/icons/tg_blue.png';
import useLang from '@/hooks/useLang';
import { isShowHomeGuide } from '@/stores/config';
import { Box, LinearProgress, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';

interface Props {}

const HomeHeaderGuide: React.FC<Props> = (props: Props) => {
  const { __ } = useLang();
  const [progress, setProgress] = useState(30);
  const [isShowDLGuide, setIsShowDLGuide] = useRecoilState(isShowHomeGuide);
  useEffect(() => {
    if (progress < 1) {
      return;
    }

    const interval = setInterval(() => {
      setProgress(progress - 1);
      if (progress == 1) {
        setIsShowDLGuide(true);
      }
    }, 1000);
    return () => {
      interval && clearInterval(interval);
    };
  }, [progress]);

  const handleDl = async () => {
    setIsShowDLGuide(true);
    window.open('https://t.me/BrBabyOficial', '_blank');
  };

  return (
    <Box
      sx={{
        bgcolor: 'background.default', //
        cursor: 'pointer',
        position: 'absolute',
        top: '48px',
        left: 0,
        width: '100vw',
        zIndex: 100,
      }}
    >
      <Stack direction="row" alignItems="center" sx={{ py: '10px' }}>
        <img
          src={icon_notice}
          alt=""
          style={{
            width: '16px',
            height: '16px',
            marginRight: '10px',
            marginLeft: '16px',
          }}
        />
        <Typography
          variant="body1"
          mx={0.5}
          fontWeight={'500'}
          sx={{ display: 'flex', flex: 1 }}
        >
          {__('Join the telegram group, there will be surprises every day')}
        </Typography>
        <img
          onClick={handleDl}
          src={tg_blue}
          alt=""
          style={{ width: '32px', height: '32px', marginRight: '16px' }}
        />
      </Stack>
      <LinearProgress
        variant="determinate"
        value={((30 - progress) / 30) * 100}
        color="warning"
      />
    </Box>
  );
};

export default HomeHeaderGuide;
