// material-ui
import { Box } from '@mui/material';
// project imports

import LogoSection from './LogoSection';
import ProfileSection from './ProfileSection';

const Header = () => {
  return (
    <>
      {/* logo & toggler button */}
      <LogoSection />

      {/* header search */}
      <Box sx={{ flexGrow: 1 }} />

      <Box sx={{ flexGrow: 1 }} />
      {/* notification & profile */}
      <ProfileSection />
    </>
  );
};

export default Header;
