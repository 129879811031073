import CodeInput from '@/components/Auth/login/CodeInput';
import useAuth from '@/hooks/useAuth';
import useLang from '@/hooks/useLang';
import useResponsive from '@/hooks/useResponsive';
import services from '@/services';
import {
  isOpenWithdrawerState,
  isOpenWithdrawerValidState,
  userInfoState,
  walletState,
  withdrawInfoState,
} from '@/stores/auth';
import { ColorfulBg } from '@/styles';
import { LoadingButton } from '@mui/lab';
import { Box, OutlinedInput, Stack, Typography } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { SnackbarUtils } from '../snackbar/SnackbarProvider';

interface Props {}

/**
 * 体现安全验证内容
 *
 * @param {Props} props
 * @return {*}
 */
const WithdrawValidConten = (props: Props) => {
  const { __ } = useLang();
  const theme = useTheme();

  const [openWithdrawValid, setOpenWithdrawValid] = useRecoilState(
    isOpenWithdrawerValidState,
  );
  const setOpenWithdraw = useSetRecoilState(isOpenWithdrawerState);

  const info = useRecoilValue(withdrawInfoState);

  const isMobile = useResponsive('down', 'md');
  const [amount, setAmount] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const wallet = useRecoilValue(walletState);
  const user = useRecoilValue(userInfoState);
  const [code, setCode] = useState<string>('');
  const { getWallet } = useAuth();
  const [pwd, setPwd] = useState<string>('');
  const [cardNo, setCardNo] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [canSave, setCanSave] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');

  const totalAmount =
    info.outType === 2 ? +(wallet?.agent_money || 0) : +(wallet?.money || 0);

  useEffect(() => {
    if (openWithdrawValid) {
      getWallet();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openWithdrawValid]);

  const handleClickAll = () => {
    setAmount(totalAmount.toString());
  };

  const handleSendCode = async () => {
    if (!phone) {
      return false;
    }
    return await services.auth.sendSMS({
      phone,
      type: 6,
    });
  };

  const handleSubmit = async () => {
    if (loading) {
      return;
    }

    setLoading(true);

    const params = {
      type_id: 1,
      card_no: cardNo,
      first_name: firstName,
      last_name: lastName,
      mobile: phone,
      code: code,
    };

    const isSuccess = await services.wallet.setCPF(params);

    if (isSuccess) {
      setOpenWithdrawValid(false);
      SnackbarUtils.success(__('Successfully'));
    }

    setLoading(false);
  };

  const _onInput = (type: string, txt: string) => {
    let params = {
      firstName,
      lastName,
      cardNo,
      phone,
      code,
    };
    switch (type) {
      case 'first':
        setFirstName(txt);
        params.firstName = txt;
        break;
      case 'last':
        setLastName(txt);
        params.lastName = txt;
        break;
      case 'card':
        setCardNo(txt);
        params.cardNo = txt;
        break;
      case 'phone':
        setPhone(txt);
        params.phone = txt;
        break;
      case 'code':
        setCode(txt);
        params.code = txt;
        break;
    }

    console.log('params:', params);
    if (
      params.firstName &&
      params.lastName &&
      params.cardNo &&
      params.phone &&
      params.code
    ) {
      console.log('true');
      setCanSave(true);
    } else {
      console.log('false');
      setCanSave(false);
    }
  };

  return (
    <Box sx={{ height: 'auto' }}>
      {/* <Scrollbar
        style={{
          width: '100%',
          marginBottom: '48px',
          maxHeight: isMobile ? 'calc(75vh - 132px)' : 'calc(100vh - 132px)',
        }}
      > */}
      <Box px={2.5} pt={2.5} pb={4}>
        <Stack spacing={1}>
          <OutlinedInput
            value={firstName}
            sx={styles.input}
            onChange={(e) => _onInput('first', e.target.value)}
            placeholder={__('Enter You First Name')}
          ></OutlinedInput>
          <OutlinedInput
            value={lastName}
            sx={styles.input}
            onChange={(e) => _onInput('last', e.target.value)}
            placeholder={__('Enter You Last Name')}
          ></OutlinedInput>
          <OutlinedInput
            value={cardNo}
            sx={styles.input}
            onChange={(e) => _onInput('card', e.target.value)}
            placeholder={__('Enter CPF')}
          ></OutlinedInput>
          <OutlinedInput
            sx={styles.input}
            value={phone}
            onChange={(e) => _onInput('phone', e.target.value)}
            placeholder={__('Please enter phone number')}
          ></OutlinedInput>

          <Stack>
            <Stack spacing={{ xs: 1.5, md: 2 }}>
              <CodeInput
                code={code}
                sx={styles.input}
                onChange={(v) => _onInput('code', v)}
                onSendSMS={handleSendCode}
                placeholder={__('Please enter verify code')}
              />
            </Stack>
            <Stack
              sx={{
                width: '100%',
                marginTop: '4px',
              }}
              direction="row"
              justifyContent="flex-end"
            >
              <Typography
                variant="body2"
                color={alpha(theme.palette.info.main, 0.56)}
              >
                {
                  'To ensure the safety of your account, please fill in true and accurate information. '
                }
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Box>
      {/* </Scrollbar> */}
      <Box
        sx={{
          bgcolor: 'card.main',
          px: '16px',
          py: '24px',
          width: '100%',
        }}
      >
        <LoadingButton
          disabled={!canSave}
          variant="contained"
          fullWidth
          sx={{
            background: canSave ? ColorfulBg() : '#3C3952',
            boxShadow: 'none !important',
            borderRadius: '8px',
          }}
          loading={loading}
          onClick={handleSubmit}
        >
          {__('Save')}
        </LoadingButton>
      </Box>
    </Box>
  );
};

const styles = {
  input: {
    height: '48px',
    bgcolor: 'card.main',
    '&.MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'divider', // 设置边框颜色
      },
    },
  },
};

export default WithdrawValidConten;
