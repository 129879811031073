import { Box, SxProps, Theme } from '@mui/material';

interface Props {
  name: string;
  color?: string;
  size?: number;
  id?: string;
  onClick?: () => void;
  sx?: SxProps<Theme>;
}

const Iconfont: React.FC<Props> = (props: Props) => {
  return (
    <Box
      component="svg"
      onClick={props.onClick}
      sx={{
        color: props.color,
        width: props.size || 24,
        height: props.size || 24,
        fill: 'currentcolor',
        verticalAlign: '-0.15em',
        overflow: 'hidden',
        ...props.sx,
      }}
      id={props.id}
    >
      <use xlinkHref={`#${props.name}`}></use>
    </Box>
  );
};

export default Iconfont;
