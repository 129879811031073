import { Box, IconButton, Stack } from '@mui/material';

import ICON_FACEBOOK from '@/assets/footer/icon_facebook@2x.png';
import ICON_GOOGLE from '@/assets/footer/icon_google@2x.png';
import ICON_INSTAGRAM from '@/assets/footer/icon_instagram@2x.png';
import ICON_TELEGRAM from '@/assets/footer/icon_telegram@2x.png';
import ICON_TIKTOK from '@/assets/footer/icon_tiktok@2x.png';
import ICON_TWITTER from '@/assets/footer/icon_twitter@2x.png';
import ICON_YOUTUBE from '@/assets/footer/icon_YouTube@2x.png';
import useResponsive from '@/hooks/useResponsive';

interface Props {}
const styles = {
  mobieImg: { width: 24, height: 24 },
};
const FooterLinks: React.FC<Props> = (props: Props) => {
  const isMobile = useResponsive('down', 'md');
  if (isMobile) {
    return (
      <Box
        p={1}
        sx={{
          bgcolor: {
            md: 'transparent',
          },
          borderRadius: '8px',
        }}
      >
        <Stack
          justifyContent="space-between"
          width="100%"
          direction="row"
          mb={1}
        >
          <IconButton
          // href="https://t.me/SereiabetOficial"
          // target="_blank"
          >
            <img src={ICON_TELEGRAM} alt="Sereia Bet" style={styles.mobieImg} />
          </IconButton>
          <IconButton
          // href="https://www.facebook.com/profile.php?id=61551877903551&mibextid=ZbWKwL"
          // target="_blank"
          >
            <img src={ICON_FACEBOOK} alt="facebook" style={styles.mobieImg} />
          </IconButton>
          <IconButton
          // href="https://instagram.com/sereiabetofc?igshid=MzRlODBiNWFlZA=="
          // target="_blank"
          >
            <img src={ICON_INSTAGRAM} alt="instagram" style={styles.mobieImg} />
          </IconButton>
          <IconButton
          // href="https://biolink.info/sereiabetofc"
          // target="_blank"
          >
            <img src={ICON_GOOGLE} alt="google" style={styles.mobieImg} />
          </IconButton>
          <IconButton
          // href="https://www.youtube.com/@sereiabetofc"
          // target="_blank"
          >
            <img src={ICON_YOUTUBE} alt="sereia" style={styles.mobieImg} />
          </IconButton>
          <IconButton
          // href="https://www.tiktok.com/@sereiabetofc?_t=8gKTqB69pvp&_r=1"
          // target="_blank"
          >
            <img src={ICON_TIKTOK} alt="tiktok" style={styles.mobieImg} />
          </IconButton>
          <IconButton
          // href="https://twitter.com/sereiabetofc"
          // target="_blank"
          >
            <img src={ICON_TWITTER} alt="twitter" style={styles.mobieImg} />
          </IconButton>
          {/* <IconButton href="https://wa.me/5564981291313" target="_blank">
            <img src={ICON_SEREIA} alt="sereia" style={styles.mobieImg} />
          </IconButton> */}
        </Stack>

        {/* <Stack justifyContent="space-between" width="100%" direction="row"> */}

        {/* </Stack> */}
      </Box>
    );
  }
  return (
    <Box
      p={1}
      sx={{
        bgcolor: {
          xs: 'card.main',
          md: 'transparent',
        },
        borderRadius: '8px',
      }}
    >
      <Stack
        justifyContent="center"
        width="100%"
        direction="row"
        spacing={5}
        flexWrap="wrap"
      >
        <IconButton href="https://t.me/SereiabetOficial" target="_blank">
          <img
            src={ICON_TELEGRAM}
            alt="Sereia Bet"
            style={{ width: 40, height: 40 }}
          />
        </IconButton>
        <IconButton
          href="https://www.facebook.com/profile.php?id=61551877903551&mibextid=ZbWKwL"
          target="_blank"
        >
          <img
            src={ICON_FACEBOOK}
            alt="facebook"
            style={{ width: 40, height: 40 }}
          />
        </IconButton>
        <IconButton
          href="https://instagram.com/sereiabetofc?igshid=MzRlODBiNWFlZA=="
          target="_blank"
        >
          <img
            src={ICON_INSTAGRAM}
            alt="instagram"
            style={{ width: 40, height: 40 }}
          />
        </IconButton>
        <IconButton href="https://biolink.info/sereiabetofc" target="_blank">
          <img
            src={ICON_GOOGLE}
            alt="google"
            style={{ width: 40, height: 40 }}
          />
        </IconButton>
        <IconButton
          href="https://www.youtube.com/@sereiabetofc"
          target="_blank"
        >
          <img
            src={ICON_YOUTUBE}
            alt="sereia"
            style={{ width: 40, height: 40 }}
          />
        </IconButton>
        <IconButton
          href="https://www.tiktok.com/@sereiabetofc?_t=8gKTqB69pvp&_r=1"
          target="_blank"
        >
          <img
            src={ICON_TIKTOK}
            alt="tiktok"
            style={{ width: 40, height: 40 }}
          />
        </IconButton>
        <IconButton href="https://twitter.com/sereiabetofc" target="_blank">
          <img
            src={ICON_TWITTER}
            alt="twitter"
            style={{ width: 40, height: 40 }}
          />
        </IconButton>
        {/* <IconButton href="https://wa.me/5564981291313" target="_blank">
          <img
            src={ICON_SEREIA}
            alt="sereia"
            style={{ width: 40, height: 40 }}
          />
        </IconButton> */}
      </Stack>
    </Box>
  );
};

export default FooterLinks;
