import useAuth from '@/hooks/useAuth';
import useLang from '@/hooks/useLang';
import useResponsive from '@/hooks/useResponsive';
import services from '@/services';
import {
  isOpenSignSuccessState,
  signListState,
  todaySignListState,
} from '@/stores/auth';
import { LoadingButton } from '@mui/lab';
import { alpha, Box, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { cloneDeep } from 'lodash';
import { useEffect, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { SignCardView } from './SignCard7';
import SignSuccessDrawer from './SignSuccessDrawer';

export const DailySignView = () => {
  const { user, getWallet, getUserInfo } = useAuth();
  const [openSignSuccess, setOpenSignSuccess] = useRecoilState(
    isOpenSignSuccessState,
  );
  const setSignList = useSetRecoilState(signListState);
  const setTodaySignList = useSetRecoilState(todaySignListState);
  const isMobile = useResponsive('down', 'md');
  const theme = useTheme();
  const { __ } = useLang();
  const [sign7Ary, setSign7Ary] = useState<Array<any>>([]);
  const [signing, setSigning] = useState<Boolean>(false);

  useEffect(() => {
    getSignAry();
  }, []);
  const getSignAry = async () => {
    let res = { data: [] }; //await services.auth.getSignList();暂时去除请求签到列表
    let data = res?.data;
    if (Array.isArray(data)) {
      setSignList(data || []);
      changeToSign7(data || []);
    }
  };

  const changeToSign7 = (data: any[]) => {
    // 今天签到天数
    let today =
      user?.qiandao == 1
        ? parseInt(`${user!.qiandao_num}`, 10)
        : parseInt(`${user!.qiandao_num}`, 10) + 1;
    let isFirstWeek = today <= 3;
    let isLastWeek = data.length < today + 3;
    let signAryTmp = [];
    if (data.length < 8) {
      signAryTmp = cloneDeep(data);
    } else if (isFirstWeek) {
      signAryTmp = data.slice(0, 7);
    } else if (isLastWeek) {
      signAryTmp = data.slice(data.length - 7, data.length);
    } else {
      signAryTmp = data.slice(today - 4, today + 3);
    }
    setSign7Ary(signAryTmp);
  };

  const handleSign = async () => {
    // setOpenSign.off();
    if (user?.qiandao) {
      // let res = {
      //   status: 1,
      //   msg: '',
      //   data: [
      //     {
      //       number: 1,
      //       title: 'Money',
      //       pic: 'https://test2.brabab.com/upload/level/20231117/2e0d58f87a0a8565be10ff2695aee6c5.png',
      //     },
      //     {
      //       number: 1,
      //       title: 'Lottery',
      //       pic: 'https://test2.brabab.com/upload/level/20231117/12a8345aae0b24aea5c96dc2b5e04d45.png',
      //     },
      //   ],
      //   handle: 'refresh_money',
      // };
      // await getSignAry();
      // setOpenSignSuccess(true);
      return;
    }
    if (signing) {
      return;
    }
    setSigning(true);
    let res = await services.auth.signDaily();
    setSigning(false);
    if (res?.status === 1) {
      setTodaySignList(res?.data || []);
      getUserInfo();
      getWallet();
      await getSignAry();
      setOpenSignSuccess(true);
    }
  };
  if (sign7Ary.length < 1) {
    return null;
  }

  return (
    <Box
      mt={2.5}
      sx={{
        bgcolor: 'background.paper',
        borderRadius: '10px',
        p: { xs: '12px 16px', md: 3 },
        mt: 2.5,
      }}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography
          variant="body2"
          color={alpha(theme.palette.info.main, 0.56)}
        >
          <span>{__('dailySignTitle1')}</span>
          <span style={{ color: 'white' }}>{` ${user!.qiandao_num} `}</span>
          <span>{__('dailySignTitle2')}</span>
        </Typography>
        <LoadingButton
          disabled={user?.qiandao == 1}
          size="small"
          variant="contained"
          sx={{
            bgcolor: user?.qiandao == 1 ? 'text.secondary' : 'primary.main',
            height: '32px',
            borderRadius: '8px',
            px: '12px',
            color: user?.qiandao == 1 ? 'text.disabled' : 'text.primary',
            textTransform: 'none',
            pointerEvents: user?.qiandao == 0 ? 'all' : 'none',
          }}
          onClick={handleSign}
        >
          {user?.qiandao == 1 ? __('Signed in') : __('Sign in')}
        </LoadingButton>
      </Stack>
      <Stack
        sx={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          position: 'relative',
        }}
        mt={'16px'}
      >
        {sign7Ary.map((item, idx) => {
          const isAvtive = idx <= user!.qiandao_num - 1;
          return (
            <SignCardView
              key={idx}
              day={item.day}
              dayData={item}
              dayNum={idx}
              maxDayNum={sign7Ary.length}
              money={item.money}
              active={isAvtive}
              todaySign={user?.qiandao || 0}
              lastSign={user!.qiandao_num}
              linWidth={'16.6%'}
            />
          );
        })}
        <Stack
          sx={{
            flexDirection: 'row',
            alignItems: 'center',
            position: 'absolute',
            width: '99%',
            top: '14px',
            left: '2px',
          }}
        >
          {sign7Ary.map((item, idx) => {
            const isAvtive = idx <= user!.qiandao_num - 1;
            if (idx == sign7Ary.length - 1) {
              return null;
            }
            return (
              <Box
                key={idx}
                sx={{
                  width: '16.6%',
                  height: '1px',
                }}
              >
                <Stack
                  sx={{
                    bgcolor: isAvtive ? 'primary.main' : 'background.neutral',
                    height: isAvtive ? '4px' : '1px',
                  }}
                ></Stack>
              </Box>
            );
          })}
        </Stack>
      </Stack>
      {/* <Grid container columns={{ xs: 42, md: 21 }} mt={'16px'}>
        {sign7Ary.map((item, idx) => {
          const isAvtive = idx <= user!.qiandao_num - 1;
          return (
            <Grid item xs={6} md={isMobile ? 6 : 3} key={idx}>
              <SignCardView
                day={item.day}
                dayData={item}
                dayNum={idx}
                maxDayNum={sign7Ary.length}
                money={item.money}
                active={isAvtive}
                todaySign={user?.qiandao || 0}
                lastSign={user!.qiandao_num}
              />
            </Grid>
          );
        })}
      </Grid> */}
      {/* <SignModal
        open={openSign}
        onClose={setOpenSign.off}
        onSign={handleSign}
      /> */}
      {/* <SignSuccessModal
        open={openSignSuccess}
        onClose={setOpenSignSuccess.off}
      /> */}
      <SignSuccessDrawer lastSign={user!.qiandao_num} />
    </Box>
  );
};
