import SetPaymentDrawer from '@/components/Auth/password/SetPaymentDrawer';
import BindCPFDrawer from '@/components/withdraw/BindCPFDrawer';
import BindPhoneDrawer from '@/components/withdraw/BindPhone';
import WithdrawDrawer from '@/components/withdraw/WithdrawDrawer';
import WithdrawValidDrawer from '@/components/withdraw/WithdrawValidDrawer';
import { Box } from '@mui/material';

interface Props {}

const WithdrawContainer: React.FC<Props> = (props: Props) => {
  return (
    <Box>
      <BindCPFDrawer />
      <BindPhoneDrawer />
      <WithdrawValidDrawer />
      <WithdrawDrawer />
      {/* <UnSetPayPwdDrawer /> */}
      <SetPaymentDrawer />
    </Box>
  );
};

export default WithdrawContainer;
