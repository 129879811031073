import useBoolean from '@/hooks/useBoolean';
import useLang from '@/hooks/useLang';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, InputAdornment, OutlinedInput } from '@mui/material';

interface Props {
  password: string;
  onChangePassword: (value: string) => void;
  placeholder?: string;
}

const PasswordInput: React.FC<Props> = (props: Props) => {
  const [showPassword, setShowPassword] = useBoolean();
  const { __ } = useLang();
  return (
    <OutlinedInput
      type={showPassword ? 'text' : 'password'}
      value={props.password}
      onChange={(e) => props.onChangePassword(e.target.value)}
      placeholder={props.placeholder || __('Enter your password')}
      endAdornment={
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            onClick={setShowPassword.toggle}
            onMouseDown={(e) => e.preventDefault()}
            edge="end">
            {showPassword ? (
              <VisibilityOff fontSize="small" />
            ) : (
              <Visibility fontSize="small" />
            )}
          </IconButton>
        </InputAdornment>
      }
    />
  );
};

export default PasswordInput;
