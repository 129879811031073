import { Theme } from '@mui/material/styles';

export default function Container(theme: Theme) {
  return {
    MuiContainer: {
      defaultProps: {
        maxWidth: 'xl', // 将默认最大宽度设置为 "lg"
      },
      styleOverrides: {
        root: {
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
          // '@media (min-width:600px)': {
          //   paddingLeft: '16px',
          //   paddingRight: '16px',
          // },
          [`@media (min-width:${theme.breakpoints.values.md}px)`]: {
            paddingLeft: theme.spacing(4),
            paddingRight: theme.spacing(4),
          },
        },
      },
    },
  };
}
