import ICON_HISTORY from '@/assets/icons/icon_history_5.svg';
import ICON_MESSAGE from '@/assets/icons/icon_message_5.svg';
import ICON_RIGHT from '@/assets/icons/icon_rightArrow.png';
import ICON_SETTING from '@/assets/icons/icon_setting_5.svg';
import ICON_LIVE from '@/assets/icons/icon_support_5.svg';
import useAuth from '@/hooks/useAuth';
import useLang from '@/hooks/useLang';
import { meDrawerState, supportUrlState } from '@/stores/auth';
import { supportListState, tenantConfigState } from '@/stores/config';
import { Box, Divider, MenuItem, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

interface Props {}

const styles = {
  MeMenuItemBg: {
    height: '44px',
    marginLeft: '4px',
    marginRight: '4px',
    width: 'calc(25vw - 22px)',
    justifyContent: 'center',
  },
  messageBradge: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '16px',
    height: '16px',
    bgcolor: 'error.main',
    borderRadius: '10px',
    color: 'white',
    right: '5px',
    top: '-10px',
  },
};

const MeMenus: React.FC<Props> = (props: Props) => {
  const navigate = useNavigate();
  const { __ } = useLang();
  const supportUrl = useRecoilValue(supportUrlState);
  const setOpenMeDrawer = useSetRecoilState(meDrawerState);
  const [supportList, setSupportList] = useRecoilState(supportListState);
  const tenantConfig = useRecoilValue(tenantConfigState);

  let liveSupport = supportList.find((su) => su.type == 5);

  const menuData = [
    // { name: __('Reward'), icon: ICON_REWARD, type: 'reward' },
    {
      name: __('History'),
      icon: tenantConfig?.iconConfig?.personalIcon?.history || ICON_HISTORY,
      type: 'history',
    },
    {
      name: __('Message'),
      icon: tenantConfig?.iconConfig?.personalIcon?.message || ICON_MESSAGE,
      type: 'message',
    },
    // { name: __('Account'), icon: ICON_ACCOUNT, type: 'account' },
    {
      name: __('Setting'),
      icon: tenantConfig?.iconConfig?.personalIcon?.setting || ICON_SETTING,
      type: 'setting',
    },
    {
      name: __('Live Support'),
      icon: tenantConfig?.iconConfig?.personalIcon?.support || ICON_LIVE,
      type: 'live',
    },
  ];
  const { user } = useAuth();
  let msgNum = user?.msg || 0;
  const _goMenu = (type: string) => {
    if (type == 'live' && liveSupport?.url) {
      window.open(liveSupport.url, '_blank');
      return;
    }
    let page = '';
    switch (type) {
      case 'reward':
        page = '/reward';
        break;
      case 'history':
        page = '/records';
        break;
      case 'message':
        page = '/message';
        break;
      case 'account':
        page = '/account';
        break;
      case 'setting':
        page = '/setting';
        break;
    }
    navigate(page);
  };

  return (
    <Box
      sx={{
        bgcolor: 'card.main',
        borderRadius: '10px',
        mt: 2.5,
        paddingY: '12px',
        paddingX: '12px',
      }}
    >
      {menuData.map((v, i) => {
        return (
          <Box key={i}>
            {i != 0 ? <Divider sx={{ m: '0 !important' }} /> : null}
            <MenuItem
              sx={{
                height: '56px',
                justifyContent: 'space-between',
                px: 0,
              }}
              onClick={() => _goMenu(v.type)}
            >
              <Stack alignItems="center" direction="row" spacing={1}>
                <img style={{ width: 24, height: 24 }} alt="" src={v.icon} />
                <Typography variant="body1" fontWeight={500}>
                  {v.name}
                </Typography>
              </Stack>
              <Stack alignItems="center" direction="row" spacing={1}>
                {msgNum > 0 && v.type == 'message' ? (
                  <Box sx={styles.messageBradge}>
                    <Typography variant="body2">{msgNum}</Typography>
                  </Box>
                ) : null}
                <img
                  style={{ width: 24, height: 24 }}
                  alt=""
                  src={ICON_RIGHT}
                />
              </Stack>
            </MenuItem>
          </Box>
        );
      })}

      {/* <ListItemIcon
          sx={[styles.MeMenuItemBg, { marginRight: 0 }]}
          onClick={() => _goMenu('/records')}
        >
          <Stack alignItems="center">
            <img src={ICON_EXCHANGE} alt="" style={{ width: 24, height: 24 }} />
            <Typography variant="body1">{'Exchange'}</Typography>
          </Stack>
        </ListItemIcon> */}
    </Box>
  );
};

export default MeMenus;
