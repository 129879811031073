import iOSDownLoad from '@/assets/images/iOSDownLoad.png';
import VIP0 from '@/assets/vip/VIP_l0.png';
import VIP1 from '@/assets/vip/VIP_l1.png';
import VIP10 from '@/assets/vip/VIP_l10.png';
import VIP2 from '@/assets/vip/VIP_l2.png';
import VIP3 from '@/assets/vip/VIP_l3.png';
import VIP4 from '@/assets/vip/VIP_l4.png';
import VIP5 from '@/assets/vip/VIP_l5.png';
import VIP6 from '@/assets/vip/VIP_l6.png';
import VIP7 from '@/assets/vip/VIP_l7.png';
import VIP8 from '@/assets/vip/VIP_l8.png';
import VIP9 from '@/assets/vip/VIP_l9.png';
import useLang from '@/hooks/useLang';
import { vipListState } from '@/stores/auth';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useRecoilValue } from 'recoil';
interface Props {}

const IOSDownLoadConten = (props: Props) => {
  const { __ } = useLang();
  const vipList = useRecoilValue(vipListState);
  const theme = useTheme();
  const getVipImg = (id: any) => {
    switch (id) {
      case '0':
        return VIP0;
      case '1':
        return VIP1;
      case '2':
        return VIP2;
      case '3':
        return VIP3;
      case '4':
        return VIP4;
      case '5':
        return VIP5;
      case '6':
        return VIP6;
      case '7':
        return VIP7;
      case '8':
        return VIP8;
      case '9':
        return VIP9;
      case '10':
        return VIP10;
    }
  };

  return (
    <Box px={2.5}>
      <img
        src={iOSDownLoad}
        alt=""
        style={{ width: '375px', height: '319px' }}
      />
    </Box>
  );
};

export default IOSDownLoadConten;
