import services from '@/services';
import {
  isOpenLRModalState,
  LRModalInitalPage
} from '@/stores/auth';
import { useEffect, useRef } from 'react';
import { useSetRecoilState } from 'recoil';
import useAuth from './useAuth';

const useHeartbeat = (interval: number) => {
  const { logout, updateWallet } = useAuth();
  const setInitalPage = useSetRecoilState(LRModalInitalPage);
  const setOpenLRModal = useSetRecoilState(isOpenLRModalState);
  const timer = useRef<NodeJS.Timeout>();

  useEffect(() => {
    const heartbeat = () => {
      services.config.heartbeat().then((res) => {
        if (res?.handle === 'clean_token') {
          logout();

          timer.current && clearInterval(timer.current);

          // if (isMobile) {
          //   navigate('/signin');
          // } else {
          //   setOpenSigninModal(true);
          // }
          setInitalPage(0);
          setOpenLRModal(true);
        } else {
          if (res?.data) {
            updateWallet(res.data);
          }
        }
      });
    };

    timer.current = setInterval(heartbeat, interval);
    heartbeat();

    return () => {
      timer.current && clearInterval(timer.current);
    };
  }, []);
};

export default useHeartbeat;
