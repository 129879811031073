import { Box, BoxProps } from '@mui/material';

interface Props extends BoxProps {
  children: React.ReactNode;
}

const Center = (props: Props) => {
  return (
    <Box {...props} display="flex" alignItems="center" justifyContent="center">
      {props.children}
    </Box>
  );
};

export default Center;
