import useHeartbeat from '@/hooks/useHeartbeat';
import { Box } from '@mui/material';

interface Props {}

const Heartbeat: React.FC<Props> = (props: Props) => {
  useHeartbeat(15 * 1000);
  return <Box></Box>;
};

export default Heartbeat;
