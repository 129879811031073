import services from '@/services';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

export interface IAgentSummary {
  userId: number;
  totalCount: number; //总邀请人数（历史邀请人数）
  totalActivateCount: number;//总激活人数（历史激活人数）
  totalRechargedCount: number;//总首充人数（历史首充人数）
  registerCount: number; //该时间段内注册人数（新增人数）
  activateCount: number; //有效人数
  rechargedCount: number; //充值人数
  firstRechargeCount: number; //首充人数
  rechargeTimes: number; //充值次数
  rechargeAmt: number; //充值金额
  cashOutTimes: number; //提现次数
  cashOutAmt: number; //提现金额
  orderAmt: number; //下单金额
  orderResultAmt: number; //订单结果金额
  agentAmt:number;
}

export interface IAgentPeople {
  id: string;
  regTime: number;
  vipState: number;
  agentState: number
}

export const useAgentSummary = (start: string, end: string) => {
  const [data, setData] = useState<IAgentSummary>();
  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState<IAgentPeople[]>([]);

  useEffect(() => {
    const fetchAgentSummary = async () => {
      setIsLoading(true);

      const {data,list} = await services.config.getAgentSummary(
        dayjs(start).unix(),
        dayjs(end).unix(),
      );
      setData(data);
      setList(list);
      setIsLoading(false);
    };

    fetchAgentSummary();
  }, [start, end]);

  return {
    data,
    list,
    isLoading,
  };
};
