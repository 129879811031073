import Center from '@/components/center';
import useLang from '@/hooks/useLang';
import useResponsive from '@/hooks/useResponsive';
import { todaySignListState, userInfoState } from '@/stores/auth';
import { hideScrollbarY } from '@/utils/cssStyles';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import { Scrollbar } from 'react-scrollbars-custom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { SignCardView28 } from './SignCard28';

interface Props {
  lastSign?: number;
  dayList: {}[][];
}

const SignSuccessConten = (props: Props) => {
  const { __ } = useLang();
  const isMobile = useResponsive('down', 'md');

  const user = useRecoilValue(userInfoState);
  const [todaySignList] = useRecoilState(todaySignListState);
  const theme = useTheme();
  let hasGp = todaySignList.length > 0;

  const { dayList } = props;
  let line = dayList.length % 7;
  let height = isMobile
    ? hasGp
      ? `calc(45vh + ${line * 64}px)`
      : `calc(25vh + ${line * 64}px)`
    : '100vh';
  if (line >= 4) {
    height = isMobile ? (hasGp ? '70vh' : '55vh') : '100vh';
  }
  return (
    <Box sx={{ height }}>
      <Scrollbar
        style={{
          width: '100%',
          marginBottom: '48px',
          maxHeight: isMobile ? 'calc(80vh - 132px)' : 'calc(100vh - 132px)',
        }}
      >
        <Box px={2.5} pt={2.5} pb={2}>
          {hasGp ? (
            <Center flexDirection={'column'} mb={'32px'}>
              <Typography>{__('Congratulations')}</Typography>
              <Typography>{__('signSuccessTip')}</Typography>
              <Box
                sx={{
                  overflowX: 'scroll',
                  width: '100%',
                  maxWidth: '100%',
                  ...hideScrollbarY,
                }}
                mt={'16px'}
              >
                <Stack
                  direction="row"
                  spacing="10px"
                  sx={{
                    justifyContent:
                      todaySignList.length < 3 ? 'center' : 'flex-start',
                  }}
                >
                  {todaySignList.map((item, idx) => {
                    return (
                      <Box>
                        <img
                          key={idx}
                          src={item.pic}
                          alt=""
                          style={{
                            width: 120,
                            height: 120,
                            borderTopRightRadius: '16px',
                            borderTopLeftRadius: '16px',
                            border: '1px solid #333E4F',
                            backgroundColor: 'background.neutral',
                          }}
                        />
                        <Center
                          sx={{
                            height: '28px',
                            bgcolor: 'background.neutral',
                            borderBottomLeftRadius: '16px',
                            borderBottomRightRadius: '16px',
                          }}
                        >
                          <Typography
                            color={'text.secondary'}
                            variant={'body2'}
                            textAlign={'center'}
                          >{`${item.title}*${item.number}`}</Typography>
                        </Center>
                      </Box>
                    );
                  })}
                </Stack>
              </Box>
            </Center>
          ) : null}

          <Typography
            variant="body2"
            color={alpha(theme.palette.info.main, 0.56)}
          >
            <span>{__('dailySignTitle1')}</span>
            <span style={{ color: 'white' }}>{` ${user!.qiandao_num} `}</span>
            <span>{__('dailySignTitle2')}</span>
          </Typography>
          <Grid>
            {dayList.map((SIGN_LIST_7, row) => {
              let weekTmp = SIGN_LIST_7;
              let rowMod = row % 2;
              return (
                <Grid
                  container
                  columns={{ xs: 42, md: 21 }}
                  mt={row == 0 ? '16px' : '0px'}
                  key={row}
                  justifyContent={rowMod > 0 ? 'flex-end' : 'flex-start'}
                >
                  {weekTmp.map((item, col) => {
                    const isAvtive = col <= user!.qiandao_num - 1;
                    return (
                      <Grid item xs={6} md={3} key={col}>
                        <SignCardView28
                          dayData={item}
                          row={row}
                          col={col}
                          maxRow={dayList.length}
                          maxDayNum={weekTmp.length}
                          active={isAvtive}
                          todaySign={user?.qiandao || 0}
                          lastSign={user!.qiandao_num}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Scrollbar>
    </Box>
  );
};

export default SignSuccessConten;
