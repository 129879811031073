import useResponsive from '@/hooks/useResponsive';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import { Box, Button, IconButton, Stack, Typography } from '@mui/material';

interface Props {
  onBack?: () => void;
  px?: number;
}

const BackHeader: React.FC<Props> = (props: Props) => {
  return (
    <Stack px={props.px || 0} direction="row" alignItems="center">
      <Button
        color="info"
        onClick={props.onBack}
        startIcon={<ArrowBackIosNewRoundedIcon />}
      >
        Back
      </Button>
    </Stack>
  );
};

export const PageHeader = (props: {
  onBack?: () => void;
  title: string;
  rightIcon?: React.ReactNode;
  onClickRight?: () => void;
}) => {
  const isMobile = useResponsive('down', 'md');
  if (isMobile) {
    return (
      <Stack
        px={1.5}
        width="100%"
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ height: '48px' }}
      >
        <Box
          width="48px"
          height="48px"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            ml: '-12px',
          }}
        >
          <IconButton color="info" onClick={props.onBack}>
            <ArrowBackIosNewRoundedIcon />
          </IconButton>
        </Box>
        <Typography variant="subtitle1" fontWeight={700}>
          {props.title}
        </Typography>
        <Box width="48px" height="48px">
          <IconButton color="info" onClick={props.onClickRight}>
            {props.rightIcon}
          </IconButton>
        </Box>
      </Stack>
    );
  } else {
    return (
      <Stack px={1.5} width="100%" direction="row" alignItems="center">
        <Typography variant="subtitle1" fontWeight={700}>
          {props.title}
        </Typography>
      </Stack>
    );
  }
};

export default BackHeader;
