import gameBg from '@/assets/images/gameBg.png';
import useLang from '@/hooks/useLang';
import useResponsive from '@/hooks/useResponsive';
import { IGameCategoryImg, IGameItem } from '@/types/config';
import { hideScrollbarX } from '@/utils/cssStyles';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/pagination';
import Center from '../center';
import EmptyData from '../EmptyData';
import GameFont from '../GameFont';
import ImageWithLoading from '../image';
import { GameCard } from './GameList';

interface Props {
  title: string;
  desc?: string;
  icon?: IGameCategoryImg;
  bgImg?: IGameCategoryImg;
  list: IGameItem[];
  onClickMore?: () => void;
}
// 首页小卡片样式，一行放6个
export const IconImgBgGameList = (props: Props) => {
  const isMobile = useResponsive('down', 'md');
  if (isMobile) {
    return <IconImgBgGameListMobile {...props} />;
  }
  return <IconImgBgGameListWeb {...props} />;
};

export const IconImgBgGameListWeb = (props: Props) => {
  const showList = props.list.slice(0, 8);
  return (
    <Box sx={{ position: 'relative', mt: '16px', pt: '24px' }}>
      {props?.icon?.square && false ? (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: '50%',
            transform: 'translateX(-50%)',
            width: '40px',
            display: 'block',
          }}
        >
          <img
            src={props?.icon?.square}
            alt=""
            style={{ width: '48px', height: '48px' }}
          />
        </Box>
      ) : null}
      <Box
        sx={{
          display: 'flex',
          height: '258px',
          flexDirection: 'column',
          justifyContent: 'space-between',
          backgroundImage: `url(${props?.bgImg?.vertocal || gameBg})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          pb: '12px',
          px: '12px',
          borderRadius: '8px',
        }}
      >
        <Center sx={{ mt: '32px' }}>
          <Typography
            variant="subtitle1"
            // className="br-game-font"
            sx={{
              fontWeight: '700',
              px: 1,
              overflow: 'hidden',
              width: '100%',
              textOverflow: 'ellipsis',
              textAlign: 'center',
              textShadow: '10px 3px 6px rgba(0, 0, 0, 0.16)',
              display: 'none',
            }}
          >
            {props?.title}
          </Typography>
        </Center>
        <Grid container gridRow={1} columns={24} spacing={{ xs: 1.5, sm: 2 }}>
          {showList.map((item, index) => {
            return (
              <Grid item key={item.id} md={3}>
                <GameCard item={item} />
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Box>
  );
};

export const IconImgBgGameListMobile = (props: Props) => {
  const { __ } = useLang();
  const navigate = useNavigate();

  const showList = props.list;

  return (
    <Box sx={{ position: 'relative', mt: '16px', pt: '16px' }}>
      {props?.icon?.square && false ? (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: '50%',
            transform: 'translateX(-50%)',
            display: 'block',
          }}
        >
          <img
            src={props?.icon?.square}
            alt=""
            style={{ width: '64px', height: '64px' }}
          />
        </Box>
      ) : null}

      <Box
        sx={{
          display: 'flex',
          height: '181px',
          flexDirection: 'column',
          justifyContent: 'space-between',
          backgroundImage: `url(${props?.bgImg?.transverse || gameBg})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          pb: '12px',
          borderRadius: '8px',
        }}
      >
        <Center sx={{ mt: '26px' }}>
          <Typography
            variant="subtitle1"
            // className="br-game-font"
            sx={{
              fontWeight: '700',
              px: 1,
              overflow: 'hidden',
              width: '100%',
              textOverflow: 'ellipsis',
              textAlign: 'center',
              textShadow: '10px 3px 6px rgba(0, 0, 0, 0.16)',
              display: 'none',
            }}
          >
            {props?.title}
          </Typography>
        </Center>

        <Stack
          direction="row"
          spacing="10px"
          sx={{
            pl: '12px',
            pr: '12px',
            ...hideScrollbarX,
          }}
        >
          {showList.map((item, index) => {
            return (
              <Box
                key={index}
                sx={{ cursor: 'pointer', position: 'relative' }}
                onClick={() => {
                  navigate('/game/info?id=' + item.id);
                }}
              >
                <ImageWithLoading
                  width={90}
                  height={119}
                  aspectRatio={'53/70'}
                  alt={item.game_name}
                  src={item.pic}
                />
                <Center
                  sx={{
                    bottom: '3px',
                    width: '100%',
                    overflow: 'hidden',
                    minHeight: '25%',
                    left: 0,
                    right: 0,
                    position: 'absolute',
                  }}
                >
                  <GameFont name={item.game_name}></GameFont>
                </Center>
              </Box>
            );
          })}
          {props.list.length === 0 && (
            <Center py={2}>
              <EmptyData />
            </Center>
          )}
        </Stack>
      </Box>
    </Box>
  );
};
