import { Stack, Typography } from "@mui/material";
import Center from "../center";
import ImageIconVip from "../image-icons/vip";

interface Props {}

const SinnerLevel: React.FC<Props> = (props: Props) => {
  return (
    <Center>
      <Center
        sx={{
          height: 40,
          borderRadius: "20px",
          bgcolor: { xs: "#221C3F", md: "#151030" },
          px: "20px",
        }}
      >
        <Stack direction="row" alignItems="center" spacing={1}>
          <ImageIconVip width="21px" height="24px" />
          <Typography variant="h5">unranked (Level 0)</Typography>
        </Stack>
      </Center>
    </Center>
  );
};

export default SinnerLevel;
