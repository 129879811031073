import useLang from '@/hooks/useLang';
import { InputAdornment, MenuItem, OutlinedInput, Select } from '@mui/material';

interface Props {
  phone: string;
  onChangePhone?: (value: string) => void;
  areaCode: number;
  onAreaCodeChange?: (value: number) => void;
  placeholder?: string;
  disabled?: boolean;
  type?: string;
}

const PhoneInput: React.FC<Props> = (props: Props) => {
  // const [areaCode, setAreaCode] = useState<number>(852);
  const { __ } = useLang();
  return (
    <OutlinedInput
      value={props.phone}
      onChange={(e) => props.onChangePhone?.(e.target.value)}
      startAdornment={
        <InputAdornment position="start">
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={props.areaCode}
            onChange={(e) => {
              props.onAreaCodeChange?.(+e.target.value);
            }}
            sx={{
              height: '48px',
              '.MuiOutlinedInput-notchedOutline': {
                border: '0 !important',
              },
            }}
          >
            <MenuItem value={55}>+55</MenuItem>
          </Select>
        </InputAdornment>
      }
      // inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
      type={props.type ?? 'number'}
      disabled={props.disabled}
      placeholder={props.placeholder || __('Please enter phone number')}
    />
  );
};

export default PhoneInput;
