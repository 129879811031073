import { ILotteryGift } from '@/types/vip';
import { Box, Stack, Typography } from '@mui/material';
interface Props {
  data: ILotteryGift;
}

const RewardItem: React.FC<Props> = (props: Props) => {
  return (
    <Box
      sx={{
        bgcolor: 'background.paper',
        p: 2,
        borderRadius: '8px',
      }}
    >
      <Stack
        spacing={1.5}
        sx={{ justifyContent: 'center', alignItems: 'center' }}
      >
        <Box
          sx={{
            width: '80px',
            height: '80px',
            borderRadius: '50%',
            overflow: 'hidden',
            minWidth: '80px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <img
            src={props.data.pic}
            alt=""
            style={{ width: '96px', height: '96px' }}
          />
        </Box>
        <Stack spacing={1} sx={{ minHeight: '54px' }}>
          <Typography variant="body1" fontWeight={500} textAlign="center">
            {props.data.title}
          </Typography>
          <Typography variant="body2" sx={{ opacity: 0.32 }} textAlign="center">
            {props.data.contents}
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
};

export default RewardItem;
