import icon_invite from '@/assets/images/icon_invite.png';
import icon_spinner from '@/assets/images/icon_spinner.png';
import icon_vip from '@/assets/images/icon_vip.png';
import invite_bg from '@/assets/images/invite_bg.png';
import spinner_bg from '@/assets/images/spinner_bg.png';
import vip_bg from '@/assets/images/vip_bg.png';
import Iconfont from '@/components/Iconfont';
import useAuth from '@/hooks/useAuth';
import {
  isOpenLRModalState,
  isOpenVipDialogState,
  LRModalInitalPage,
} from '@/stores/auth';
import { Divider, List, ListItem, ListItemButton } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

interface IMenuItem {
  name: string;
  path: string;
  icon: string;
  bgImg?: string;
  divider?: boolean;
  isPrivate?: boolean;
}

const MENU_LIST: IMenuItem[] = [
  {
    name: 'Spinner',
    path: '/lucky',
    icon: icon_spinner,
    bgImg: spinner_bg,
    isPrivate: true,
  },
  {
    name: 'VIP',
    path: '/vip',
    icon: icon_vip,
    bgImg: vip_bg,
    isPrivate: true,
  },
  {
    name: 'Invite',
    path: '/commissions',
    icon: icon_invite,
    bgImg: invite_bg,
    isPrivate: true,
  },
  {
    divider: true,
  } as IMenuItem,
  {
    name: 'Popular Games',
    path: '/popular-games',
    icon: 'icon_popularGames',
  },
  {
    name: 'Live Casino',
    path: '/live-casino',
    icon: 'icon_liveCasino',
  },
  {
    name: 'Slots',
    path: '/slots',
    icon: 'icon_slots',
  },
  {
    name: 'New Gamers',
    path: '/new-games',
    icon: 'icon_newGamers',
  },
  {
    name: 'Game Providers',
    path: '/game-providers',
    icon: 'icon_gameProviders',
  },
];

const ActiveMenuItem = (props: { name: string; icon: string }) => {
  return (
    <ListItem
      sx={{
        transition: '0.3',
        mb: 0.5,
        height: '48px',
        borderRadius: '8px',
        bgcolor: 'background.paper',
      }}
    >
      <Iconfont name={props.icon} sx={{ mr: 2 }} />
      {props.name}
    </ListItem>
  );
};

const ImgBgMenuItem = (props: {
  menu: IMenuItem;
  isAcitve: Boolean;
  handleClick: Function;
}) => {
  const { menu, handleClick, isAcitve } = props;
  if (isAcitve) {
    return (
      <ListItem
        sx={{
          transition: '0.3',
          mb: 0.5,
          height: '64px',
          borderRadius: '8px',
          backgroundImage: `url(${menu.bgImg})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <img
          src={menu.icon}
          alt=""
          style={{ width: '40px', height: '40px', marginRight: 2 }}
        />
        {menu.name}
      </ListItem>
    );
  }
  return (
    <ListItemButton
      key={menu.name}
      sx={{
        color: 'text.secondary',
        transition: '0.3',
        mb: 0.5,
        height: '64px',
        borderRadius: '8px',
        backgroundImage: `url(${menu.bgImg})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
      onClick={() => handleClick(menu)}
    >
      <img
        src={menu.icon}
        alt=""
        style={{ width: '40px', height: '40px', marginRight: 2 }}
      />
      {menu.name}
    </ListItemButton>
  );
};

const MenuList = () => {
  const [activeMenu, setActiveMenu] = useState<IMenuItem | null>(null);
  const navigate = useNavigate();
  // const setVisibility = useSetRecoilState(visibilityState);
  const setIsOpenVip = useSetRecoilState(isOpenVipDialogState);
  const location = useLocation();
  const setInitalPage = useSetRecoilState(LRModalInitalPage);
  const setOpenLRModal = useSetRecoilState(isOpenLRModalState);
  const { isLogin } = useAuth();

  const handleClick = (item: IMenuItem) => {
    // if (['Live Support', 'FAQ'].includes(item.name)) {
    //   setVisibility('maximized');
    // } else if (item.name === 'App Download') {
    //   window.location.href =
    //     'https://gamepic.s3.sa-east-1.amazonaws.com/brbrbet/app-release.apk';
    // } else {
    //   setActiveMenu(item);
    // }
    if (item.isPrivate && !isLogin) {
      // setOpen(true);
      setInitalPage(0);
      setOpenLRModal(true);
      return;
    }

    if (item.name === 'VIP') {
      setIsOpenVip(true);
    } else {
      navigate(item.path);
    }
  };

  useEffect(() => {
    const fullPath = location.pathname + location.search;
    let isMatch = false;
    MENU_LIST.forEach((item) => {
      if (item.divider) return;
      if (item.path === fullPath || item.path === location.pathname) {
        setActiveMenu(item);
        isMatch = true;
      }
    });
    if (!isMatch) {
      setActiveMenu(null);
    }
  }, [location]);

  return (
    <List>
      {MENU_LIST.map((menu, index) => {
        if (menu.divider) {
          return <Divider sx={{ my: 1 }} key={`divider-${index}`} />;
        }
        if (menu.isPrivate) {
          return (
            <ImgBgMenuItem
              menu={menu}
              key={menu.name}
              handleClick={handleClick}
              isAcitve={menu.name === activeMenu?.name}
            />
          );
        }
        if (menu.name === activeMenu?.name) {
          return (
            <ActiveMenuItem name={menu.name} icon={menu.icon} key={menu.name} />
          );
        } else {
          return (
            <ListItemButton
              key={menu.name}
              sx={{
                color: 'text.secondary',
                height: '48px',
                transition: '0.3',
                mb: 0.5,
                borderRadius: '8px',
                ':hover': {
                  bgcolor: 'background.paper',
                },
              }}
              onClick={() => handleClick(menu)}
            >
              <Iconfont name={menu.icon} sx={{ mr: 2 }} />
              {menu.name}
            </ListItemButton>
          );
        }
      })}
    </List>
  );
};

export default MenuList;
