import useLang from '@/hooks/useLang';
import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, Typography } from '@mui/material';

interface Props {
  onClose: () => void;
}

const DepositDialogHeader: React.FC<Props> = (props: Props) => {
  const { __ } = useLang();
  return (
    <Box
      sx={{
        height: 56,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        px: 1.5,
      }}
    >
      <Box width="40px"></Box>
      <Typography textAlign="center" variant="subtitle1" fontWeight={700}>
        {__('Deposit')}
      </Typography>
      <Box width="40px">
        <IconButton color="info" onClick={props.onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default DepositDialogHeader;
