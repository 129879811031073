import { PageHeader } from '@/components/BackHeader';
import CommonSkeleton from '@/components/CommonSkeleton';
import { SnackbarUtils } from '@/components/snackbar/SnackbarProvider';
import SocialButton from '@/components/social/buttons';
import useAuth from '@/hooks/useAuth';
import useBoolean from '@/hooks/useBoolean';
import useLang from '@/hooks/useLang';
import useScrollToTop from '@/hooks/useScrollToTop';
import services from '@/services';
import { ColorfulBg } from '@/styles';
import { IOAuthBind } from '@/types/user';
import { LoadingButton } from '@mui/lab';
import { Box, Stack, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MenusWeb from '../Me/components/MenusWeb';
import UnBindButton from './components/UnBindButton';

interface Props {}
// const ContainerSx = {
//   width: {
//     xs: '100%',
//     md: '480px',
//   },
//   mx: {
//     xs: 0,
//     md: 'auto',
//   },
// };

const LockButton = (props: {
  days?: string;
  id?: string;
  status?: number;
  onUnbind?: () => void;
}) => {
  const isBind = props.status === 1;
  const [loading, setLoading] = useBoolean();
  const { __ } = useLang();

  const handleBind = async () => {
    if (!props?.days || parseInt(props?.days || '0') < 1) {
      return;
    }
    if (loading) return;
    setLoading.on();
    await bandA(props.days);
    setLoading.off();
  };

  const bandA = async (days?: string) => {
    try {
      console.log('days:', days);
      const isSuccess = await services.auth.userLock({
        days,
      });
      if (isSuccess) {
        SnackbarUtils.success(__('Successfully'));
      }
    } catch (error) {
      console.log(error);
      return null;
    }
  };
  return (
    <LoadingButton
      disabled={parseInt(props?.days || '0') < 1}
      onClick={handleBind}
      sx={{
        background: ColorfulBg(),
        height: '48px',
        width: '100%',
        borderRadius: '8px',
        mt: '16px',
      }}
      variant="contained"
      size="small"
      loading={loading}
    >
      {__('Submit')}
    </LoadingButton>
  );

  // if (isBind) {
  //   return <UnBindButton id={props.id} onUnbind={props.onUnbind} />;
  // } else {
  //   return (
  //     <LoadingButton
  //       onClick={handleBind}
  //       sx={{ background: ColorfulBg, height: '48px', borderRadius: '50px' }}
  //       variant="contained"
  //       size="small"
  //       loading={loading}
  //     >
  //       {__('绑定')}
  //     </LoadingButton>
  //   );
  // }
};

const BindButton = (props: {
  id: string;
  status: number;
  onUnbind: () => void;
}) => {
  const isBind = props.status === 1;
  const [loading, setLoading] = useBoolean();
  const { __ } = useLang();

  const handleBind = async () => {
    if (loading) return;
    setLoading.on();
    const url = await fetchData(props.id);
    window.location.href = url;
    setLoading.off();
  };

  const fetchData = async (id: string) => {
    try {
      const data = await services.auth.getAuthUrl({
        id,
        callback_url:
          window.location.origin + window.location.pathname + '?id=' + id,
      });
      return data?.data?.url;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  if (isBind) {
    return <UnBindButton id={props.id} onUnbind={props.onUnbind} />;
  } else {
    return (
      <LoadingButton
        onClick={handleBind}
        sx={{ background: ColorfulBg(), borderRadius: '50px' }}
        variant="contained"
        size="small"
        loading={loading}
      >
        {__('绑定')}
      </LoadingButton>
    );
  }
};

const AccountBind: React.FC = () => {
  const { user, getUserInfo } = useAuth();
  const [isLoading, setIsLoading] = useBoolean();
  const [authInfo, setAuthInfo] = useState<Record<number, IOAuthBind>>({});
  const { __ } = useLang();

  const search = new URLSearchParams(window.location.search);
  const guid = search.get('guid');
  const id = search.get('id');

  useEffect(() => {
    fetchOauthBindList();
  }, []);

  useEffect(() => {
    if (guid && id) {
      fetchOauthGuid({ guid, id });
    } else {
      setIsLoading.off();
    }
  }, [guid, id]);

  const fetchOauthBindList = async () => {
    const data = await services.auth.getOauthBindList();
    if (data) {
      setAuthInfo(data);
    }
  };

  const fetchOauthGuid = async (params: { guid: string; id: string }) => {
    const res = await services.auth.getOauthGuid({
      ...params,
      type: 2,
    });
    if (res?.data?.is_binding) {
      SnackbarUtils.success(__('绑定成功'));
      getUserInfo();
      fetchOauthBindList();
    }
    setIsLoading.off();
  };

  const handleUnbind = () => {
    fetchOauthBindList();
  };

  if (!user || isLoading) {
    return <CommonSkeleton />;
  }

  return (
    <Box>
      <Box
        mb={1}
        display={{ xs: 'none', md: 'flex' }}
        alignItems="center"
        height="48px"
      >
        <Typography variant="subtitle2" fontWeight={700}>
          {__('Account')}
        </Typography>
      </Box>
      <Stack spacing={2}>
        <Stack
          sx={{
            border: { md: 'none' },
            bgcolor: { md: 'background.neutral' },
            borderRadius: '10px',
            p: { md: 3 },
          }}
          direction="row"
          alignItems="center"
          spacing={2}
        >
          <Stack flex="auto" direction="row" alignItems="center" spacing={1.5}>
            <SocialButton type="facebook" />
            <Stack spacing={0.5}>
              <Typography variant="body1" fontWeight={700}>
                Facebook
              </Typography>
              <Typography variant="body2" color="text.seconday">
                {authInfo[2]?.oauth_id ? `ID: ${authInfo[2]?.oauth_id}` : ' '}
              </Typography>
            </Stack>
          </Stack>

          <BindButton
            id="2"
            status={user.oauth2_banding_status}
            onUnbind={handleUnbind}
          />
        </Stack>

        <Stack
          sx={{
            border: { md: 'none' },
            bgcolor: { md: 'background.neutral' },
            borderRadius: '10px',
            p: { md: 3 },
          }}
          direction="row"
          alignItems="center"
          spacing={2}
        >
          <Stack flex="auto" direction="row" alignItems="center" spacing={1.5}>
            <SocialButton type="google" />
            <Stack spacing={0.5}>
              <Typography variant="body1" fontWeight={700}>
                Google
              </Typography>
              <Typography variant="body2" color="text.seconday">
                {authInfo[1]?.oauth_id ? `ID: ${authInfo[1]?.oauth_id}` : ' '}
              </Typography>
            </Stack>
          </Stack>

          <BindButton
            id="1"
            status={user.oauth1_banding_status}
            onUnbind={handleUnbind}
          />
        </Stack>
      </Stack>
    </Box>
  );
};

const AccountLock: React.FC = () => {
  const { user, getUserInfo } = useAuth();
  const [isLoading, setIsLoading] = useBoolean();
  const [authInfo, setAuthInfo] = useState<Record<number, IOAuthBind>>({});
  const { __ } = useLang();
  const [day, setDay] = useState<string>('1');
  const search = new URLSearchParams(window.location.search);
  const guid = search.get('guid');
  const id = search.get('id');

  useEffect(() => {
    fetchOauthBindList();
  }, []);

  useEffect(() => {
    if (guid && id) {
      fetchOauthGuid({ guid, id });
    } else {
      setIsLoading.off();
    }
  }, [guid, id]);

  const fetchOauthBindList = async () => {
    // const data = await services.auth.getOauthBindList();
    // if (data) {
    //   setAuthInfo(data);
    // }
  };

  const fetchOauthGuid = async (params: { guid: string; id: string }) => {
    const res = await services.auth.getOauthGuid({
      ...params,
      type: 2,
    });
    if (res?.data?.is_binding) {
      SnackbarUtils.success(__('绑定成功'));
      getUserInfo();
      fetchOauthBindList();
    }
    setIsLoading.off();
  };

  const handleUnbind = () => {
    fetchOauthBindList();
  };

  if (!user || isLoading) {
    return <CommonSkeleton />;
  }

  return (
    <Box>
      <Box
        mb={1}
        display={{ xs: 'none', md: 'flex' }}
        alignItems="center"
        height="48px"
      >
        <Typography variant="subtitle2" fontWeight={700}>
          {__('Account')}
        </Typography>
      </Box>
      <Typography variant="body1" fontWeight={400}>
        {__('account lock tip')}
      </Typography>
      <TextField
        sx={styles.input}
        InputProps={{
          style: {
            fontSize: '24px',
            height: '84px',
            backgroundColor: '#00000000',
          },
        }}
        value={day}
        id="outlined-helperText"
        label={__('Suspension Days')}
        onChange={(e) => setDay(e.target.value)}
        variant="filled"
        type="number"
        inputProps={{
          inputMode: 'numeric',
          pattern: '[0-9]*',
        }}
      />
      <LockButton
        id="1"
        days={day}
        status={user.oauth1_banding_status}
        onUnbind={handleUnbind}
      />
    </Box>
  );
};

const AccountBindPage: React.FC<Props> = (_props: Props) => {
  useScrollToTop();
  const navigate = useNavigate();
  const { __ } = useLang();

  return (
    <Box minHeight="calc(100vh - 112px)">
      <Box display={{ md: 'none' }} py="10px" ml={-1}>
        <PageHeader title={__('Account')} onBack={() => navigate(-1)} />
      </Box>
      <Box py={{ xs: 1.5, md: 2 }}>
        <Stack direction="row" spacing={3}>
          <MenusWeb active="account" />
          <Box flex="auto">
            {/* <AccountBind /> */}
            <AccountLock />
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};
const styles = {
  input: {
    height: '84px',
    width: '100%',
    marginTop: '16px',
    borderRadius: '8px',
    bgcolor: 'card.main',
    '&.MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'divider', // 设置边框颜色
      },
    },
  },
};

export default AccountBindPage;
