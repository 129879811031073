import useAuth from '@/hooks/useAuth';
import useBoolean from '@/hooks/useBoolean';
import useLang from '@/hooks/useLang';
import services from '@/services';
import { isOpenBindPhoneDrawerState } from '@/stores/auth';
import { LoadingButton } from '@mui/lab';
import { Box, Stack } from '@mui/material';
import { useState } from 'react';
import { useRecoilState } from 'recoil';
import CodeInput from '../Auth/login/CodeInput';
import PhoneInput from '../Auth/login/PhoneInput';
import CommonDrawerV2 from '../common-drawer/CommonDrawerV2';
import { SnackbarUtils } from '../snackbar/SnackbarProvider';

interface Props {}

const BindPhoneDrawer = (props: Props) => {
  const [open, setOpen] = useRecoilState(isOpenBindPhoneDrawerState);
  const [loading, setLoading] = useBoolean();
  const { getUserInfo } = useAuth();
  const { __ } = useLang();

  const [phone, setPhone] = useState<string>('');
  const [code, setCode] = useState<string>('');

  const handleSendCode = async () => {
    if (!phone) {
      return false;
    }
    return await services.auth.sendSMS({
      phone,
      type: 2,
    });
  };

  const handleSubmit = async () => {
    if (loading) {
      return;
    }

    setLoading.on();

    const params = {
      code,
      mobile: phone,
    };

    const isSuccess = await services.auth.bindPhone(params);
    setLoading.off();
    if (isSuccess) {
      SnackbarUtils.success(__('Successfully'));
      await getUserInfo();
      setOpen(false);
      setCode('');
      setPhone('');
    }
  };

  return (
    <CommonDrawerV2
      title={__('绑定手机')}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
    >
      <Box px={2.5} pt={2.5} pb={4}>
        <Stack spacing={2.5}>
          <PhoneInput
            phone={phone}
            onChangePhone={(v) => setPhone(v)}
            areaCode={55}
          />
          <CodeInput
            code={code}
            onChange={(v) => setCode(v)}
            onSendSMS={handleSendCode}
          />

          <LoadingButton
            sx={{
              background: 'linear-gradient(90deg, #3656FF 0%, #EA33D2 100%)',
              boxShadow: 'none !important',
              mt: '20px',
            }}
            fullWidth
            variant="contained"
            loading={loading}
            onClick={handleSubmit}
          >
            {__('Submit')}
          </LoadingButton>
        </Stack>
      </Box>
    </CommonDrawerV2>
  );
};

export default BindPhoneDrawer;
