import {
  isOpenLRModalState,
  LRModalInitalPage
} from '@/stores/auth';
import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';

export const useHomeSign = () => {
  const search = new URLSearchParams(window.location.search);
  const signin = search.get('signin');
  const setInitalPage = useSetRecoilState(LRModalInitalPage);
  const setOpenLRModal = useSetRecoilState(isOpenLRModalState);

  useEffect(() => {
    if (signin === '1') {
      // if (isMobile) {
      //   navigate('/signin');
      // } else {
      //   setOpen(true);
      // }
      setInitalPage(0);
      setOpenLRModal(true);
    }
  }, [signin]);
};
