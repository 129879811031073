import { SnackbarUtils } from '@/components/snackbar/SnackbarProvider';
import useLang from '@/hooks/useLang';
import services from '@/services';
import { ColorfulBg } from '@/styles';
import { SigninType } from '@/types';
import { LoadingButton } from '@mui/lab';
import { Box, OutlinedInput, Stack } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CodeInput from './CodeInput';
import PasswordInput from './PasswordInput';
import PhoneInput from './PhoneInput';

interface Props {
  onSuccess?: () => void;
  type: SigninType;
}

const ResetPasswordForm: React.FC<Props> = (props: Props) => {
  const [password, setPassword] = useState<string>('');
  const [passwordAgain, setPasswordAgain] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [areaCode, setAreaCode] = useState<number>(55);
  const [code, setCode] = useState<string>('');
  const navigate = useNavigate();
  const { __ } = useLang();
  const [loading, setLoading] = useState<boolean>(false);
  const isPhone = props.type === SigninType.Phone;

  const handleSendSMS = async () => {
    if (isPhone) {
      return services.auth.sendSMS({ phone: `+55${phone}`, type: 4 });
    } else {
      return services.auth.sendEmail({ email, type: 4 });
    }
  };

  const handleSubmit = async () => {
    if (loading) {
      return;
    }

    if (password !== passwordAgain) {
      SnackbarUtils.error(__('密码不一致'));
      return;
    }

    try {
      let params: any = {
        password,
        password1: passwordAgain,
        code,
        type: 1,
      };

      if (props.type === SigninType.Email) {
        params['email'] = email;
      } else {
        params['phone'] = phone;
      }
      await services.auth.resetPassword(params);
      SnackbarUtils.success(__('Successfully reset password'), () => {
        navigate('/signin');
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const AccountInput = {
    [SigninType.Email]: (
      <OutlinedInput
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder={__('Please enter email')}
      />
    ),
    [SigninType.Phone]: (
      <PhoneInput
        phone={phone}
        areaCode={areaCode}
        onAreaCodeChange={(v) => setAreaCode(v)}
        onChangePhone={(v) => setPhone(v)}
      />
    ),
  };

  return (
    <Box>
      <Stack spacing={{ xs: '20px', md: 2 }}>
        {AccountInput[props.type]}
        <CodeInput
          code={code}
          onChange={(v) => setCode(v)}
          onSendSMS={handleSendSMS}
        />
        <PasswordInput
          password={password}
          onChangePassword={(pwd) => setPassword(pwd)}
          placeholder={__('Enter new password')}
        />
        <PasswordInput
          password={passwordAgain}
          onChangePassword={(pwd) => setPasswordAgain(pwd)}
          placeholder={__('Enter new password Again')}
        />
      </Stack>
      <LoadingButton
        variant="contained"
        fullWidth
        sx={{
          background: ColorfulBg(),
          boxShadow: 'none !important',
          mt: 3,
          borderRadius: '8px',
        }}
        loading={loading}
        onClick={handleSubmit}
      >
        {__('Submit')}
      </LoadingButton>
      {/* <Typography
        mt={4}
        variant="body2"
        color="text.disabled"
        textAlign="center"
        display={{ xs: 'block', md: 'none' }}
      >
        {__('Can not receive code?')}
        <br />
        <Link sx={{ textDecoration: 'none' }} color="primary.main">
          {__('Click to contact online customer service')}
        </Link>
      </Typography> */}
    </Box>
  );
};

export default ResetPasswordForm;
