import { Box } from '@mui/material';
import { VipProcessBarColors } from '../consts';

export const VIPProcessBar = ({
  current,
  total,
  vip,
  barColor,
}: {
  current: number;
  total: number;
  vip: number;
  barColor?: string;
}) => {
  const background = VipProcessBarColors[vip];

  const percentage = total <= 0 ? 0 : Math.round((current * 100) / total);
  return (
    <Box mt={1}>
      <Box
        sx={{
          width: '100%',
          height: '6px',
          bgcolor: 'text.disabled',
          borderRadius: '21px',
        }}
      >
        <Box
          sx={{
            width: `${Math.min(100, percentage)}%`,
            bgcolor: barColor || background,
            height: '6px',
            borderRadius: '21px',
          }}
        ></Box>
      </Box>
    </Box>
  );
};
