import icon_history_active from '@/assets/icons/icon_history_active.png';
import ICON_HOME_ACTIVE from '@/assets/icons/icon_home_active.png';
import icon_message_active from '@/assets/icons/icon_message_active.png';
import ME_HISTORY from '@/assets/icons/me_history.svg';
import ME_HOME from '@/assets/icons/me_home.svg';
import ICON_LIVE from '@/assets/icons/me_live.svg';
import ICON_MESSAGE from '@/assets/icons/me_message.svg';
import { tenantConfigState } from '@/stores/config';

import useLang from '@/hooks/useLang';
import useResponsive from '@/hooks/useResponsive';
import { supportUrlState } from '@/stores/auth';
import { ListItemButton, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

interface Props {
  active: string;
}

const MenusWeb: React.FC<Props> = (props: Props) => {
  const { __ } = useLang();
  const navigate = useNavigate();
  const supportUrl = useRecoilValue(supportUrlState);
  const tenantConfig = useRecoilValue(tenantConfigState);

  const isMobile = useResponsive('down', 'md');

  const ItemBg = {
    borderRadius: '8px',
    overflow: 'hidden',
    width: '168px',
    p: 1.5,
  };

  const menuAry = [
    {
      key: 'home',
      img: ME_HOME,
      activeImg: ICON_HOME_ACTIVE,
      title: __('Home'),
      goPage: '/me',
    },
    // {
    //   key: 'reward',
    //   img: ICON_REWARD,
    //   activeImg: icon_reward_active,
    //   title: 'reward',
    //   goPage: '/reward',
    // },
    {
      key: 'history',
      img: ME_HISTORY,
      activeImg:
        tenantConfig?.iconConfig?.personalIcon?.history || icon_history_active,
      title: __('History'),
      goPage: '/records',
    },
    {
      key: 'message',
      img: ICON_MESSAGE,
      activeImg:
        tenantConfig?.iconConfig?.personalIcon?.message || icon_message_active,
      title: 'Message',
      goPage: '/message',
    },
    // {
    //   key: 'exchange',
    //   img: ICON_EXCHANGE,
    //   title: 'Exchange',
    //   goPage: '/reward',
    // },
    // {
    //   key: 'account',
    //   img: ME_USER,
    //   activeImg: icon_account_active,
    //   title: __('Account'),
    //   goPage: '/account',
    // },
    // {
    //   key: 'setting',
    //   img: ME_SETTINGS,
    //   activeImg: icon_setting_active,
    //   title: __('Setting'),
    //   goPage: '/setting',
    // },
    {
      key: 'live',
      img: tenantConfig?.iconConfig?.personalIcon?.support || ICON_LIVE,
      title: __('Live Support'),
      goPage: '',
    },
  ];

  const menuClick = (menu: {
    key: string;
    img: string;
    title: any;
    goPage: string;
  }) => {
    if (menu.key === 'live') {
      if (supportUrl) {
        window.location.href = supportUrl;
      }
      return;
    }
    navigate(menu.goPage);
  };
  if (isMobile) return null;

  return (
    <Stack>
      <Stack
        sx={{
          bgcolor: 'background.neutral',
          borderRadius: '10px',
          p: 2,
        }}
        spacing={1}
      >
        {menuAry.map((menu, index) => {
          let isActive = props.active === menu.key; //
          return (
            <ListItemButton
              key={index}
              sx={[
                ItemBg,
                {
                  bgcolor: isActive ? 'primary.main' : 'background.neutral',
                },
              ]}
              onClick={() => menuClick(menu)}
            >
              <Stack alignItems="center" direction="row" spacing={2}>
                <img
                  style={{ width: 24, height: 24 }}
                  alt=""
                  src={isActive ? menu.activeImg : menu.img}
                />
                <Typography variant="body1" fontWeight={700}>
                  {menu.title}
                </Typography>
              </Stack>
            </ListItemButton>
          );
        })}
      </Stack>
    </Stack>
  );
};

export default MenusWeb;
