import { PageHeader } from '@/components/BackHeader';
import Center from '@/components/center';
import EmptyData from '@/components/EmptyData';
import useLang from '@/hooks/useLang';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import { LoadingButton } from '@mui/lab';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { useWinningRecords } from './useWiningRecords';

interface Props {}

const WinningRecords: React.FC<Props> = (props: Props) => {
  const navigate = useNavigate();
  const { __ } = useLang();
  const { records, hasMore, loading, loadMore, total } = useWinningRecords();

  return (
    <Box>
      <Box mt={{ xs: 0, md: 2 }} mx={{ xs: -2, md: 0 }} mb={2}>
        <Stack direction="row">
          <Box
            width="40px"
            height="40px"
            sx={{ display: { xs: 'none', md: 'block' } }}
          >
            <IconButton color="info" onClick={() => navigate('/')}>
              <ArrowBackIosNewRoundedIcon />
            </IconButton>
          </Box>
          <PageHeader
            onBack={() => navigate('/')}
            title={__('Winning Records')}
          />
        </Stack>
      </Box>

      <Box
        sx={{
          borderRadius: 1,
          bgcolor: 'background.paper',
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            px: 2,
            py: {
              xs: 1.5,
              md: 2,
            },
            bgcolor: 'background.neutral',
          }}
        >
          <Box flexBasis="50%">
            <Typography variant="body2" color="text.disabled">
              {__('Record')}
            </Typography>
          </Box>
          <Box flexBasis="50%">
            <Typography textAlign="right" variant="body2" color="text.disabled">
              {__('Time')}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ px: 2, pb: 0.5 }}>
          {records.map((item, index) => {
            return (
              <RankItem
                key={index}
                record={item.title}
                time={item.add_time}
                pic={item.pic}
                noBorder={index === records.length - 1}
              />
            );
          })}
          {records.length === 0 && (
            <Center py={10}>
              <EmptyData />
            </Center>
          )}
        </Box>
      </Box>
      {hasMore ? (
        <Center mt={2}>
          <LoadingButton
            variant="contained"
            color="primary"
            onClick={loadMore}
            loading={loading}
            size="small"
            sx={{
              px: 1.5,
              py: 1,
              fontSize: 14,
              height: 32,
              borderRadius: '8px',
              bgcolor: 'card.main',
              fontWeight: 500,
            }}
          >
            {__('Load More')}
          </LoadingButton>
        </Center>
      ) : total > 0 ? (
        <Center mt={2}>
          <Typography variant="body2" color="text.disabled">
            {__('displaying').replace('{x}', `${total}`)}
          </Typography>
        </Center>
      ) : null}
    </Box>
  );
};

const RankItem = (props: {
  record: string;
  time: number;
  pic: string;
  noBorder?: boolean;
}) => {
  const { record, time, pic } = props;

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        py: {
          xs: 1,
          md: 1.5,
        },
        borderBottom: props.noBorder ? 'none' : '1px solid #3C3952',
      }}
    >
      <Box flexBasis="50%" sx={{ overflow: 'hidden' }}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <img
            src={pic}
            alt=""
            style={{ width: '24px', height: '24px', borderRadius: 4 }}
          />
          <Typography
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              minWidth: 0,
              flex: 'auto',
            }}
            variant="body1"
          >
            {record}
          </Typography>
        </Stack>
      </Box>

      <Box flexBasis="50%">
        <Typography textAlign="right" variant="body2">
          {dayjs(+(time || 0) * 1000).format('YYYY-MM-DD HH:mm:ss')}
        </Typography>
      </Box>
    </Box>
  );
};

export default WinningRecords;
