import { useEffect, useState } from 'react';

type ReturnType = boolean;

export default function useOffSetTop(top = 100): ReturnType {
  const [value, setValue] = useState(false);

  useEffect(() => {
    const handleScroll = (e: any) => {
      if (e.target.scrollTop > top) {
        setValue(true);
      } else {
        setValue(false);
      }
    };

    window.document
      .getElementById('root')
      ?.addEventListener('scroll', handleScroll);
    return () => {
      window.document
        .getElementById('root')
        ?.removeEventListener('scroll', handleScroll);
    };
  }, [top]);

  return value;
}
