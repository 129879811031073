import useLang from '@/hooks/useLang';
import { Box, Typography } from '@mui/material';

interface Props {}

/**
 * 弹窗内容
 *
 * @param {Props} props
 * @return {*}
 */
const MemberTipDrawerConten = (props: Props) => {
  const { __ } = useLang();
  return (
    <Box sx={{ width: '100%', py: 3, px: 2 }} pb={'8px'}>
      <Typography variant="body1">{__('mTip1')}</Typography>
      <Typography variant="body1">{__('mTip2')}</Typography>
    </Box>
  );
};

export default MemberTipDrawerConten;
