import { IOAuthBind, SetUserInfoRequest } from '../types/user';
import { Request } from '../utils';
import { testHost } from './host';
// const host = "https://game.edestrelaclube.link";
const host = '';

export async function login(
  params: any,
  // {
  // type: number;
  // phone?: string;
  // email?: string;
  // password?: string;
  // code?: string;
  // recommender?: string;
  // }
) {
  return Request.put(`${testHost}/api_login.html`, params);
}

export async function resetPassword(params: {
  phone?: string;
  email?: string;
  password: string;
  password1: string;
  code: string;
  type: 1 | 2; // 取回密码类型，1为登录密码，2为资金密码
}) {
  return Request.post(`${testHost}/api_get_password.html`, params);
}

export async function sendSMS(params: {
  phone?: string;
  type: 1 | 2 | 3 | 4 | 5 | 6;
}) {
  try {
    await Request.post(`${testHost}/api_sms.html`, params);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function sendEmail(params: {
  email?: string;
  type: 1 | 2 | 3 | 4 | 5 | 6;
}) {
  try {
    await Request.post(`${testHost}/api_email.html`, params);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

/**
 * 签到接口
 *
 * @export
 * @return {*}
 */
export async function signDaily() {
  try {
    const { data } = await Request.post(`${testHost}/api_sign_in.html`);
    return data;
  } catch (error) {
    console.log(error);
    return null;
  }
}

/**
 * 签到列表
 *
 * @export
 * @return {*}
 */
export async function getSignList() {
  try {
    const { data } = await Request.post(`${testHost}/api_sign_list.html`);
    // let data =  {
    //   status: 1,
    //   msg: '',
    //   data: [
    //     {
    //       day: 1,
    //       weight: 1,
    //       is_sign: 1,
    //     },
    //     {
    //       day: 2,
    //       weight: 2,
    //       is_sign: 1,
    //     },
    //     {
    //       day: 3,
    //       weight: 1,
    //       is_sign: 1,
    //     },
    //     {
    //       day: 4,
    //       weight: 2,
    //       is_sign: 0,
    //     },
    //     {
    //       day: 5,
    //       weight: 1,
    //       is_sign: 0,
    //     },
    //     {
    //       day: 6,
    //       weight: 2,
    //       is_sign: 0,
    //     },
    // {
    //   day: 7,
    //   weight: 1,
    //   is_sign: 0,
    // },
    // {
    //   day: 8,
    //   weight: 2,
    //   is_sign: 0,
    // },
    // {
    //   day: 9,
    //   weight: 1,
    //   is_sign: 0,
    // },
    // {
    //   day: 10,
    //   weight: 2,
    //   is_sign: 0,
    // },
    // {
    //   day: 11,
    //   weight: 1,
    //   is_sign: 0,
    // },
    // {
    //   day: 12,
    //   weight: 1,
    //   is_sign: 0,
    // },
    // {
    //   day: 13,
    //   weight: 1,
    //   is_sign: 0,
    // },
    // {
    //   day: 14,
    //   weight: 1,
    //   is_sign: 0,
    // },
    // {
    //   day: 15,
    //   weight: 2,
    //   is_sign: 0,
    // },
    // {
    //   day: 16,
    //   weight: 1,
    //   is_sign: 0,
    // },
    // {
    //   day: 17,
    //   weight: 1,
    //   is_sign: 0,
    // },
    // {
    //   day: 18,
    //   weight: 2,
    //   is_sign: 0,
    // },
    // {
    //   day: 19,
    //   weight: 1,
    //   is_sign: 0,
    // },
    // {
    //   day: 20,
    //   weight: 1,
    //   is_sign: 0,
    // },
    // {
    //   day: 21,
    //   weight: 1,
    //   is_sign: 0,
    // },
    //   ],
    // };
    // console.log(data);
    return data;
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function setPaymentPassword(params: {
  type: number;
  password: string;
  password1: string;
  code: string;
}) {
  try {
    await Request.post(`${testHost}/api_setting_money_password.html`, params);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function getLogs(params: { page: number; pagesize: number }) {
  try {
    const { data } = await Request.post(
      `${testHost}/api_agent_money_log.html`,
      params,
    );
    console.log(data);
    return data;
  } catch (error) {
    console.log(error);
    return null;
  }
}

// 上传头像
export async function uploadAvatar(file: File) {
  const formData = new FormData();
  formData.append('type', 'avatar');
  formData.append('file', file);
  try {
    const { data } = await Request.post(
      `${testHost}/api_upload.html`,
      formData,
    );
    console.log(data);
    return data;
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function getMembers(params: {
  page: number;
  pagesize: number;
  total: number;
}) {
  try {
    const { data } = await Request.post(
      `${testHost}/api_agent_list.html`,
      params,
    );
    console.log(data);
    return data;
  } catch (error) {
    console.log(error);
    return null;
  }
}

// http://test.brabab.com/api_oauth.html
export async function getAuthUrl(params: { id: string; callback_url: string }) {
  try {
    const { data } = await Request.post(`${testHost}/api_oauth.html`, params);
    console.log(data);
    return data;
  } catch (error) {
    console.log(error);
    return null;
  }
}

// http://test.brabab.com/api_oauth_guid.html
export async function getOauthGuid(params: {
  id: string;
  guid: string;
  type: number;
}) {
  try {
    const { data } = await Request.post(
      `${testHost}/api_oauth_guid.html`,
      params,
    );
    console.log(data);
    return data;
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function unbind(params: { id: string }) {
  try {
    const { data } = await Request.post(
      `${testHost}/api_oauth_secure.html`,
      params,
    );
    console.log(data);
    return data;
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function getOauthBindList() {
  try {
    const { data } = await Request.post(
      `${testHost}/api_oauth_banding_list.html`,
    );
    console.log(data);
    if (data?.data) {
      return data.data as Record<number, IOAuthBind>;
    }
    return null;
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function resetPaymentPassword(params: {
  old_password: string;
  new_password: string;
  new_password1: string;
}) {
  try {
    await Request.post(`${testHost}/api_edit_password.html`, {
      ...params,
      type: 2,
    });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function resetLoginPassword(params: {
  old_password: string;
  new_password: string;
  new_password1: string;
}) {
  try {
    await Request.post(`${testHost}/api_edit_password.html`, {
      ...params,
      type: 1,
    });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

// 绑定手机
export async function bindPhone(params: { mobile: string; code: string }) {
  try {
    await Request.post(`${testHost}/api_bind_mobile.html`, params);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}


// 绑定email
export async function bindEmail(params: { email: string; code: string }) {
  try {
    await Request.post(`${testHost}/api_bind_email.html`, params);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function getUserInfo() {
  return Request.post(`${testHost}/api_user_info.html`);
}

export async function editUserInfo(params: {
  nick_name?: string;
  sex?: number;
  birthday_day?: string;
  avatar?: string;
}) {
  return Request.post(`${testHost}/api_edit_user_info.html`, params);
}

export async function registerAndLogin(params: any) {
  return Request.post(`${host}/api/auth/password`, params);
}

export async function setUserInfo(params: SetUserInfoRequest) {
  return Request.put(`${host}/api/users/me`, params);
}

export async function getWallet() {
  return Request.post(`${testHost}/api_wallet.html`);
}

export async function deposit(params: {
  amount: number;
  coupon_id: string | null;
  currency_type: string;
  inventory_id: number;
  type: string;
}) {
  return Request.post(`${host}/api/payments/${params.type}/deposit`, params);
}

export async function getPaymentMethod() {
  return Request.get(`${host}/api/payments/methods`);
}

// 修改密码
export async function changePassword(params: {
  current_password: string;
  password: string;
  password_confirm: string;
}) {
  return Request.post(`${host}/api/users/change_password`, params);
}

// 锁定用户
export async function userSuspend(params: { days: number }) {
  return Request.put(`${host}/api/users/suspend`, params);
}

// 锁定用户
export async function userLock(params: { days?: string }) {
  return Request.put(`${testHost}/${params?.days||'0'}/api_self_lock_account.html`, {});
}

// 开盒子
export async function openBox(params: {
  box_id: number;
  box_source: number;
  rank_id: number;
}) {
  return Request.post(`${host}/api/reward/open_box`, params);
}

export async function getMyXp() {
  return Request.get(`${host}/api/users/me/xp`);
}
