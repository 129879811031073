import axios, { AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { SnackbarUtils } from '../components/snackbar/SnackbarProvider';

export const handleRequestError = (error: any) => {
  const msg = error?.data?.message || error?.data?.msg;
  if (msg) {
    console.log(msg);
    SnackbarUtils.error(msg);
  }
};

// 登出
const logout = () => {
  // 需要登录权限的页面，则跳转到登录页
  // if (PRIVATE_PATH_LIST.includes(window.location.pathname)) {
  SnackbarUtils.warning('Login expired, please log in again');
  localStorage.setItem('access_token', '');
  // Router.push(PATH_PAGE.home);
  // window.location.href = '/?signin=1';
  if (window.location.pathname !== '/') {
    window.location.href = '/';
  }
};

const Request = axios.create({
  timeout: 20 * 1000,
});

// 请求前拦截
export const requestInterceptor = (config: InternalAxiosRequestConfig) => {
  // 请求时带 access_token 和 language
  const access_token = localStorage.getItem('access_token');
  if (access_token) {
    config.headers['Authorization'] = access_token;
  }
  const language = localStorage.getItem('__language__') || 'pt';
  config.headers['Accept-Language'] = language;
  config.headers['times'] = Math.floor(Date.now() / 1000);
  config.headers['version'] = 1000;
  config.headers['sign'] = 'abcd';
  config.headers['tid'] = 1;
  config.headers['sid'] = '1';
  config.headers['lang'] = language;

  return config;
};

Request.interceptors.request.use(requestInterceptor, (error) =>
  Promise.reject(error),
);

// 响应后拦截
export const responseIntercepter = async (res: AxiosResponse) => {
  if (res?.status === 200) {
    const innerStatus = res.data?.status;
    if (innerStatus && innerStatus !== 1) {
      handleRequestError(res);
      return Promise.reject(res);
    }
    return Promise.resolve(res);
  } else if (res?.status === 401) {
    logout();
    return Promise.reject(res);
  } else {
    return Promise.reject(res);
  }
};

Request.interceptors.response.use(responseIntercepter, (error) => {
  if (error?.response?.status === 401) {
    logout();
  }
  return Promise.reject(error);
});

export default Request;
