import { MenuItem, Select } from '@mui/material';

interface Props {
  code: number;
  onChangeCode: (code: number) => void;
}

const AreaSelect: React.FC<Props> = (props: Props) => {
  return (
    <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={props.code}
      onChange={(e) => {
        props.onChangeCode(+e.target.value);
      }}
      sx={{
        '.MuiOutlinedInput-notchedOutline': {
          border: '0 !important',
        },
      }}
    >
      <MenuItem value={55}>+55</MenuItem>
    </Select>
  );
};

export default AreaSelect;
