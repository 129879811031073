import Center from '@/components/center';
import CommonSkeleton from '@/components/CommonSkeleton';
import EmptyData from '@/components/EmptyData';
import { IAgentPeople } from '@/hooks/useAgentSummary';
import useLang from '@/hooks/useLang';
import {
  Box,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';

const MemberId = ({ data }: { data: IAgentPeople }) => {
  const { __ } = useLang();
  return (
    <Stack direction="row" alignItems={'center'} spacing={0.5}>
      <Box
        sx={{
          width: '16px',
          height: '16px',
          borderRadius: '4px',
          bgcolor: 'primary.main',
          textAlign: 'center',
        }}
      >
        <Typography variant="body2">{__('ID')}</Typography>
      </Box>
      <Typography variant="subtitle2" fontWeight={500}>
        {data.id}
      </Typography>
    </Stack>
  );
};

const MemberCard = ({ data }: { data: IAgentPeople }) => {
  const { __ } = useLang();
  return (
    <Box
      sx={{
        bgcolor: 'card.main',
        borderRadius: 1,
        mb: {
          xs: 2,
          md: 0,
        },
      }}
    >
      <Stack
        p={{ xs: 2, md: 3 }}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <MemberId data={data} />
        <Typography variant="body1" color="text.secondary" fontWeight={500}>
          {dayjs(+(data?.regTime || 0) * 1000).format('YYYY-MM-DD')}
        </Typography>
      </Stack>
      {/* <Divider></Divider>
      <Stack
        direction="row"
        alignItems={'center'}
        justifyContent={'space-between'}
        px={{ xs: 2, md: 3 }}
        pt={{ xs: 2, md: 3 }}
      >
        <Typography variant="body1" color="text.secondary">
          {__('Deposits')}
        </Typography>
        <Typography variant="body1" fontWeight={500}>
          {data.commission}
        </Typography>
      </Stack>

      <Stack
        direction="row"
        alignItems={'center'}
        justifyContent={'space-between'}
        px={{ xs: 2, md: 3 }}
        pt={{ xs: 2, md: 3 }}
        pb={{ xs: 2, md: 3 }}
      >
        <Typography variant="body1" color="text.secondary">
          {__('Vald Bets')}
        </Typography>
        <Typography variant="body1" fontWeight={500}>
          {data.poll}
        </Typography>
      </Stack> */}
    </Box>
  );
};

interface Props {
  total: number;
  loading: boolean;
  members: IAgentPeople[];
}

const MembersList: React.FC<Props> = (props: Props) => {
  return <List {...props} />;
  // const isMobile = useResponsive('down', 'md');
  // return isMobile ? <List {...props} /> : <MembersTable {...props} />;
};

const MembersTable = ({ loading, total, members }: Props) => {
  const { __ } = useLang();
  if (loading) {
    return <CommonSkeleton />;
  }
  return (
    <Stack spacing={1.5}>
      {total > 0 && (
        <Typography variant="body1" textAlign="right">
          {__('条数据').replace('{n}', `${total}`)}
        </Typography>
      )}
      <TableContainer
        sx={{
          borderRadius: '8px',
          overflowX: 'hidden',
          border: '1px solid #272142',
        }}
      >
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  color: '#8B899C',
                  bgcolor: 'background.paper',
                  borderColor: '#272142',
                  width: '25%',
                  minWidth: '25%',
                  px: 1,
                  maxWidth: '25%',
                }}
              >
                {__('ID')}
              </TableCell>
              <TableCell
                sx={{
                  color: '#8B899C',
                  bgcolor: 'background.paper',
                  borderColor: '#272142',
                  width: '25%',
                  minWidth: '25%',
                  px: 1,
                  maxWidth: '25%',
                }}
              >
                {__('Invited Date')}
              </TableCell>
              <TableCell
                sx={{
                  color: '#8B899C',
                  bgcolor: 'background.paper',
                  borderColor: '#272142',
                  width: '25%',
                  minWidth: '25%',
                  px: 1,
                  maxWidth: '25%',
                }}
              >
                {__('Deposits')}
              </TableCell>
              <TableCell
                sx={{
                  bgcolor: 'background.paper',
                  color: '#8B899C',
                  borderColor: '#272142',
                  width: '25%',
                  minWidth: '25%',
                  px: 1,
                  maxWidth: '25%',
                }}
              >
                {__('Vald Bets')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {members.map((item, index) => {
              return (
                <TableRow
                  key={`${item.id}-${index}`}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell
                    sx={{
                      borderColor: '#272142',
                      width: '25%',
                      minWidth: '25%',
                      px: 1,
                      maxWidth: '25%',
                    }}
                  >
                    {item.id}
                  </TableCell>
                  <TableCell
                    sx={{
                      borderColor: '#272142',
                      width: '25%',
                      minWidth: '25%',
                      px: 1,
                      maxWidth: '25%',
                    }}
                  >
                    {dayjs(+(item?.regTime || 0) * 1000).format(
                      'YYYY-MM-DD HH:mm:ss',
                    )}
                  </TableCell>
                  {/* <TableCell
                    sx={{
                      borderColor: '#272142',
                      width: '25%',
                      minWidth: '25%',
                      px: 1,
                      maxWidth: '25%',
                    }}
                  >
                    {item.total_money}
                  </TableCell>
                  <TableCell
                    sx={{
                      borderColor: '#272142',
                      width: '25%',
                      minWidth: '25%',
                      px: 1,
                      maxWidth: '25%',
                    }}
                  >
                    {item.bet}
                  </TableCell> */}
                </TableRow>
              );
            })}
            {members.length === 0 && (
              <TableRow>
                <TableCell colSpan={4}>
                  <Center py={10}>
                    <EmptyData></EmptyData>
                  </Center>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};

const List = ({ loading, total, members }: Props) => {
  const { __ } = useLang();
  if (loading) {
    return <CommonSkeleton />;
  }

  if (total === 0) {
    return (
      <Center py={10}>
        <EmptyData></EmptyData>
      </Center>
    );
  }

  return (
    <Box>
      <Stack
        width="100%"
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        height="48px"
      >
        <Typography variant="subtitle2" fontWeight={700}>
          Commission Records
        </Typography>
        <Typography variant="body1" textAlign="right" color="secondary.main">
          {__('条数据').replace('{n}', `${total}`)}
        </Typography>
      </Stack>
      <Box>
        <Grid container columns={24} spacing={{ xs: 0, md: 3 }}>
          {members.map((item) => {
            return (
              <Grid item xs={24} sm={12} md={8} key={item.id}>
                <MemberCard data={item} />
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Box>
  );
};

export default MembersList;
