import useLang from '@/hooks/useLang';
import { homeTab } from '@/stores/config';
import { HomeTab } from '@/types';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab } from '@mui/material';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import TabCommissions from './Tabs/Commissions';
import TabLog from './Tabs/Log';
import TabMembers from './Tabs/Members';

interface Props {}

const TABS = ['Commissions', 'Members', 'Log'];

const TabContent = ({ tab }: { tab: string }) => {
  if (tab === 'Commissions') {
    return <TabCommissions />;
  } else if (tab === 'Members') {
    return <TabMembers />;
  }
  return <TabLog />;
};

const CommissionsPage: React.FC<Props> = (props: Props) => {
  const [tab, setTab] = useState<string>(TABS[0]);
  const { __ } = useLang();
  const homeTabValue = useRecoilValue(homeTab);

  useEffect(() => {
    if (homeTabValue === HomeTab.Invite) {
      setTab(TABS[0]);
    }
  }, [homeTabValue]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };

  return (
    <Box
      bgcolor="background.default"
      sx={{ minHeight: 'calc(100vh - 56px)', pt: { xs: 0, md: 2 } }}
    >
      <TabContext value={tab}>
        <TabList
          TabIndicatorProps={{
            sx: {
              bgcolor: 'primary.main',
              height: { xs: '3px', md: '3px' },
              borderRadius: '9px',
              width: { xs: '32px', md: '32px' },
            },
          }}
          sx={{
            borderRadius: {
              xs: 0,
              md: '8px',
            },
            display: {
              xs: 'flex',
              md: 'inline-flex',
            },
            p: { xs: 0, md: 0.5 },
            '.MuiButtonBase-root.MuiTab-root': {
              px: { xs: 0, md: 2 },
              mr: { md: 1 },
              height: { md: '40px' },
              minHeight: { md: '40px' },
              '&:last-child': {
                mr: { md: 0 },
              },
            },
          }}
          TabScrollButtonProps={{
            sx: {
              mr: { xs: 0, md: 2 },
              px: { xs: 0, md: 2 },
            },
          }}
          onChange={handleChange}
          aria-label="lab API tabs example"
        >
          {TABS.map((item) => (
            <Tab
              sx={{
                flex: { xs: 1, md: 'unset' },
                zIndex: 100,
                color: 'text.primary',
                '&:not(.Mui-selected)': {
                  color: 'text.disabled',
                },
                mr: {
                  xs: 0,
                  md: 2,
                },
              }}
              label={__(item)}
              value={item}
              key={item}
            />
          ))}
        </TabList>
        {TABS.map((item) => (
          <TabPanel sx={{ px: 0, py: '20px' }} value={item} key={item}>
            <TabContent tab={tab} />
          </TabPanel>
        ))}
      </TabContext>
    </Box>
  );
};

export default CommissionsPage;
