import CodeInput from '@/components/Auth/login/CodeInput';
import PhoneInput from '@/components/Auth/login/PhoneInput';
import CommonSkeleton from '@/components/CommonSkeleton';
import { SnackbarUtils } from '@/components/snackbar/SnackbarProvider';
import useAuth from '@/hooks/useAuth';
import useLang from '@/hooks/useLang';
import services from '@/services';
import { isOpenVerifyAccountState } from '@/stores/auth';
import { ColorfulBg } from '@/styles';
import { LoadingButton } from '@mui/lab';
import { Box, OutlinedInput, Stack } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';

interface Props {
  onSuccess?: () => void;
  showClose?: boolean;
  type?: string;
}

const VerifyAccountPage: React.FC<Props> = (props: Props) => {
  const [account, setAccount] = useState<string>('');
  const [pwdAgain, setPwdAgain] = useState<string>('');
  const [code, setCode] = useState<string>('');
  const navigate = useNavigate();
  const { __ } = useLang();
  const [openVerifyAccount, setOpenVerifyAccount] = useRecoilState(
    isOpenVerifyAccountState,
  );
  const [canSave, setCanSave] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const { getUserInfo, user } = useAuth();

  const handleSendSMS = (isPhone: boolean) => async () => {
    if (isPhone) {
      return services.auth.sendSMS({ phone: account, type: 4 });
    } else {
      return services.auth.sendEmail({ email: account, type: 2 });
    }
  };

  const handleSubmit = (isPhone: boolean) => async () => {
    if (loading) {
      return;
    }

    setLoading(true);

    let isSuccess = false;
    if (isPhone) {
      let params = {
        code,
        mobile: account,
      };
      isSuccess = await services.auth.bindPhone(params);
    } else {
      let params = {
        code,
        email: account,
      };
      isSuccess = await services.auth.bindEmail(params);
    }

    if (isSuccess) {
      SnackbarUtils.success(__('Successfully'));
      getUserInfo();
      setOpenVerifyAccount('');
    }
    setLoading(false);
  };

  const _onInput = (type: string, txt: string) => {
    let params = {
      account,
      code,
    };
    switch (type) {
      case 'account':
        setAccount(txt);
        params.account = txt;
        break;
      case 'code':
        setCode(txt);
        params.code = txt;
        break;
    }

    if (params.account && params.code) {
      setCanSave(true);
    } else {
      setCanSave(false);
    }
  };

  if (!user) {
    return <CommonSkeleton />;
  }

  const isBindPhone = props?.type === 'phone';

  return (
    <Box pb={4}>
      <Box px={2}>
        <Stack spacing="20px" mt={2.5}>
          {isBindPhone ? (
            <PhoneInput
              type="text"
              phone={account}
              placeholder={__('Please enter phone number')}
              areaCode={55}
              onChangePhone={(e) => _onInput('account', e)}
            />
          ) : (
            <OutlinedInput
              placeholder={__('Please enter email')}
              value={account}
              onChange={(e) => _onInput('account', e.target.value)}
            />
          )}
          <CodeInput
            code={code}
            onChange={(e) => _onInput('code', e)}
            onSendSMS={handleSendSMS(isBindPhone)}
          />
        </Stack>

        <LoadingButton
          disabled={!canSave}
          sx={{
            background: canSave ? ColorfulBg() : '#3C3952',
            boxShadow: 'none !important',
            mt: '20px',
            borderRadius: '8px',
          }}
          fullWidth
          variant="contained"
          loading={loading}
          onClick={handleSubmit(isBindPhone)}
        >
          {__('Verify')}
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default VerifyAccountPage;
