import * as auth from './auth';
import * as config from './config';
import * as game from './game';
import * as home from './home';
import * as lucky from './lucky';
import * as wallet from './wallet';

const services = {
  auth,
  config,
  home,
  wallet,
  game,
  lucky,
};

export default services;
